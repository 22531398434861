<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_expense_master')">
            <!--page content-->
            <div class="admin-page-content">
                <!-- Header Section -->
                <div class="d-flex justify-content-between items-center mb-0">
                    <h5 class="text-lg font-bold">
                        <template v-if="unitNo">
                            Stock #{{ unitNo }} | 
                        </template>
                        <span>{{ year }} {{ makeName }} {{ modelName }}</span>
                    </h5>
                    <div>
                        <button
                            type="submit"
                            v-show="permissionNameArray.includes('can_add_expense_master')"
                            class="btn btn-primary waves-effect mr-2"
                            v-on:click.prevent="chagneComponent(isEditValue)"
                        >
                            {{ buttonTitle }}
                        </button>
                        <button
                            v-if="isListAssetExpenseTab"
                            type="submit"
                            v-show="permissionNameArray.includes('can_add_expense_master')"
                            class="btn btn-primary waves-effect"
                            v-on:click.prevent="syncAssetExpenses()"
                        >
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <!-- Forms -->
                <div :class="alertClass" id="success-alert">
                    <strong>{{ alertMessage }}</strong>
                </div>
                <!-- List -->
                <div v-if="isListAssetExpenseTab" class="col-12 paddingnone">
                    <list-asset-expense-tab 
                        v-bind:permissionNameArray=permissionNameArray
                        @updateParentDisplayMethod="updateParentDisplayMethod"
                        v-show="permissionNameArray.includes('can_list_expense_master')"
                        >
                    </list-asset-expense-tab>
                </div>
                <!-- Add Update Asset Expense -->
                <div v-else class="forms-container add_user_forms_container">
                    <add-update-asset-expense-tab 
                        v-bind:permissionNameArray=permissionNameArray
                        :isEdit="isEditValue"  
                        v-show="permissionNameArray.includes('can_add_expense_master')"></add-update-asset-expense-tab>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import AddUpdateAssetExpenseTab from "@/components/AssetMaster/ExpenseTab/AddUpdateAssetExpenseTab";
    import ListAssetExpenseTab from "@/components/AssetMaster/ExpenseTab/ListAssetExpenseTab";
    Vue.component("app-footer", Footer);
    export default {
        name: 'AssetExpenseTab',
        components: {
            "list-asset-expense-tab" : ListAssetExpenseTab,
            "add-update-asset-expense-tab" : AddUpdateAssetExpenseTab,
        },
        mixins: [commonMixins],
        data() {
            return {
                isEditValue: 0,
                staticInputLabelMessages: "",
                isListAssetExpenseTab: true,
                buttonTitle: 'Add',
                assetId: 0,
                unitNo: "",
                year: "",
                makeName: "",
                modelName: "",

                buttonNames: {
                    save: 'Submit',
                },
                disableButtons: {
                    save: false
                },
                alertClass: "",
                alertMessage: "",
            }
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            self.unitNo = self.asset.content.asset_data.unit;
            self.year = self.asset.content.asset_data.year;
            self.makeName = self.asset.content.asset_data.make_name;
            self.modelName = self.asset.content.asset_data.model_name;
            $("#alertError").hide();
        },
        methods: {
            updateParentDisplayMethod : function (actTab) {
                self.$emit('updateDisplayData',actTab);
            },
            syncAssetExpenses: function(){
                EventBus.$emit("sync-expense-list", "from-expense-tab");
            },
            chagneComponent: function(editValue) {
                self.isListAssetExpenseTab = !self.isListAssetExpenseTab;
                self.buttonTitle = 'Add';
                self.isEditValue = 0;
                if(!self.isListAssetExpenseTab) {
                    self.isEditValue = editValue;
                    self.buttonTitle = 'Back';
                }
            },
        }
    }
</script>