<template>
    <table class="custom-table">
        <caption>{{ labelMessages.vws_addon_list }}</caption>
        <thead>
            <th>{{ labelMessages.addon_item_name }}</th>
            <th>{{ labelMessages.addon_description }}</th>
            <th>{{ labelMessages.addon_tax }}</th>
            <th>{{ labelMessages.addon_cost }}</th>
            <th>{{ labelMessages.addon_price }}</th>
            <th>{{ labelMessages.addon_add_to_price }}</th>
            <th>{{ labelMessages.addon_excl_from_fet }}</th>
            <th>{{ labelMessages.addon_show_on_doc }}</th>
            <th>{{ labelMessages.addon_add_to_gp }}</th>
            <th></th>
        </thead>
        <tbody v-if="addOnItems != undefined && addOnItems.length > 0">
            <template v-for="(data, index) in addOnItems">
                <tr>
                    <td>
                        {{ data.excede_item.item }}
                    </td>
                    <td>{{ data.description }}</td>
                    <td>{{ data.tax }}</td>
                    <td>$ {{ data.cost.toFixed(2) }}</td>
                    <td>$ {{ data.price.toFixed(2) }}</td>
                    <td>
                        <input 
                            type="checkbox" 
                            :checked="parseInt(data.is_add_to_total_price) == 1 || data.is_add_to_total_price == true
                                ? true
                                : false" 
                            disabled
                        />
                    </td>
                    <td>
                        <input 
                            type="checkbox"
                            :checked="parseInt(data.is_fet_exempt) == 1 || data.is_fet_exempt == true
                                ? true
                                : false"
                            disabled
                        />
                    </td>
                    <td>
                        <input 
                            type="checkbox" :checked="(parseInt(data.is_show_on_documents) || data.is_show_on_documents == true) == 1
                                ? true
                                : false" 
                            disabled
                        />
                    </td>
                    <td>
                        <input 
                            type="checkbox" 
                            :checked="parseInt(data.is_do_not_add_to_gp) == 1 || data.is_do_not_add_to_gp == true
                                ? true
                                : false" 
                            disabled
                        />
                    </td>
                    <td>
                        <a v-on:click="triggerEvent(data, index, 'edit')" title="Edit AddOn Item" href="javascript:void(0)"
                            class="mar-right-0">
                            <i aria-hidden="true" class="fa fa-edit"></i>
                        </a>
                        <a v-on:click="triggerEvent(data, index, 'delete')" title="Delete AddOn Item" href="javascript:void(0)"
                            class="mar-right-0 pl-2">
                            <i aria-hidden="true" class="fa fa-trash text-danger"></i>
                        </a>
                    </td>
                </tr>
            </template>
        </tbody>
        <tbody v-if="purchaseOrderList != undefined && purchaseOrderList.length > 0">
            <template v-for="(po,poIndex) in purchaseOrderList">
                <tr class="po-row">
                    <td colspan="1">
                        {{ po.ex_pur_id != null ? 'Ex.PO#: '+po.ex_pur_id : '' }}
                    </td>
                    <td colspan="8">
                        <a href="javascript:void(0)" @click="triggerEvent(po, poIndex, 'edit-po')">
                            Vendor#{{ po.vendor_id }}-{{ po.vendor_name }} /
                            Ship Code: {{ po.shp_code }} /
                            Terms: {{ po.terms }} /
                            Type: {{ po.vehicle_type }} /
                            Order Date: {{ po.order_date }} /
                            Expected Date: {{ po.expected_date }}
                        </a>
                    </td>
                    <td>
                        <a v-on:click="triggerEvent(po, po.ex_pur_id, 'delete-po')" title="Delete PO" href="javascript:void(0)"
                            class="mar-right-0 pl-2" v-if="po.id">
                            <i aria-hidden="true" class="fa fa-trash text-danger"></i>
                        </a>
                        <span v-if="permissionNameArray.includes('can_add_purchase_order_excede') && po.po_items[0].id != null && dealStatusSlug === 'po-request' && po.ex_pur_id == null">&nbsp; |</span>
                        <a v-on:click="triggerEvent(po, poIndex, 'create-excede')" :title="(po.ex_pur_id != null) ? 'Update Excede' : 'Create Excede'" href="javascript:void(0)"
                            class="mar-right-0 pl-2"
                            v-if="permissionNameArray.includes('can_add_purchase_order_excede') && po.po_items[0].id != null && dealStatusSlug === 'po-request' && po.ex_pur_id == null">
                            <i aria-hidden="true" :class="po.ex_pur_id != null ? 'fa fa-edit' : 'fa fa-plus'"></i> 
                            <span> Ex.PO</span>
                        </a>
                    </td>
                </tr>
                <tr v-for="(poItem, poItemIndex) in po.po_items">
                    <td>{{ poItem.po_item_type.itm_typ_name }}</td>
                    <td>{{ poItem.description }}</td>
                    <td>{{ poItem.tax }}</td>
                    <td>$ {{ poItem.cost.toFixed(2) }}</td>
                    <td>$ {{ poItem.price.toFixed(2) }}</td>
                    <td>
                        <input 
                            type="checkbox" 
                            :checked="parseInt(poItem.is_add_to_total_price) == 1 || poItem.is_add_to_total_price == true
                                ? true
                                : false" 
                            disabled
                        />
                    </td>
                    <td>
                        <input 
                            type="checkbox"
                            :checked="parseInt(poItem.is_fet_exempt) == 1 || poItem.is_fet_exempt == true
                                ? true
                                : false"
                            disabled
                        />
                    </td>
                    <td>
                        <input 
                            type="checkbox" :checked="(parseInt(poItem.is_show_on_documents) || poItem.is_show_on_documents == true) == 1
                                ? true
                                : false" 
                            disabled
                        />
                    </td>
                    <td>
                        <input 
                            type="checkbox" 
                            :checked="parseInt(poItem.is_do_not_add_to_gp) == 1 || poItem.is_do_not_add_to_gp == true
                                ? true
                                : false" 
                            disabled
                        />
                    </td>
                    <td>
                        <!-- <a v-on:click="triggerEvent(poItem, poItemIndex, 'edit-po-item', po, poIndex)" title="Edit PO Item" href="javascript:void(0)"
                            class="mar-right-0">
                            <i aria-hidden="true" class="fa fa-edit"></i>
                        </a> -->
                        <a v-on:click="triggerEvent(poItem, poItemIndex, 'delete-po-item', po, poIndex)" title="Delete PO Item" href="javascript:void(0)"
                            class="mar-right-0 pl-2" v-if="po.po_items_count > 1">
                            <i aria-hidden="true" class="fa fa-trash text-danger"></i>
                        </a>
                    </td>
                </tr>
            </template>
        </tbody>
        <tbody v-else-if="addOnItems.length == 0 && purchaseOrderList.length == 0">
            <tr>
                <td colspan="3" id="table-colspan">
                    {{ labelMessages.no_records_to_display_label }}
                </td>
            </tr>
        </tbody>
        <common-loader refId="commonLoaderItemList" ref="commonLoaderItemList" />
    </table>
</template>

<script>
    import eventBus from '@/components/event-bus';
    import CommonLoader from "@/components/partials/CommonLoader.vue";

    export default {
        props: {
            addOnItems: [],
            purchaseOrderList: [],
            labelMessages: {},
            permissionNameArray: [],
            dealStatusSlug: '',
            isGetItemApiCall: false
        },
        components: {
            "common-loader": CommonLoader,
        },
        name: "ItemList",
        data() {
            return {
            }
        },
        methods: {
            triggerEvent: function (data, index, eventType, po=null, poItemIndex=null) {
                switch(eventType) {
                    case 'edit':
                        eventBus.$emit("edit-addon-item", data, index);
                        break;
                    case 'edit-po':
                        if(Object.keys(data).includes('deal_inventory_id') && !data.deal_inventory_id) {
                            data['current_index'] = index;
                            eventBus.$emit("edit-po-item", data, index);
                        } else {
                            eventBus.$emit("edit-po-item", data.po_items[0], index);
                        }
                        break;
                    case 'edit-po-item':
                        if(Object.keys(po).includes('deal_inventory_id') && !po.deal_inventory_id) {
                            po['current_index'] = poItemIndex;
                            eventBus.$emit("edit-po-item", po, poItemIndex);
                        } else {
                            eventBus.$emit("edit-po-item", data, index);
                        }
                        break;
                    case 'create-excede':
                        eventBus.$emit("create-po-excede", data.id);
                        break;
                    case 'delete':
                        eventBus.$emit("delete-addon-po-item", data, index, 'addon');
                        break;
                    case 'delete-po-item':
                        eventBus.$emit("delete-addon-po-item", data, index, 'po-item', poItemIndex);
                        break;
                    case 'delete-po':
                        eventBus.$emit("delete-po", data.id, index);
                        break;
                }
            },
        },
        watch: {
            isGetItemApiCall(status) {
                if(status) {
                    $("#commonLoaderItemList").find(".loading-container.lodingTable").show();
                } else {
                    $("#commonLoaderItemList").find(".loading-container.lodingTable").hide();
                }
            }
        }
    };
</script>

<style
    lang="css"
    scoped
    src="../../../../../public/static/css/vehicleWorksheetUpdate.css"
  >
</style>