import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from '@/components/HelloWorld';
import Login from '@/components/Login/Login';
import ForgotPassword from '@/components/Login/ForgotPassword';
import FilterComponent from '@/components/FilterComponent/FilterComponent';

import ListUser from '@/components/UserManagement/ListUser';
import AddUpdateUser from '@/components/UserManagement/AddUpdateUser';
import UserDataTab from '@/components/UserManagement/UserMaster/UserDataTab';

import ListExhaust from '@/components/ExhaustMaster/ListExhaust';
import AddUpdateExhaust from '@/components/ExhaustMaster/AddUpdateExhaust';

import ListExpenseCategory from '@/components/ExpenseCategoryMaster/ListExpenseCategory';
import AddUpdateExpenseCategory from '@/components/ExpenseCategoryMaster/AddUpdateExpenseCategory';

import ListBreathers from '@/components/BreathersMaster/ListBreathers';
import AddUpdateBreathers from '@/components/BreathersMaster/AddUpdateBreathers';

import ListDropAxleConfig from '@/components/DropAxleConfigMaster/ListDropAxleConfig';
import AddUpdateDropAxleConfig from '@/components/DropAxleConfigMaster/AddUpdateDropAxleConfig';

import ListAxleType from '@/components/AxleTypeMaster/ListAxleType';
import AddUpdateAxleType from '@/components/AxleTypeMaster/AddUpdateAxleType';

import ListSuspension from '@/components/SuspensionMaster/ListSuspension';
import AddUpdateSuspension from '@/components/SuspensionMaster/AddUpdateSuspension';

import ListSpecification from '@/components/SpecificationMaster/ListSpecification';
import AddUpdateSpecification from '@/components/SpecificationMaster/AddUpdateSpecification';

import ListBrakeType from '@/components/BrakeTypeMaster/ListBrakeType';
import AddUpdateBrakeType from '@/components/BrakeTypeMaster/AddUpdateBrakeType';

import ListCabType from '@/components/CabTypeMaster/ListCabType';
import AddUpdateCabType from '@/components/CabTypeMaster/AddUpdateCabType';

import ListCabStyle from '@/components/CabStyleMaster/ListCabStyle';
import AddUpdateCabStyle from '@/components/CabStyleMaster/AddUpdateCabStyle';

import ListFairings from '@/components/FairingsMaster/ListFairings';
import AddUpdateFairings from '@/components/FairingsMaster/AddUpdateFairings';

import ListMake from '@/components/MakeMaster/ListMake';
import AddUpdateMake from '@/components/MakeMaster/AddUpdateMake';

import ListModel from '@/components/ModelMaster/ListModel';
import AddUpdateModel from '@/components/ModelMaster/AddUpdateModel';

import ListFuelType from '@/components/FuelTypeMaster/ListFuelType';
import AddUpdateFuelType from '@/components/FuelTypeMaster/AddUpdateFuelType';

import ListCondition from '@/components/ConditionMaster/ListCondition';
import AddUpdateCondition from '@/components/ConditionMaster/AddUpdateCondition';

import ListStatus from '@/components/StatusMaster/ListStatus';
import AddUpdateStatus from '@/components/StatusMaster/AddUpdateStatus';

import ListSaleType from '@/components/SaleTypeMaster/ListSaleType';
import AddUpdateSaleType from '@/components/SaleTypeMaster/AddUpdateSaleType';

import ListWarrantyType from '@/components/WarrantyTypeMaster/ListWarrantyType';
import AddUpdateWarrantyType from '@/components/WarrantyTypeMaster/AddUpdateWarrantyType';

import ListEngineMake from '@/components/EngineMakeMaster/ListEngineMake';
import AddUpdateEngineMake from '@/components/EngineMakeMaster/AddUpdateEngineMake';

import ListEngineCylinder from '@/components/EngineCylinderMaster/ListEngineCylinder';
import AddUpdateEngineCylinder from '@/components/EngineCylinderMaster/AddUpdateEngineCylinder';

import ListApuMake from '@/components/ApuMakeMaster/ListApuMake';
import AddUpdateApuMake from '@/components/ApuMakeMaster/AddUpdateApuMake';

import ListTransSpeed from '@/components/TransSpeedMaster/ListTransSpeed';
import AddUpdateTransSpeed from '@/components/TransSpeedMaster/AddUpdateTransSpeed';

import ListTransType from '@/components/TransTypeMaster/ListTransType';
import AddUpdateTransType from '@/components/TransTypeMaster/AddUpdateTransType';

import ListTransMake from '@/components/TransMakeMaster/ListTransMake';
import AddUpdateTransMake from '@/components/TransMakeMaster/AddUpdateTransMake';

import ListOpportunity from '@/components/OpportunityMaster/ListOpportunity';
import AddUpdateOpportunity from '@/components/OpportunityMaster/AddUpdateOpportunity';

import ListContactGroup from '@/components/ContactGroupMaster/ListContactGroup';
import AddUpdateContactGroup from '@/components/ContactGroupMaster/AddUpdateContactGroup';

import ListContact from '@/components/ContactMaster/ListContact';
import AddUpdateContact from '@/components/ContactMaster/AddUpdateContact';
import ListContactEmail from '@/components/ContactMaster/ListContactEmail';
import ViewContactEmail from '@/components/ContactMaster/ViewContactEmail';
import VehicleServiceDetails from '@/components/ContactMaster/VehicleServiceDetails';
import CustomerDashboard from '@/components/ContactMaster/Dashboard';

import ListInvoice from '@/components/InvoiceMaster/ListInvoice';
import AddUpdateInvoice from '@/components/InvoiceMaster/AddUpdateInvoice';

import ListContactLead from '@/components/ContactLeadMaster/ListContactLead';
import AddUpdateContactLead from '@/components/ContactLeadMaster/AddUpdateContactLead';

import ListCompany from '@/components/CompanyMaster/ListCompany';
import AddUpdateCompany from '@/components/CompanyMaster/AddUpdateCompany';

import ListDepartment from '@/components/DepartmentMaster/ListDepartment';
import AddUpdateDepartment from '@/components/DepartmentMaster/AddUpdateDepartment';

import ListRole from '@/components/Role/ListRole';
import AddUpdateRole from '@/components/Role/AddUpdateRole';

import ListLocation from '@/components/LocationMaster/ListLocation';
import AddUpdateLocation from '@/components/LocationMaster/AddUpdateLocation';
import DraggableExpenseType from '@/components/LocationMaster/DraggableExpenseType';

import ListCity from '@/components/CityMaster/ListCity';

import ListState from '@/components/StateMaster/ListState';

import ListInterior from '@/components/InteriorMaster/ListInterior';
import AddUpdateInterior from '@/components/InteriorMaster/AddUpdateInterior';

import ListTrailerType from '@/components/TrailerTypeMaster/ListTrailerType';
import AddUpdateTrailerType from '@/components/TrailerTypeMaster/AddUpdateTrailerType';

import ListSideDoorType from '@/components/SideDoorTypeMaster/ListSideDoorType';
import AddUpdateSideDoorType from '@/components/SideDoorTypeMaster/AddUpdateSideDoorType';

import ListAssetExpenses from '@/components/AssetExpenses/ListAssetExpenses';
import AddUpdateAssetExpenses from '@/components/AssetExpenses/AddUpdateAssetExpenses';

import ListBodyStyle from '@/components/BodyStyleMaster/ListBodyStyle';
import AddUpdateBodyStyle from '@/components/BodyStyleMaster/AddUpdateBodyStyle';

import ListBodyType from '@/components/BodyTypeMaster/ListBodyType';
import AddUpdateBodyType from '@/components/BodyTypeMaster/AddUpdateBodyType';

import ListDumpMake from '@/components/DumpMakeMaster/ListDumpMake';
import AddUpdateDumpMake from '@/components/DumpMakeMaster/AddUpdateDumpMake';

import ListDumpType from '@/components/DumpTypeMaster/ListDumpType';
import AddUpdateDumpType from '@/components/DumpTypeMaster/AddUpdateDumpType';

import ListFloorType from '@/components/FloorTypeMaster/ListFloorType';
import AddUpdateFloorType from '@/components/FloorTypeMaster/AddUpdateFloorType';

import ListFrame from '@/components/FrameMaster/ListFrame';
import AddUpdateFrame from '@/components/FrameMaster/AddUpdateFrame';

import ListRearDoorType from '@/components/RearDoorTypeMaster/ListRearDoorType';
import AddUpdateRearDoorType from '@/components/RearDoorTypeMaster/AddUpdateRearDoorType';

import ListReeferMake from '@/components/ReeferMakeMaster/ListReeferMake';
import AddUpdateReeferMake from '@/components/ReeferMakeMaster/AddUpdateReeferMake';

import ListRoofType from '@/components/RoofTypeMaster/ListRoofType';
import AddUdpateRoofType from '@/components/RoofTypeMaster/AddUdpateRoofType';

import ListAsset from '@/components/AssetMaster/ListAsset';
import DeleteAssetList from '@/components/AssetMaster/DeleteAssetList';
import AddUpdateAsset from '@/components/AssetMaster/AddUpdateAsset';
import ViewAsset from '@/components/AssetMaster/ViewAsset';
import AddAsset from '@/components/AssetMaster/AddAsset';
import AssetTabView from '@/components/AssetMaster/AssetTabView';
import AssetSpecification from '@/components/AssetMaster/AssetSpecification';
import MultyAssetTabView from '@/components/AssetMaster/MultipleAction/MultyAssetTabView';
import PrintQrCode from '@/components/AssetMaster/MultipleAction/PrintQrCode';

import AssetCloseTab from '@/components/AssetMaster/AssetCloseTab';
import AssetCommissionManagerTab from '@/components/AssetMaster/AssetCommissionManagerTab';
import EmailSpecSheetTabView from '@/components/AssetMaster/SpecSheet/EmailSpecSheetTabView';
import Quotation from '@/components/AssetMaster/BookDeal/Quotation';

import ListCommissionType from '@/components/CommissionTypeMaster/ListCommissionType';
import AddUpdateCommissionType from '@/components/CommissionTypeMaster/AddUpdateCommissionType';

import ListCloseType from '@/components/CloseTypeMaster/ListCloseType';
import AddUpdateCloseType from '@/components/CloseTypeMaster/AddUpdateCloseType';

import ListFormEmailManager from '@/components/Forms/FormEmailManager/ListFormEmailManager';
import AddUpdateFormEmailManager from '@/components/Forms/FormEmailManager/AddUpdateFormEmailManager';

import ListPORequest from '@/components/Forms/PORequest/ListPORequest';
import AddUpdatePORequest from '@/components/Forms/PORequest/UpdatePORequest';

import ListVehicleCheckIn from '@/components/Forms/VehicleCheckIn/ListVehicleCheckIn';
import AddUpdateVehicleCheckIn from '@/components/Forms/VehicleCheckIn/UpdateVehicleCheckIn';

import ListTruckTrailerSearchMadeEasy from '@/components/Forms/TruckTrailerSearchMadeEasy/ListTruckTrailerSearchMadeEasy';
import AddUpdateTruckTrailerSearchMadeEasy from '@/components/Forms/TruckTrailerSearchMadeEasy/UpdateTruckTrailerSearchMadeEasy';

import ListUsedTruckHoldsSubmissionForm from '@/components/Forms/UsedTruckHoldsSubmissionForm/ListUsedTruckHoldsSubmissionForm';
import AddUpdateUsedTruckHoldsSubmissionForm from '@/components/Forms/UsedTruckHoldsSubmissionForm/UpdateUsedTruckHoldsSubmission';

import ListOfferSubmission from '@/components/Forms/OfferSubmission/ListOfferSubmission';
import UpdateOfferSubmission from '@/components/Forms/OfferSubmission/UpdateOfferSubmission';

import ListActivity from '@/components/Activity/ListActivity';
import AddUpdateActivity from '@/components/Activity/AddUpdateActivity';
import ViewUpdateActivity from '@/components/Activity/ViewUpdateActivity';

import AddUpdateLeadQuality from '@/components/LeadQualityMaster/AddUpdateLeadQuality';
import ListLeadQuality from '@/components/LeadQualityMaster/ListLeadQuality';

import AddUpdateFundingSource from '@/components/FundingSourceMaster/AddUpdateFundingSource';
import ListFundingSource from '@/components/FundingSourceMaster/ListFundingSource';

import AddUpdateFundingStatus from '@/components/FundingStatusMaster/AddUpdateFundingStatus';
import ListFundingStatus from '@/components/FundingStatusMaster/ListFundingStatus';

import AddUpdateFundingMethod from '@/components/FundingMethodMaster/AddUpdateFundingMethod'
import ListFundingMethod from '@/components/FundingMethodMaster/ListFundingMethod'

import AddUpdateReferralSource from '@/components/ReferralSourceMaster/AddUpdateReferralSource'
import ListReferralSource from '@/components/ReferralSourceMaster/ListReferralSource'

import ListEngineModel from '@/components/EngineModelMaster/ListEngineModel'
import AddUpdateEngineModel from '@/components/EngineModelMaster/AddUpdateEngineModel'

import ListMail from '@/components/Mail/ListMail';

import AssetSpecSheet from '@/components/AssetMaster/SpecSheet/AssetSpecSheet';
import AssetSpecSheetVINStock from '@/components/AssetMaster/SpecSheet/AssetSpecSheetVINStock';
import AssetPrintableSummary from '@/components/AssetMaster/SpecSheet/AssetPrintableSummary';
import AssetPromoSpecSheet from '@/components/AssetMaster/SpecSheet/AssetPromoSpecSheet';
import EmailPromoSheet from '@/components/AssetMaster/SpecSheet/EmailPromoSheet';
import GlobalSearch from '@/components/GlobalSearch/GlobalSearch';
import PrintMultipleInventory from '@/components/AssetMaster/PrintMultiple/PrintMultipleInventory';

import compareAsset from '@/components/AssetMaster/SpecSheet/compareAsset';

import AddUpdateUsedTruckHoldSubmission from '@/components/Forms/UsedTruckHoldsSubmissionForm/AddUsedTruckHoldsSubmission';
import AddUpdateVehicalCheckIn from '@/components/Forms/VehicleCheckIn/AddVehicleCheckIn';
import AddUpdatePORequestSubmission from '@/components/Forms/PORequest/AddPORequest';
import AddUpdateOfferSubmission from '@/components/Forms/OfferSubmission/AddOfferSubmission';
import AddUpdateTruckAndTrailerSearchMadeEasy from '@/components/Forms/TruckTrailerSearchMadeEasy/AddTruckAndTrailerSearchMadeEasy';

import AddUpdateBranch from '@/components/BranchMaster/AddUpdateBranch';
import ListBranch from '@/components/BranchMaster/ListBranch';

import AddUpdateUsedTruckMovedSubmission from '@/components/Forms/UsedTruckMovedSubmissionForm/AddUsedTruckMovedSubmission';
import ListUsedTruckMovedSubmission from '@/components/Forms/UsedTruckMovedSubmissionForm/ListUsedTruckMovedSubmission';
import UpdateUsedTruckMovedSubmission from '@/components/Forms/UsedTruckMovedSubmissionForm/UpdateUsedTruckMovedSubmission';

import ListTransModel from '@/components/TransModelMaster/ListTransModel';
import AddUpdateTransModel from '@/components/TransModelMaster/AddUpdateTransModel';

import ListTaskMaster from '@/components/TaskMaster/ListTaskMaster';
import AddUpdateTaskMaster from '@/components/TaskMaster/AddUpdateTaskMaster';
import ViewTaskMaster from '@/components/TaskMaster/ViewTaskMaster';

import ListCaseMaster from '@/components/CaseMaster/ListCaseMaster';
import AddUpdateCaseMaster from '@/components/CaseMaster/AddUpdateCaseMaster';
import ViewCaseMaster from '@/components/CaseMaster/ViewCaseMaster';

import ListCallsAndEmails from '@/components/CallsAndEmails/ListCallsAndEmails';
import AddUpdateCallsAndEmails from '@/components/CallsAndEmails/AddUpdateCallsAndEmails';
import ViewCallsAndEmails from '@/components/CallsAndEmails/ViewCallsAndEmails';
import ListDtrTradeTerms from '@/components/DtrTradeTermsMaster/ListDtrTradeTerms';
import AddUpdateDtrTradeTerms from '@/components/DtrTradeTermsMaster/AddUpdateDtrTradeTerms';

import ListFleet from '@/components/FleetMaster/ListFleet';
import AddUpdateFleet from '@/components/FleetMaster/AddUpdateFleet';

/* import ListNotificationEmailManager from '@/components/NotificationEmailManager/ListNotificationEmailManager';
import AddUpdateNotificationEmailManager from '@/components/NotificationEmailManager/AddUpdateNotificationEmailManager'; */

import AddUpdateQuotation from '@/components/Forms/QuotationMaster/AddUpdateQuotation';
import ListQuotation from '@/components/Forms/QuotationMaster/ListQuotation';

import ListHoldStatus from '@/components/HoldStatusMaster/ListHoldStatus';
import AddUpdateHoldStatus from '@/components/HoldStatusMaster/AddUpdateHoldStatus';

import ListInventorySetting from '@/components/InventorySettingMaster/ListInventorySetting';
import AddUpdateInventorySetting from '@/components/InventorySettingMaster/AddUpdateInventorySetting';

import ListRepairOrder from '@/components/RepairOrder/ListRepairOrder';
import ListRepairOrderOps from '@/components/RepairOrder/ListRepairOrderOps';
import ListRepairOrderItems from '@/components/RepairOrder/ListRepairOrderItems';
import ListRepairOrderEmpLbr from '@/components/RepairOrder/ListRepairOrderEmpLbr';

import ListOnOrderAsset from '@/components/Dashboard/ListOnOrderAsset';

import ListDocument from '@/components/DocumentManagement/Document/ListDocument';
import AddUpdateDocument from '@/components/DocumentManagement/Document/AddUpdateDocument';

import ListBuyTruckSubmissionForm from '@/components/Forms/BuysTrucksSubmissionForm/ListBuyTruckSubmissionForm';
import AddUpdateBuysTrucksSubmissionForm from '@/components/Forms/BuysTrucksSubmissionForm/AddBuysTrucksSubmissionForm';
import UpdateBuysTrucksSubmissionForm from '@/components/Forms/BuysTrucksSubmissionForm/UpdateBuysTrucksSubmissionForm';

import ListTire from '@/components/TireMaster/ListTire';
import AddUpdateTire from '@/components/TireMaster/AddUpdateTire';
import ListApu from '@/components/ApuMaster/ListApu';
import AddUpdateApu from '@/components/ApuMaster/AddUpdateApu';

import ListBodyColor from '@/components/BodyColorMaster/ListBodyColor';
import AddUpdateBodyColor from '@/components/BodyColorMaster/AddUpdateBodyColor';

import ListBodyNotes from '@/components/BodyNotesMaster/ListBodyNotes';
import AddUpdateBodyNotes from '@/components/BodyNotesMaster/AddUpdateBodyNotes';

import ListLiftgateCapacity from '@/components/LiftgateCapacityMaster/ListLiftgateCapacity';
import AddUpdateLiftgateCapacity from '@/components/LiftgateCapacityMaster/AddUpdateLiftgateCapacity';

import ListPTO from '@/components/PTOMaster/ListPTO';
import AddUpdatePTO from '@/components/PTOMaster/AddUpdatePTO';

import ListBodyLength from '@/components/BodyLengthMaster/ListBodyLength';
import AddUpdateBodyLength from '@/components/BodyLengthMaster/AddUpdateBodyLength';

import ListLiftgate from '@/components/LiftgateMaster/ListLiftgate';
import AddUpdateLiftgate from '@/components/LiftgateMaster/AddUpdateLiftgate';

import ListBodyMake from '@/components/BodyMakeMaster/ListBodyMake';
import AddUpdateBodyMake from '@/components/BodyMakeMaster/AddUpdateBodyMake';

import ListApuModel from '@/components/ApuModelMaster/ListApuModel';
import AddUpdateApuModel from '@/components/ApuModelMaster/AddUpdateApuModel';

import ListBodyModel from '@/components/BodyModelMaster/ListBodyModel';
import AddUpdateBodyModel from '@/components/BodyModelMaster/AddUpdateBodyModel';

import ListLiftgateModel from '@/components/LiftgateModelMaster/ListLiftgateModel';
import AddUpdateLiftgateModel from '@/components/LiftgateModelMaster/AddUpdateLiftgateModel';

import ListDealStatus from '@/components/DealStatusMaster/ListDealStatus';
import AddUpdateDealStatus from '@/components/DealStatusMaster/AddUpdateDealStatus';

import ViewDocument from '@/components/DocumentMaster/ViewDocument';
import ListBrakeMake from '@/components/BrakeMakeMaster/ListBrakeMake';
import AddUpdateBrakeMake from '@/components/BrakeMakeMaster/AddUpdateBrakeMake';

import ListFrontAxleMake from '@/components/FrontAxleMakeMaster/ListFrontAxleMake';
import AddUpdateFrontAxleMake from '@/components/FrontAxleMakeMaster/AddUpdateFrontAxleMake';

import ListFrontTireMake from '@/components/FrontTireMakeMaster/ListFrontTireMake';
import AddUpdateFrontTireMake from '@/components/FrontTireMakeMaster/AddUpdateFrontTireMake';

import ListFrontTireModel from '@/components/FrontTireModelMaster/ListFrontTireModel';
import AddUpdateFrontTireModel from '@/components/FrontTireModelMaster/AddUpdateFrontTireModel';

import ListFrontWheelsModel from '@/components/FrontWheelsModelMaster/ListFrontWheelsModel';
import AddUpdateFrontWheelsModel from '@/components/FrontWheelsModelMaster/AddUpdateFrontWheelsModel';

import ListRearTireMake from '@/components/RearTireMakeMaster/ListRearTireMake';
import AddUpdateRearTireMake from '@/components/RearTireMakeMaster/AddUpdateRearTireMake';

import ListRearTireModel from '@/components/RearTireModelMaster/ListRearTireModel';
import AddUpdateRearTireModel from '@/components/RearTireModelMaster/AddUpdateRearTireModel';

import ListRearWheelsModel from '@/components/RearWheelsModelMaster/ListRearWheelsModel';
import AddUpdateRearWheelsModel from '@/components/RearWheelsModelMaster/AddUpdateRearWheelsModel';

import ListSleeperType from '@/components/SleeperTypeMaster/ListSleeperType';
import AddUpdateSleeperType from '@/components/SleeperTypeMaster/AddUpdateSleeperType';

import ListSuspensionModel from '@/components/SuspensionModelMaster/ListSuspensionModel';
import AddUpdateSuspensionModel from '@/components/SuspensionModelMaster/AddUpdateSuspensionModel';

import ListSuspensionType from '@/components/SuspensionTypeMaster/ListSuspensionType';
import AddUpdateSuspensionType from '@/components/SuspensionTypeMaster/AddUpdateSuspensionType';

import ListInquiryNotificationSettings from '@/components/InquiryNotificationSettings/ListInquiryNotificationSettings';
import AddUpdateInquiryNotificationSettings from '@/components/InquiryNotificationSettings/AddUpdateInquiryNotificationSettings';

import ListAreaOnYard from '@/components/LocationMaster/ListAreaOnYard';
import AddUpdateAreaOnYard from '@/components/LocationMaster/AddUpdateAreaOnYard';

import ListUserWiseQRCodeHistory from '@/components/QRCodeMaster/ListUserWiseQRCodeHistory';
import ListVendor from '@/components/VendorMaster/ListVendor';
import AddUpdateVendor from '@/components/VendorMaster/AddUpdateVendor';

import ListFrontWheels from '@/components/FrontWheelsMaster/ListFrontWheels';
import AddUpdateFrontWheels from '@/components/FrontWheelsMaster/AddUpdateFrontWheels';

import ListRearWheels from '@/components/RearWheelsMaster/ListRearWheels';
import AddUpdateRearWheels from '@/components/RearWheelsMaster/AddUpdateRearWheels';

import ListVendorGroup from '@/components/VendorGroupMaster/ListVendorGroup';
import AddUpdateVendorGroup from '@/components/VendorGroupMaster/AddUpdateVendorGroup';

import ListVendorItem from '@/components/VendorMaster/ListVendorItem';
import AddUpdateVendorItem from '@/components/VendorMaster/AddUpdateVendorItem';

import ListConclusion from '@/components/ConclusionMaster/ListConclusion';
import AddUpdateConclusion from '@/components/ConclusionMaster/AddUpdateConclusion';
import ListPriceBook from '@/components/PriceBookMaster/ListPriceBook';
import AddUpdatePriceBook from '@/components/PriceBookMaster/AddUpdatePriceBook';

import ListDeals from '@/components/DealMaster/ListDeals';
import ListDealsWithVehicles from '@/components/DealMaster/ListDealsWithVehicles';
import OpportunityTabView from '@/components/OpportunityMaster/OpportunityTabView';

import AddUpdateOpportunityDeals from '@/components/OpportunityMaster/DealsTab/AddUpdateOpportunityDeals';

import AddUpdateInventoryType from '@/components/InventoryTypeMaster/AddUpdateInventoryType';
import ListInventoryType from '@/components/InventoryTypeMaster/ListInventoryType';

import ListWebsite from '@/components/WebsiteMaster/ListWebsite';
import AddUpdateWebsite from '@/components/WebsiteMaster/AddUpdateWebsite';

import ListLocationExpenseType from '@/components/LocationExpenseTypeMaster/ListLocationExpenseType';
import AddUpdateLocationExpenseType from '@/components/LocationExpenseTypeMaster/AddUpdateLocationExpenseType';

import Form from '@/components/AssetMaster/BookDeal/Form';

import ListCSORequest from '@/components/Forms/CSORequest/ListCSORequest';
import AddUpdateCSORequestSubmission from '@/components/Forms/CSORequest/AddCSORequest';
import AddUpdateCSORequest from '@/components/Forms/CSORequest/UpdateCSORequest';

import ListFinanceGroup from '@/components/FinanceGroupMaster/ListFinanceGroup';
import AddUpdateFinanceGroup from '@/components/FinanceGroupMaster/AddUpdateFinanceGroup';

import ListDocumentGroup from '@/components/DocumentManagement/DocumentGroupMaster/ListDocumentGroup';
import AddUpdateDocumentGroup from '@/components/DocumentManagement/DocumentGroupMaster/AddUpdateDocumentGroup';

import ListFinanceContract from '@/components/FinanceContractMaster/ListFinanceContract';
import UpdateFinanceContract from '@/components/FinanceContractMaster/UpdateFinanceContract';

import ListFinanceService from '@/components/FinanceServiceMaster/ListFinanceService';
import AddUpdateFinanceService from '@/components/FinanceServiceMaster/AddUpdateFinanceService';

import BuildUp from '@/components/AssetMaster/BookDeal/BuildUp';
import Conquest from '@/components/AssetMaster/BookDeal/Conquest';

import HandleAccess from '@/components/PublicAccessHandler/HandleAccess';
import Fandi from '@/components/Fandi/list';

import ViewTaxRates from "@/components/TaxRateMaster/ViewTaxRates";
import AddUpdateTaxRate from "@/components/TaxRateMaster/AddUpdateTaxRate";

import TransactionSheet from "@/components/AssetMaster/TransactionSheet/TransactionSheet";

import LocationDataTab from '@/components/LocationMaster/LocationDataTab';

import ListGvwFee from '@/components/GvwFeeMaster/ListGvwFee';
import AddUpdateGvwFee from '@/components/GvwFeeMaster/AddUpdateGvwFee';

/*##### UPDATED TRANSACTION-SHEET & VHEICLE-WORK-SHEET #####*/
import NewTransactionSheet from "@/components/AssetMaster/NewTransactionSheet/Index";
import NewVehicleWorkSheet from "@/components/AssetMaster/NewTransactionSheet/VehicleWorksheet";
import NotificationManager from '@/components/NotificationMaster/NotificationManager'
import AddUserNotification from '@/components/NotificationMaster/AddUserNotification'

import ListTag from '@/components/TagMaster/Index.vue';
import AddTag from '@/components/TagMaster/AddUpdateTag.vue';
import OpportunityTab from '@/components/OpportunityMaster/OpportunityTab';
import DealsReview from '@/components/AssetMaster/DealsReview/Index.vue'
            
import CustomerContactEdit from '@/components/ContactMaster/CustomerContactEdit';

import ListTeams from '@/components/TeamsMaster/ListTeams';
import Calendar from '@/components/Calendar/Calendar';

import VendorTabView from '@/components/VendorMaster/VendorTabView';

import PageNotFound from '@/components/PageNotFound/PageNotFound'
import ListInformationReport from '@/components/Reports/ListInformationReport'
import SoldUnitByBranchReport from '@/components/Reports/SoldUnitByBranchReport'
import FlooringReport from '@/components/Reports/FlooringReport'

import CustomerInfoReport from '@/components/Reports/CustomerInfoReport'
import BranchLocationReport from '@/components/Reports/BranchLocationReport'
import FinanceReserveReport from '@/components/Reports/FinanceReserveReport'
import LocationInventoryReport from '@/components/Reports/LocationInventoryReport'
import AllInventoryForAccountingReport from '@/components/Reports/AllInventoryForAccountingReport'
import VehicleWarrantyReport from '@/components/Reports/VehicleWarrantyReport'
import GPReport from '@/components/Reports/GPReport'
import BrandValueReport from '@/components/Reports/BrandValueReport'
import DealLevelReport from '@/components/Reports/DealLevelReport'

import ListBatch from '@/components/BatchMaster/ListBatch.vue';

import UploadGuideDocument from '@/components/GuideDocument/UploadGuideDocument';

import VendorLogin from '@/components/Login/VendorLogin';
import VendorDashboard from '@/components/VendorPortal/VendorDashboard';
import VendorForgotPassword from '@/components/Login/VendorForgotPassword';
import VendorResetPassword from '@/components/Login/VendorResetPassword';
import VendorResetLinkNotFound from '@/components/Login/VendorResetLinkNotFound';

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/vendor/login',
            name: 'VendorLogin',
            component: VendorLogin
        },
        {
            path: '/vendor/forgotpassword',
            name: 'VendorForgotPassword',
            component: VendorForgotPassword,
        },
        {
            path: '/vendor/resetpassword/:token',
            name: 'VendorResetPassword',
            component: VendorResetPassword,
        },
        {
            path: '/vendor/reset-link-not-found',
            name: 'VendorResetLinkNotFound',
            component: VendorResetLinkNotFound
        },
        {
            path: '/vendor/dashboard',
            name: 'VendorDashboard',
            component: VendorDashboard
        },
        {
            path: '/branch/list',
            name: 'ListBranch',
            component: ListBranch
        },
        {
            path: '/branch/add',
            name: 'AddBranch',
            component: AddUpdateBranch
        },
        {
            path: '/branch/edit/:id',
            name: 'UpdateBranch',
            component: AddUpdateBranch
        },
        {
            path: '/trucktrailersearchmadeeasy/add',
            name: 'AddUpdateTruckAndTrailerSearchMadeEasy',
            component: AddUpdateTruckAndTrailerSearchMadeEasy
        },
        {
            path: '/offersubmission/add',
            name: 'AddUpdateOfferSubmission',
            component: AddUpdateOfferSubmission
        },
        {
            path: '/usedtruckholdsubmission/add',
            name: 'AddUpdateUsedTruckHoldSubmission',
            component: AddUpdateUsedTruckHoldSubmission
        },
        {
            path: '/usedtruckmovedsubmission/list',
            name: 'ListUsedTruckMovedSubmission',
            component: ListUsedTruckMovedSubmission
        },
        {
            path: '/usedtruckmovedsubmission/add',
            name: 'AddUpdateUsedTruckMovedSubmission',
            component: AddUpdateUsedTruckMovedSubmission
        },
        {
            path: '/usedtruckmovedsubmission/edit/:id',
            name: 'UpdateUsedTruckMovedSubmission',
            component: UpdateUsedTruckMovedSubmission
        },
        {
            path: '/porequest/add',
            name: 'AddUpdatePORequestSubmission',
            component: AddUpdatePORequestSubmission
        },
        {
            path: '/vehicalcheckin/add',
            name: 'AddUpdateVehicalCheckIn',
            component: AddUpdateVehicalCheckIn
        },
        {
            path: '/referralsource/list',
            name: 'ListReferralSource',
            component: ListReferralSource
        },
        {
            path: '/referralsource/add',
            name: 'AddReferralSource',
            component: AddUpdateReferralSource
        },
        {
            path: '/email/spec',
            name: 'EmailSpecSheetTabView',
            component: EmailSpecSheetTabView,
            props : true
        },
        {
            path: '/referralsource/edit/:id',
            name: 'UpdateReferralSource',
            component: AddUpdateReferralSource
        },
        {
            path: '/enginemodel/list',
            name: 'ListEngineModel',
            component: ListEngineModel
        },
        {
            path: '/enginemodel/add',
            name: 'AddEngineModel',
            component: AddUpdateEngineModel
        },
        {
            path: '/enginemodel/edit/:id',
            name: 'UpdateEngineModel',
            component: AddUpdateEngineModel
        },
        {
            path: '/transmodel/list',
            name: 'ListTransModel',
            component: ListTransModel
        },
        {
            path: '/transmodel/add',
            name: 'AddTransModel',
            component: AddUpdateTransModel
        },
        {
            path: '/transmodel/edit/:id',
            name: 'UpdateTransModel',
            component: AddUpdateTransModel
        },
        {
            path: '/leadquality/list',
            name: 'ListLeadQuality',
            component: ListLeadQuality
        },
        {
            path: '/leadquality/add',
            name: 'AddLeadQuality',
            component: AddUpdateLeadQuality
        },
        {
            path: '/leadquality/edit/:id',
            name: 'UpdateLeadQuality',
            component: AddUpdateLeadQuality
        },
        {
            path: '/fundingsource/list',
            name: 'ListFundingSource',
            component: ListFundingSource
        },
        {
            path: '/fundingsource/add',
            name: 'AddFundingSource',
            component: AddUpdateFundingSource
        },
        {
            path: '/fundingsource/edit/:id',
            name: 'UpdateFundingSource',
            component: AddUpdateFundingSource
        },
        {
            path: '/fundingmethod/list',
            name: 'ListFundingMethod',
            component: ListFundingMethod
        },
        {
            path: '/fundingmethod/add',
            name: 'AddFundingMethod',
            component: AddUpdateFundingMethod
        },
        {
            path: '/fundingmethod/edit/:id',
            name: 'UpdateFundingMethod',
            component: AddUpdateFundingMethod
        },
        {
            path: '/fundingstatus/list',
            name: 'ListFundingStatus',
            component: ListFundingStatus
        },
        {
            path: '/fundingstatus/add',
            name: 'AddFundingStatus',
            component: AddUpdateFundingStatus
        },
        {
            path: '/fundingstatus/edit/:id',
            name: 'UpdateFundingStatus',
            component: AddUpdateFundingStatus
        },
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/forgotpassword',
            name: 'ForgotPassword',
            component: ForgotPassword
        },
        {
            path: '/global/search',
            name: 'GlobalSearch',
            component: GlobalSearch
        },
        {
            path: '/summary/print',
            name: 'AssetPrintableSummary',
            component: AssetPrintableSummary
        },
        {
            path: '/compare/inventory',
            name: 'compareAsset',
            component: compareAsset
        },
        {
            path: '/pub/print',
            name: 'AssetSpecSheet',
            component: AssetSpecSheet
        },
        {
            path: '/pub/view',
            name: 'AssetSpecSheetVINStock',
            component: AssetSpecSheetVINStock
        },
        {
            path: '/pub/multi-print',
            name: 'PrintMultipleInventory',
            component: PrintMultipleInventory
        },
        {
            path: '/pub/print/promo',
            name: 'AssetPromoSpecSheet',
            component: AssetPromoSpecSheet
        },
        {
            path: '/email/promo/sheet',
            name: 'EmailPromoSheet',
            component: EmailPromoSheet
        },
        {
            path: '/user/list',
            name: 'ListUser',
            component: ListUser
        },
        {
            path: '/add/user',
            name: 'AddUpdateUser',
            component: AddUpdateUser
        },
        {
            path: '/edit/user/:id',
            name: 'UpdateUser',
            component: AddUpdateUser
        },
        {
            path: '/user/detail/:id',
            name: 'UserTabView',
            component: UserDataTab,
        },
        {
            path: '/filter-component',
            name: 'FilterComponent',
            component: FilterComponent
        },
        {
            path: '/exhaust/list',
            name: 'ListExhaust',
            component: ListExhaust
        },
        {
            path: '/exhaust/add',
            name: 'AddUpdateExhaust',
            component: AddUpdateExhaust
        },
        {
            path: '/exhaust/edit/:id',
            name: 'UpdateExhaust',
            component: AddUpdateExhaust
        },
        {
            path: '/expensecategory/list',
            name: 'ListExpenseCategory',
            component: ListExpenseCategory
        },
        {
            path: '/expensecategory/add',
            name: 'AddUpdateExpenseCategory',
            component: AddUpdateExpenseCategory
        },
        {
            path: '/expensecategory/edit/:id',
            name: 'UpdateExpenseCategory',
            component: AddUpdateExpenseCategory
        },
        {
            path: '/breathers/list',
            name: 'ListBreathers',
            component: ListBreathers
        },
        {
            path: '/breathers/add',
            name: 'AddUpdateBreathers',
            component: AddUpdateBreathers
        },
        {
            path: '/breathers/edit/:id',
            name: 'UpdateBreathers',
            component: AddUpdateBreathers
        },
        {
            path: '/dropaxleconfig/list',
            name: 'ListDropAxleConfig',
            component: ListDropAxleConfig
        },
        {
            path: '/dropaxleconfig/add',
            name: 'AddUpdateDropAxleConfig',
            component: AddUpdateDropAxleConfig
        },
        {
            path: '/dropaxleconfig/edit/:id',
            name: 'UpdateDropAxleConfig',
            component: AddUpdateDropAxleConfig
        },
        {
            path: '/axletype/list',
            name: 'ListAxleType',
            component: ListAxleType
        },
        {
            path: '/axletype/add',
            name: 'AddUpdateAxleType',
            component: AddUpdateAxleType
        },
        {
            path: '/axletype/edit/:id',
            name: 'UpdateAxleType',
            component: AddUpdateAxleType
        },
        {
            path: '/specification/list',
            name: 'ListSpecification',
            component: ListSpecification
        },
        {
            path: '/specification/add',
            name: 'AddSpecification',
            component: AddUpdateSpecification
        },
        {
            path: '/specification/edit/:id',
            name: 'UpdateSpecification',
            component: AddUpdateSpecification
        },
        {
            path: '/suspension/list',
            name: 'ListSuspension',
            component: ListSuspension
        },
        {
            path: '/suspension/add',
            name: 'AddUpdateSuspension',
            component: AddUpdateSuspension
        },
        {
            path: '/suspension/edit/:id',
            name: 'UpdateSuspension',
            component: AddUpdateSuspension
        },
        {
            path: '/braketype/list',
            name: 'ListBrakeType',
            component: ListBrakeType
        },
        {
            path: '/braketype/add',
            name: 'AddBrakeType',
            component: AddUpdateBrakeType
        },
        {
            path: '/braketype/edit/:id',
            name: 'UpdateBrakeType',
            component: AddUpdateBrakeType
        },
        {
            path: '/cabtype/list',
            name: 'ListCabType',
            component: ListCabType
        },
        {
            path: '/cabtype/add',
            name: 'AddUpdateCabType',
            component: AddUpdateCabType
        },
        {
            path: '/cabtype/edit/:id',
            name: 'UpdateCabType',
            component: AddUpdateCabType
        },
        {
            path: '/cabstyle/list',
            name: 'ListCabStyle',
            component: ListCabStyle
        },
        {
            path: '/cabstyle/add',
            name: 'AddUpdateCabStyle',
            component: AddUpdateCabStyle
        },
        {
            path: '/cabstyle/edit/:id',
            name: 'UpdateCabStyle',
            component: AddUpdateCabStyle
        },
        {
            path: '/fairings/list',
            name: 'ListFairings',
            component: ListFairings
        },
        {
            path: '/fairings/add',
            name: 'AddUpdateFairings',
            component: AddUpdateFairings
        },
        {
            path: '/fairings/edit/:id',
            name: 'UpdateFairings',
            component: AddUpdateFairings
        },
        {
            path: '/make/list',
            name: 'ListMake',
            component: ListMake
        },
        {
            path: '/make/add',
            name: 'AddUpdateMake',
            component: AddUpdateMake
        },
        {
            path: '/make/edit/:id',
            name: 'UpdateMake',
            component: AddUpdateMake
        },
        {
            path: '/model/list',
            name: 'ListModel',
            component: ListModel
        },
        {
            path: '/model/add',
            name: 'AddUpdateModel',
            component: AddUpdateModel
        },
        {
            path: '/model/edit/:id',
            name: 'UpdateModel',
            component: AddUpdateModel
        },
        {
            path: '/fueltype/list',
            name: 'ListFuelType',
            component: ListFuelType
        },
        {
            path: '/fueltype/add',
            name: 'AddFuelType',
            component: AddUpdateFuelType
        },
        {
            path: '/fueltype/edit/:id',
            name: 'UpdateFuelType',
            component: AddUpdateFuelType
        },
        {
            path: '/condition/list',
            name: 'ListCondition',
            component: ListCondition
        },
        {
            path: '/condition/add',
            name: 'AddCondition',
            component: AddUpdateCondition
        },
        {
            path: '/condition/edit/:id',
            name: 'UpdateCondition',
            component: AddUpdateCondition
        },
        {
            path: '/status/list',
            name: 'ListStatus',
            component: ListStatus
        },
        {
            path: '/status/add',
            name: 'AddStatus',
            component: AddUpdateStatus
        },
        {
            path: '/status/edit/:id',
            name: 'UpdateStatus',
            component: AddUpdateStatus
        },
        {
            path: '/saletype/list',
            name: 'ListSaleType',
            component: ListSaleType
        },
        {
            path: '/saletype/add',
            name: 'AddSaleType',
            component: AddUpdateSaleType
        },
        {
            path: '/saletype/edit/:id',
            name: 'UpdateSaleType',
            component: AddUpdateSaleType
        },
        {
            path: '/warrantytype/list',
            name: 'ListWarrantyType',
            component: ListWarrantyType
        },
        {
            path: '/warrantytype/add',
            name: 'AddWarrantyType',
            component: AddUpdateWarrantyType
        },
        {
            path: '/warrantytype/edit/:id',
            name: 'UpdateWarrantyType',
            component: AddUpdateWarrantyType
        },
        {
            path: '/enginemake/list',
            name: 'ListEngineMake',
            component: ListEngineMake
        },
        {
            path: '/enginemake/add',
            name: 'AddEngineMake',
            component: AddUpdateEngineMake
        },
        {
            path: '/enginemake/edit/:id',
            name: 'UpdateEngineMake',
            component: AddUpdateEngineMake
        },

        {
            path: '/enginecylinder/list',
            name: 'ListEngineCylinder',
            component: ListEngineCylinder
        },
        {
            path: '/enginecylinder/add',
            name: 'AddEngineCylinder',
            component: AddUpdateEngineCylinder
        },
        {
            path: '/enginecylinder/edit/:id',
            name: 'UpdateEngineCylinder',
            component: AddUpdateEngineCylinder
        },
        {
            path: '/apumake/list',
            name: 'ListApuMake',
            component: ListApuMake
        },
        {
            path: '/apumake/add',
            name: 'AddApuMake',
            component: AddUpdateApuMake
        },
        {
            path: '/edit/apumake/:id',
            name: 'UpdateApuMake',
            component: AddUpdateApuMake
        },
        {
            path: '/transspeed/list',
            name: 'ListTransSpeed',
            component: ListTransSpeed
        },
        {
            path: '/transspeed/add',
            name: 'AddTransSpeed',
            component: AddUpdateTransSpeed
        },
        {
            path: '/transspeed/edit/:id',
            name: 'UpdateTransSpeed',
            component: AddUpdateTransSpeed
        },
        {
            path: '/transtype/list',
            name: 'ListTransType',
            component: ListTransType
        },
        {
            path: '/transtype/add',
            name: 'AddTransType',
            component: AddUpdateTransType
        },
        {
            path: '/transtype/edit/:id',
            name: 'UpdateTransType',
            component: AddUpdateTransType
        },
        {
            path: '/transmake/list',
            name: 'ListTransMake',
            component: ListTransMake
        },
        {
            path: '/transmake/add',
            name: 'AddTransMake',
            component: AddUpdateTransMake
        },
        {
            path: '/transmake/edit/:id',
            name: 'UpdateTransMake',
            component: AddUpdateTransMake
        },

        {
            path: '/opportunity/list',
            name: 'ListOpportunity',
            component: ListOpportunity
        },
        {
            path: '/opportunity/add',
            name: 'AddOpportunity',
            component: AddUpdateOpportunity
        },
        {
            path: '/opportunity/edit/:id',
            name: 'UpdateOpportunity',
            component: AddUpdateOpportunity
        },
        {
            path: '/contactgroup/list',
            name: 'ListContactGroup',
            component: ListContactGroup
        },
        {
            path: '/contactgroup/add',
            name: 'AddContactGroup',
            component: AddUpdateContactGroup
        },
        {
            path: '/contactgroup/edit/:id',
            name: 'UpdateContactGroup',
            component: AddUpdateContactGroup
        },
        {
            path: '/vendorgroup/list',
            name: 'ListVendorGroup',
            component: ListVendorGroup
        },
        {
            path: '/vendorgroup/add',
            name: 'AddVendorGroup',
            component: AddUpdateVendorGroup
        },
        {
            path: '/vendorgroup/edit/:id',
            name: 'UpdateVendorGroup',
            component: AddUpdateVendorGroup
        },
        {
            path: '/contact/dashboard',
            name: 'CustomerDashboard',
            component: CustomerDashboard
        },
        {
            path: '/customer/list',
            name: 'ListContact',
            component: ListContact
        },
        {
            path: '/contact/emaillist',
            name: 'ListContactEmail',
            component: ListContactEmail
        },
        {
            path: '/contact/emailview/:id',
            name: 'ViewContactEmail',
            component: ViewContactEmail
        },
        {
            path: '/contact/add',
            name: 'AddContact',
            component: AddUpdateContact,
            props : true
        },
        {
            path: '/customer/edit/:id',
            name: 'UpdateContact',
            component: AddUpdateContact,
            
        },
        {
            path: '/customer/edit/:customer_id/contact/:contact_id',
            name: 'UpdateCustomerContact',
            component: CustomerContactEdit,
        },

        
        {
            name: 'VehicleServiceDetails',
            path: '/customer/edit/:id/service/:sls_id',
            component: VehicleServiceDetails
        },
        {
            path: '/invoice/list',
            name: 'ListInvoice',
            component: ListInvoice
        },
        {
            path: '/invoice/add',
            name: 'AddUpdateInvoice',
            component: AddUpdateInvoice
        },
        {
            path: '/invoice/edit/:id',
            name: 'UpdateInvoice',
            component: AddUpdateInvoice
        },
        {
            path: '/contactlead/list',
            name: 'ListContactLead',
            component: ListContactLead
        },
        {
            path: '/contactlead/add',
            name: 'AddUpdateContactLead',
            component: AddUpdateContactLead
        },
        {
            path: '/contactlead/edit/:id',
            name: 'UpdateContactLead',
            component: AddUpdateContactLead
        },
        {
            path: '/company/list',
            name: 'ListCompany',
            component: ListCompany
        },
        {
            path: '/company/add',
            name: 'AddUpdateCompany',
            component: AddUpdateCompany
        },
        {
            path: '/company/edit/:id',
            name: 'UpdateCompany',
            component: AddUpdateCompany
        },
        {
            path: '/department/list',
            name: 'ListDepartment',
            component: ListDepartment
        },
        {
            path: '/department/add',
            name: 'AddUpdateDepartment',
            component: AddUpdateDepartment
        },
        {
            path: '/department/edit/:id',
            name: 'UpdateDepartment',
            component: AddUpdateDepartment
        },
        {
            path: '/location/list',
            name: 'ListLocation',
            component: ListLocation
        },
        {
            path: '/location/add',
            name: 'AddUpdateLocation',
            component: AddUpdateLocation
        },
        {
            path: '/location/edit/:id',
            name: 'UpdateLocation',
            component: AddUpdateLocation
        },
        {
            path: '/city/list',
            name: 'ListCity',
            component: ListCity
        },
        {
            path: '/state/list',
            name: 'ListState',
            component: ListState
        },
        {
            path: '/location/expense/type/:id',
            name: 'DraggableExpenseType',
            component: DraggableExpenseType
        },
        {
            path: '/expense/list',
            name: 'ListAssetExpenses',
            component: ListAssetExpenses
        },
        {
            path: '/inventory/list/onorder',
            name: 'ListOnOrderAsset',
            component: ListOnOrderAsset
        },
        {
            path: '/inventory/list',
            name: 'ListAsset',
            component: ListAsset
        },
        {
            path: '/delete/inventory/list',
            name: 'DeleteAssetList',
            component: DeleteAssetList
        },
        {
            path: '/inventory/add',
            name: 'AddUpdateAsset',
            component: AddUpdateAsset
        },
        {
            path: '/inventory/tabview/:id',
            name: 'AssetTabView',
            component: AssetTabView,
            props : true
        },
        {
            path: '/inventory/edit/multiple/',
            name: 'MultyAssetTabView',
            component: MultyAssetTabView
        },
        {
            path: '/inventory/print/QRCode',
            name: 'PrintQrCode',
            component: PrintQrCode
        },
        {
            path: '/inventory/specification',
            name: 'AssetSpecification',
            component: AssetSpecification
        },
        {
            path: '/inventory/add-core',
            name: 'AddAsset',
            component: AddAsset
        },
        {
            path: '/inventory/add-close',
            name: 'AssetCloseTab',
            component: AssetCloseTab
        },
        {
            path: '/inventory/add-commission-manager',
            name: 'AssetCommissionManagerTab',
            component: AssetCommissionManagerTab
        },
        {
            path: '/inventory/edit/:id',
            name: 'UpdateAsset',
            component: AddUpdateAsset
        },
        {
            path: '/inventory/view/:id',
            name: 'ViewAsset',
            component: ViewAsset
        },
        {
            path: '/expense/add',
            name: 'AddUpdateAssetExpenses',
            component: AddUpdateAssetExpenses
        },
        {
            path: '/expense/edit/:id',
            name: 'UpdateAssetExpenses',
            component: AddUpdateAssetExpenses
        },
        {
            path: '/role/list',
            name: 'ListRole',
            component: ListRole
        },
        {
            path: '/role/add',
            name: 'AddRole',
            component: AddUpdateRole
        },
        {
            path: '/role/edit/:id',
            name: 'UpdateRole',
            component: AddUpdateRole
        },
        {
            path: '/interior/list',
            name: 'ListInterior',
            component: ListInterior
        },
        {
            path: '/interior/add',
            name: 'AddUpdateInterior',
            component: AddUpdateInterior
        },
        {
            path: '/interior/edit/:id',
            name: 'UpdateInterior',
            component: AddUpdateInterior
        },
        {
            path: '/trailertype/list',
            name: 'ListTrailerType',
            component: ListTrailerType
        },
        {
            path: '/trailertype/add',
            name: 'AddUpdateTrailerType',
            component: AddUpdateTrailerType
        },
        {
            path: '/trailertype/edit/:id',
            name: 'UpdateTrailerType',
            component: AddUpdateTrailerType
        },
        {
            path: '/sidedoortype/list',
            name: 'ListSideDoorType',
            component: ListSideDoorType
        },
        {
            path: '/sidedoortype/add',
            name: 'AddUpdateSideDoorType',
            component: AddUpdateSideDoorType
        },
        {
            path: '/sidedoortype/edit/:id',
            name: 'UpdateSideDoorType',
            component: AddUpdateSideDoorType
        },
        {
            path: '/bodystyle/list',
            name: 'ListBodyStyle',
            component: ListBodyStyle
        },
        {
            path: '/bodystyle/add',
            name: 'AddBodyStyle',
            component: AddUpdateBodyStyle
        },
        {
            path: '/bodystyle/edit/:id',
            name: 'UpdateBodyStyle',
            component: AddUpdateBodyStyle
        },
        {
            path: '/bodytype/list',
            name: 'ListBodyType',
            component: ListBodyType
        },
        {
            path: '/bodytype/add',
            name: 'AddBodyType',
            component: AddUpdateBodyType
        },
        {
            path: '/bodytype/edit/:id',
            name: 'UpdateBodyType',
            component: AddUpdateBodyType
        },
        {
            path: '/dumpmake/list',
            name: 'ListDumpMake',
            component: ListDumpMake
        },
        {
            path: '/dumpmake/add',
            name: 'AddDumpMake',
            component: AddUpdateDumpMake
        },
        {
            path: '/dumpmake/edit/:id',
            name: 'UpdateDumpMake',
            component: AddUpdateDumpMake
        },
        {
            path: '/dumptype/list',
            name: 'ListDumpType',
            component: ListDumpType
        },
        {
            path: '/dumptype/add',
            name: 'AddDumpType',
            component: AddUpdateDumpType
        },
        {
            path: '/dumptype/edit/:id',
            name: 'UpdateDumpType',
            component: AddUpdateDumpType
        },
        {
            path: '/floortype/list',
            name: 'ListFloorType',
            component: ListFloorType
        },
        {
            path: '/floortype/add',
            name: 'AddFloorType',
            component: AddUpdateFloorType
        },
        {
            path: '/floortype/edit/:id',
            name: 'UpdateFloorType',
            component: AddUpdateFloorType
        },
        {
            path: '/frame/list',
            name: 'ListFrame',
            component: ListFrame
        },
        {
            path: '/frame/add',
            name: 'AddFrame',
            component: AddUpdateFrame
        },
        {
            path: '/frame/edit/:id',
            name: 'UpdateFrame',
            component: AddUpdateFrame
        },
        {
            path: '/commissiontype/add',
            name: 'AddCommissionType',
            component: AddUpdateCommissionType
        },
        {
            path: '/commission/edit/:id',
            name: 'UpdateCommissionType',
            component: AddUpdateCommissionType
        },
        {
            path: '/commissiontype/list',
            name: 'ListCommissionType',
            component: ListCommissionType
        },
        {
            path: '/closetype/add',
            name: 'AddCloseType',
            component: AddUpdateCloseType
        },
        {
            path: '/closetype/edit/:id',
            name: 'UpdateCloseType',
            component: AddUpdateCloseType
        },
        {
            path: '/closetype/list',
            name: 'ListCloseType',
            component: ListCloseType
        },
        {
            path: '/reardoortype/list',
            name: 'ListRearDoorType',
            component: ListRearDoorType
        },
        {
            path: '/reardoortype/add',
            name: 'AddRearDoorType',
            component: AddUpdateRearDoorType
        },
        {
            path: '/reardoortype/edit/:id',
            name: 'UpdateRearDoorType',
            component: AddUpdateRearDoorType
        },
        {
            path: '/reefermake/list',
            name: 'ListReeferMake',
            component: ListReeferMake
        },
        {
            path: '/reefermake/add',
            name: 'AddReeferMake',
            component: AddUpdateReeferMake
        },
        {
            path: '/reefermake/edit/:id',
            name: 'UpdateReeferMake',
            component: AddUpdateReeferMake
        },
        {
            path: '/rooftype/list',
            name: 'ListRoofType',
            component: ListRoofType
        },
        {
            path: '/rooftype/add',
            name: 'AddRoofType',
            component: AddUdpateRoofType
        },
        {
            path: '/rooftype/edit/:id',
            name: 'UpdateRoofType',
            component: AddUdpateRoofType
        },
        {
            path: '/emails',
            name: 'ListMail',
            component: ListMail
        },
        {
            path: '/formemailmanager/list',
            name: 'ListFormEmailManager',
            component: ListFormEmailManager
        },
        {
            path: '/formemailmanager/add',
            name: 'AddFormEmailManager',
            component: AddUpdateFormEmailManager
        },
        {
            path: '/formemailmanager/edit/:id',
            name: 'UpdateFormEmailManager',
            component: AddUpdateFormEmailManager
        },
        {
            path: '/porequest/list',
            name: 'ListPORequest',
            component: ListPORequest
        },
        {
            path: '/porequest/edit/:id',
            name: 'UpdatePORequest',
            component: AddUpdatePORequest
        },
        {
            path: '/vehiclecheckin/list',
            name: 'ListVehicleCheckIn',
            component: ListVehicleCheckIn
        },
        {
            path: '/vehiclecheckin/edit/:id',
            name: 'UpdateVehicleCheckIn',
            component: AddUpdateVehicleCheckIn
        },
        {
            path: '/trucktrailersearchmadeeasy/list',
            name: 'ListTruckTrailerSearchMadeEasy',
            component: ListTruckTrailerSearchMadeEasy
        },
        {
            path: '/trucktrailersearchmadeeasy/edit/:id',
            name: 'UpdateTruckTrailerSearchMadeEasy',
            component: AddUpdateTruckTrailerSearchMadeEasy
        },
        {
            path: '/usedtruckholdssubmissionform/list',
            name: 'ListUsedTruckHoldsSubmissionForm',
            component: ListUsedTruckHoldsSubmissionForm
        },
        {
            path: '/usedtruckholdssubmissionform/edit/:id',
            name: 'UpdateUsedTruckHoldsSubmissionForm',
            component: AddUpdateUsedTruckHoldsSubmissionForm
        },
        {
            path: '/offersubmission/list',
            name: 'ListOfferSubmission',
            component: ListOfferSubmission
        },
        {
            path: '/offersubmission/edit/:id',
            name: 'UpdateOfferSubmission',
            component: UpdateOfferSubmission
        },
        {
            path: '/activity/list',
            name: 'ListActivity',
            component: ListActivity
        },
        {
            path: '/activity/view/:id',
            name: 'ViewUpdateActivity',
            component: ViewUpdateActivity
        },
        {
            path: '/activity/add',
            name: 'AddUpdateActivity',
            component: AddUpdateActivity
        },
        {
            path: '/activity/edit/:id',
            name: 'UpdateActivity',
            component: AddUpdateActivity
        },
        {
            path: '/task/list',
            name: 'ListTaskMaster',
            component: ListTaskMaster
        },
        {
            path: '/task/add',
            name: 'AddUpdateTaskMaster',
            component: AddUpdateTaskMaster
        },
        {
            path: '/task/edit/:id',
            name: 'UpdateTaskMaster',
            component: AddUpdateTaskMaster
        },
        {
            path: '/task/view/:id',
            name: 'ViewTaskMaster',
            component: ViewTaskMaster
        },
        {
            path: '/case/list',
            name: 'ListCaseMaster',
            component: ListCaseMaster
        },
        {
            path: '/case/add',
            name: 'AddUpdateCaseMaster',
            component: AddUpdateCaseMaster
        },
        {
            path: '/case/edit/:id',
            name: 'UpdateCaseMaster',
            component: AddUpdateCaseMaster
        },
        {
            path: '/case/view/:id',
            name: 'ViewCaseMaster',
            component: ViewCaseMaster
        },
        {
            path: '/call/email/list',
            name: 'ListCallsAndEmails',
            component: ListCallsAndEmails
        },
        {
            path: '/call/email/add',
            name: 'AddUpdateCallsAndEmails',
            component: AddUpdateCallsAndEmails
        },
        {
            path: '/call/email/edit/:id',
            name: 'UpdateCallsAndEmails',
            component: AddUpdateCallsAndEmails
        },
        {
            path: '/call/email/view/:id',
            name: 'ViewCallsAndEmails',
            component: ViewCallsAndEmails
        },
        {
            path: '/dtrtradeterms/list',
            name: 'ListDtrTradeTerms',
            component: ListDtrTradeTerms
        },
        {
            path: '/dtrtradeterms/add',
            name: 'AddDtrTradeTerms',
            component: AddUpdateDtrTradeTerms
        },
        {
            path: '/edit/dtrtradeterms/:id',
            name: 'UpdateDtrTradeTerms',
            component: AddUpdateDtrTradeTerms
        },
        {
            path: '/fleet/list',
            name: 'ListFleet',
            component: ListFleet
        },
        {
            path: '/fleet/add',
            name: 'AddFleet',
            component: AddUpdateFleet
        },
        {
            path: '/edit/fleet/:id',
            name: 'UpdateFleet',
            component: AddUpdateFleet
        },
        /* {
            path: '/notification/email/manager/list',
            name: 'ListNotificationEmailManager',
            component: ListNotificationEmailManager
        },
        {
            path: '/notification/email/manager/add',
            name: 'AddUpdateNotificationEmailManager',
            component: AddUpdateNotificationEmailManager
        },
        {
            path: '/notification/email/manager/edit/:id',
            name: 'UpdateNotificationEmailManager',
            component: AddUpdateNotificationEmailManager
        }, */
        {
            path: '/buytrucksubmission/list',
            name: 'ListBuyTruckSubmissionForm',
            component: ListBuyTruckSubmissionForm
        },
        {
            path: '/buytrucksubmission/add',
            name: 'AddUpdateBuysTrucksSubmissionForm',
            component: AddUpdateBuysTrucksSubmissionForm
        },
        {
            path: '/buytrucksubmission/edit/:id',
            name: 'UpdateBuysTrucksSubmissionForm',
            component: UpdateBuysTrucksSubmissionForm
        },
        {
            path: '/tire/list',
            name: 'ListTire',
            component: ListTire
        },
        {
            path: '/tire/add',
            name: 'AddUpdateTire',
            component: AddUpdateTire
        },
        {
            path: '/tire/edit/:id',
            name: 'UpdateTire',
            component: AddUpdateTire
        },
        {
            path: '/formquotation/list',
            name: 'ListQuotation',
            component: ListQuotation
        },
        {
            path: '/formquotation/add',
            name: 'AddUpdateQuotation',
            component: AddUpdateQuotation
        },
        {
            path: '/formquotation/edit/:id',
            name: 'UpdateQuotation',
            component: AddUpdateQuotation
        },
        {
            path: '/hold/status/list',
            name: 'ListHoldStatus',
            component: ListHoldStatus
        },
        {
            path: '/hold/status/add',
            name: 'AddUpdateHoldStatus',
            component: AddUpdateHoldStatus
        },
        {
            path: '/hold/status/edit/:id',
            name: 'UpdateHoldStatus',
            component: AddUpdateHoldStatus
        },
        {
            path: '/repairorder/list',
            name: 'ListRepairOrder',
            component: ListRepairOrder
        },
        {
            path: '/repairorderops/list/:sls_id',
            name: 'ListRepairOrderOps',
            component: ListRepairOrderOps
        },
        {
            path: '/repairorderitem/list/:sls_id',
            name: 'ListRepairOrderItems',
            component: ListRepairOrderItems
        },
        {
            path: '/repairorderemplbr/list/:id/:sls_id',
            name: 'ListRepairOrderEmpLbr',
            component: ListRepairOrderEmpLbr
        },
        {
            path: '/inventory/setting/list',
            name: 'ListInventorySetting',
            component: ListInventorySetting
        },
        {
            path: '/inventory/setting/add',
            name: 'AddUpdateInventorySetting',
            component: AddUpdateInventorySetting
        },
        {
            path: '/inventory/setting/edit/:id',
            name: 'UpdateInventorySetting',
            component: AddUpdateInventorySetting
        },
        {
            path: '/document/list',
            name: 'ListDocument',
            component: ListDocument
        },
        {
            path: '/document/add',
            name: 'AddUpdateDocument',
            component: AddUpdateDocument
        },
        {
            path: '/document/edit/:id',
            name: 'UpdateDocument',
            component: AddUpdateDocument
        },
        {
            path: '/apu/list',
            name: 'ListApu',
            component: ListApu
        },
        {
            path: '/apu/add',
            name: 'AddUpdateApu',
            component: AddUpdateApu
        },
        {
            path: '/apu/edit/:id',
            name: 'UpdateApu',
            component: AddUpdateApu
        },
        {
            path: '/body/color/list',
            name: 'ListBodyColor',
            component: ListBodyColor
        },
        {
            path: '/body/color/add',
            name: 'AddUpdateBodyColor',
            component: AddUpdateBodyColor
        },
        {
            path: '/body/color/edit/:id',
            name: 'UpdateBodyColor',
            component: AddUpdateBodyColor
        },
        {
            path: '/body/notes/list',
            name: 'ListBodyNotes',
            component: ListBodyNotes
        },
        {
            path: '/body/notes/add',
            name: 'AddUpdateBodyNotes',
            component: AddUpdateBodyNotes
        },
        {
            path: '/body/notes/edit/:id',
            name: 'UpdateBodyNotes',
            component: AddUpdateBodyNotes
        },
        {
            path: '/liftgate/capacity/list',
            name: 'ListLiftgateCapacity',
            component: ListLiftgateCapacity
        },
        {
            path: '/liftgate/capacity/add',
            name: 'AddUpdateLiftgateCapacity',
            component: AddUpdateLiftgateCapacity
        },
        {
            path: '/liftgate/capacity/edit/:id',
            name: 'UpdateLiftgateCapacity',
            component: AddUpdateLiftgateCapacity
        },
        {
            path: '/pto/list',
            name: 'ListPTO',
            component: ListPTO
        },
        {
            path: '/pto/add',
            name: 'AddUpdatePTO',
            component: AddUpdatePTO
        },
        {
            path: '/pto/edit/:id',
            name: 'UpdatePTO',
            component: AddUpdatePTO
        },
        {
            path: '/body/length/list',
            name: 'ListBodyLength',
            component: ListBodyLength
        },
        {
            path: '/body/length/add',
            name: 'AddUpdateBodyLength',
            component: AddUpdateBodyLength
        },
        {
            path: '/body/length/edit/:id',
            name: 'UpdateBodyLength',
            component: AddUpdateBodyLength
        },
        {
            path: '/liftgate/list',
            name: 'ListLiftgate',
            component: ListLiftgate
        },
        {
            path: '/liftgate/add',
            name: 'AddUpdateLiftgate',
            component: AddUpdateLiftgate
        },
        {
            path: '/liftgate/edit/:id',
            name: 'UpdateLiftgate',
            component: AddUpdateLiftgate
        },
        {
            path: '/body/make/list',
            name: 'ListBodyMake',
            component: ListBodyMake
        },
        {
            path: '/body/make/add',
            name: 'AddUpdateBodyMake',
            component: AddUpdateBodyMake
        },
        {
            path: '/body/make/edit/:id',
            name: 'UpdateBodyMake',
            component: AddUpdateBodyMake
        },
        {
            path: '/apu/model/list',
            name: 'ListApuModel',
            component: ListApuModel
        },
        {
            path: '/apu/model/add',
            name: 'AddUpdateApuModel',
            component: AddUpdateApuModel
        },
        {
            path: '/apu/model/edit/:id',
            name: 'UpdateApuModel',
            component: AddUpdateApuModel
        },
        {
            path: '/body/model/list',
            name: 'ListBodyModel',
            component: ListBodyModel
        },
        {
            path: '/body/model/add',
            name: 'AddUpdateBodyModel',
            component: AddUpdateBodyModel
        },
        {
            path: '/body/model/edit/:id',
            name: 'UpdateBodyModel',
            component: AddUpdateBodyModel
        },
        {
            path: '/liftgate/model/list',
            name: 'ListLiftgateModel',
            component: ListLiftgateModel
        },
        {
            path: '/liftgate/model/add',
            name: 'AddUpdateLiftgateModel',
            component: AddUpdateLiftgateModel
        },
        {
            path: '/liftgate/model/edit/:id',
            name: 'UpdateLiftgateModel',
            component: AddUpdateLiftgateModel
        },
        {
            path: '/deal/status/list',
            name: 'ListDealStatus',
            component: ListDealStatus
        },
        {
            path: '/deal/status/add',
            name: 'AddUpdateDealStatus',
            component: AddUpdateDealStatus
        },
        {
            path: '/deal/status/edit/:id',
            name: 'UpdateDealStatus',
            component: AddUpdateDealStatus
        },
        {
            path: '/quotation',
            name: 'Quotation',
            component: Quotation
        },
        {
            path: '/document/view/:modelname/:id',
            name: 'ViewDocument',
            component: ViewDocument
        },
        {
            path: '/brake/make/list',
            name: 'ListBrakeMake',
            component: ListBrakeMake
        },
        {
            path: '/brake/make/add',
            name: 'AddUpdateBrakeMake',
            component: AddUpdateBrakeMake
        },
        {
            path: '/brake/make/edit/:id',
            name: 'UpdateBrakeMake',
            component: AddUpdateBrakeMake
        },
        {
            path: '/frontaxle/make/list',
            name: 'ListFrontAxleMake',
            component: ListFrontAxleMake
        },
        {
            path: '/frontaxle/make/add',
            name: 'AddUpdateFrontAxleMake',
            component: AddUpdateFrontAxleMake
        },
        {
            path: '/frontaxle/make/edit/:id',
            name: 'UpdateFrontAxleMake',
            component: AddUpdateFrontAxleMake
        },
        {
            path: '/fronttire/make/list',
            name: 'ListFrontTireMake',
            component: ListFrontTireMake
        },
        {
            path: '/fronttire/make/add',
            name: 'AddUpdateFrontTireMake',
            component: AddUpdateFrontTireMake
        },
        {
            path: '/fronttire/make/edit/:id',
            name: 'UpdateFrontTireMake',
            component: AddUpdateFrontTireMake
        },
        {
            path: '/fronttire/model/list',
            name: 'ListFrontTireModel',
            component: ListFrontTireModel
        },
        {
            path: '/fronttire/model/add',
            name: 'AddUpdateFrontTireModel',
            component: AddUpdateFrontTireModel
        },
        {
            path: '/fronttire/model/edit/:id',
            name: 'UpdateFrontTireModel',
            component: AddUpdateFrontTireModel
        },
        {
            path: '/frontwheels/model/list',
            name: 'ListFrontWheelsModel',
            component: ListFrontWheelsModel
        },
        {
            path: '/frontwheels/model/add',
            name: 'AddUpdateFrontWheelsModel',
            component: AddUpdateFrontWheelsModel
        },
        {
            path: '/frontwheels/model/edit/:id',
            name: 'UpdateFrontWheelsModel',
            component: AddUpdateFrontWheelsModel
        },
        {
            path: '/reartire/make/list',
            name: 'ListRearTireMake',
            component: ListRearTireMake
        },
        {
            path: '/reartire/make/add',
            name: 'AddUpdateRearTireMake',
            component: AddUpdateRearTireMake
        },
        {
            path: '/reartire/make/edit/:id',
            name: 'UpdateRearTireMake',
            component: AddUpdateRearTireMake
        },
        {
            path: '/reartire/model/list',
            name: 'ListRearTireModel',
            component: ListRearTireModel
        },
        {
            path: '/reartire/model/add',
            name: 'AddUpdateRearTireModel',
            component: AddUpdateRearTireModel
        },
        {
            path: '/reartire/model/edit/:id',
            name: 'UpdateRearTireModel',
            component: AddUpdateRearTireModel
        },
        {
            path: '/rearwheels/model/list',
            name: 'ListRearWheelsModel',
            component: ListRearWheelsModel
        },
        {
            path: '/rearwheels/model/add',
            name: 'AddUpdateRearWheelsModel',
            component: AddUpdateRearWheelsModel
        },
        {
            path: '/rearwheels/model/edit/:id',
            name: 'UpdateRearWheelsModel',
            component: AddUpdateRearWheelsModel
        },
        {
            path: '/sleeper/type/list',
            name: 'ListSleeperType',
            component: ListSleeperType
        },
        {
            path: '/sleeper/type/add',
            name: 'AddUpdateSleeperType',
            component: AddUpdateSleeperType
        },
        {
            path: '/sleeper/type/edit/:id',
            name: 'UpdateSleeperType',
            component: AddUpdateSleeperType
        },
        {
            path: '/suspension/model/list',
            name: 'ListSuspensionModel',
            component: ListSuspensionModel
        },
        {
            path: '/suspension/model/add',
            name: 'AddUpdateSuspensionModel',
            component: AddUpdateSuspensionModel
        },
        {
            path: '/suspension/model/edit/:id',
            name: 'UpdateSuspensionModel',
            component: AddUpdateSuspensionModel
        },
        {
            path: '/suspension/type/list',
            name: 'ListSuspensionType',
            component: ListSuspensionType
        },
        {
            path: '/suspension/type/add',
            name: 'AddUpdateSuspensionType',
            component: AddUpdateSuspensionType
        },
        {
            path: '/suspension/type/edit/:id',
            name: 'UpdateSuspensionType',
            component: AddUpdateSuspensionType
        },
        {
            path: '/inquirynotification/list',
            name: 'ListInquiryNotificationSettings',
            component: ListInquiryNotificationSettings
        },
        {
            path: '/inquirynotification/add',
            name: 'AddUpdateInquiryNotificationSettings',
            component: AddUpdateInquiryNotificationSettings
        },
        {
            path: '/inquirynotification/edit/:id',
            name: 'UpdateInquiryNotificationSettings',
            component: AddUpdateInquiryNotificationSettings
        },
        {
            path: '/areaonyard/list',
            name: 'ListAreaOnYard',
            component: ListAreaOnYard
        },
        {
            path: '/areaonyard/add/:id',
            name: 'AddUpdateAreaOnYard',
            component: AddUpdateAreaOnYard
        },
        {
            path: '/areaonyard/edit/:id/:aoyid',
            name: 'UpdateAreaOnYard',
            component: AddUpdateAreaOnYard
        },
        {
            path: '/vendor/list',
            name: 'ListVendor',
            component: ListVendor
        },
        {
            path: '/vendor/add',
            name: 'AddVendor',
            component: AddUpdateVendor,
            props : true
        },
        {
            path: '/vendor/edit/:id',
            name: 'UpdateVendor',
            component: AddUpdateVendor
        },
        {
            path: '/vendor/detail/:id',
            name: 'VendorTabView',
            component: VendorTabView,
        },
        {
            path: '/qrcode/history/list/:id',
            name: 'ListUserWiseQRCodeHistory',
            component: ListUserWiseQRCodeHistory
        },
        {
            path: '/front/wheels/list',
            name: 'ListFrontWheels',
            component: ListFrontWheels
        },
        {
            path: '/front/wheels/add',
            name: 'AddFrontWheels',
            component: AddUpdateFrontWheels,
        },
        {
            path: '/front/wheels/edit/:id',
            name: 'UpdateFrontWheels',
            component: AddUpdateFrontWheels
        },
        {
            path: '/rear/wheels/list',
            name: 'ListRearWheels',
            component: ListRearWheels
        },
        {
            path: '/rear/wheels/add',
            name: 'AddRearWheels',
            component: AddUpdateRearWheels,
        },
        {
            path: '/rear/wheels/edit/:id',
            name: 'UpdateRearWheels',
            component: AddUpdateRearWheels
        },
        {
            path: '/vendor/item/list',
            name: 'ListVendorItem',
            component: ListVendorItem
        },
        {
            path: '/vendor/item/add/:id',
            name: 'AddUpdateVendorItem',
            component: AddUpdateVendorItem
        },
        {
            path: '/vendor/item/edit/:id/:venid',
            name: 'UpdateVendorItem',
            component: AddUpdateVendorItem
        },
        {
            path: '/conclusion/list',
            name: 'ListConclusion',
            component: ListConclusion
        },
        {
            path: '/conclusion/add',
            name: 'AddConclusion',
            component: AddUpdateConclusion,
        },
        {
            path: '/conclusion/edit/:id',
            name: 'UpdateConclusion',
            component: AddUpdateConclusion
        },
        {
            path: '/price/book/list',
            name: 'ListPriceBook',
            component: ListPriceBook
        },
        {
            path: '/price/book/add',
            name: 'AddPriceBook',
            component: AddUpdatePriceBook,
        },
        {
            path: '/price/book/edit/:id',
            name: 'UpdatePriceBook',
            component: AddUpdatePriceBook
        },
        {
            path: '/deal/list',
            name: 'ListDeals',
            component: ListDeals
        },
        {
            path: '/deal/vehicle/list',
            name: 'ListDealsWithVehicles',
            component: ListDealsWithVehicles
        },
        {
            path: '/opportunity/tabview/:id',
            name: 'OpportunityTabView',
            component: OpportunityTabView,
            props : true
        },
        {
            path: '/opportunity/deal/edit/:id',
            name: 'UpdateOpportunityDeals',
            component: AddUpdateOpportunityDeals
        },
        {
            path: '/inventorytype/list',
            name: 'ListInventoryType',
            component: ListInventoryType
        },
        {
            path: '/inventorytype/add',
            name: 'AddInventoryType',
            component: AddUpdateInventoryType
        },
        {
            path: '/inventorytype/edit/:id',
            name: 'UpdateInventoryType',
            component: AddUpdateInventoryType
        },
        {
            path: '/website/list',
            name: 'ListWebsite',
            component: ListWebsite
        },
        {
            path: '/website/add',
            name: 'AddWebsite',
            component: AddUpdateWebsite
        },
        {
            path: '/website/edit/:id',
            name: 'UpdateWebsite',
            component: AddUpdateWebsite
        },
        {
            path: '/locationexpensetype/list',
            name: 'ListLocationExpenseType',
            component: ListLocationExpenseType
        },
        {
            path: '/locationexpensetype/add',
            name: 'AddLocationExpenseType',
            component: AddUpdateLocationExpenseType
        },
        {
            path: '/locationexpensetype/edit/:id',
            name: 'UpdateLocationExpenseType',
            component: AddUpdateLocationExpenseType
        },
        {
            path: '/csorequest/add',
            name: 'AddUpdateCSORequestSubmission',
            component: AddUpdateCSORequestSubmission
        },
        {
            path: '/csorequest/list',
            name: 'ListCSORequest',
            component: ListCSORequest
        },
        {
            path: '/csorequest/edit/:id/:level?',
            name: 'UpdateCSORequest',
            component: AddUpdateCSORequest
        },
        {
            path: '/financegroup/list',
            name: 'ListFinanceGroup',
            component: ListFinanceGroup
        },
        {
            path: '/financegroup/add',
            name: 'AddFinanceGroup',
            component: AddUpdateFinanceGroup
        },
        {
            path: '/financegroup/edit/:id',
            name: 'UpdateFinanceGroup',
            component: AddUpdateFinanceGroup
        },
        {
            path: '/documentgroup/list',
            name: 'ListDocumentGroup',
            component: ListDocumentGroup
        },
        {
            path: '/documentgroup/add',
            name: 'AddDocumentGroup',
            component: AddUpdateDocumentGroup
        },
        {
            path: '/documentgroup/edit/:id',
            name: 'UpdateDocumentGroup',
            component: AddUpdateDocumentGroup
        },
        {
            path: '/financecontract/list',
            name: 'ListFinanceContract',
            component: ListFinanceContract
        },
        {
            path: '/financecontract/edit/:id',
            name: 'UpdateFinanceContract',
            component: UpdateFinanceContract
        },
        {
            path: '/financeservice/list',
            name: 'ListFinanceService',
            component: ListFinanceService
        },
        {
            path: '/financeservice/add',
            name: 'AddFinanceService',
            component: AddUpdateFinanceService
        },
        {
            path: '/financeservice/edit/:id',
            name: 'UpdateFinanceService',
            component: AddUpdateFinanceService
        },
        {
            path: '/build-up',
            name: 'BuildUp',
            component: BuildUp
        },
        {
            path: '/conquest',
            name: 'Conquest',
            component: Conquest
        },
        {
            path: '/ppa/:encrypted_string',
            name: 'HandleAccess',
            component: HandleAccess
        },
        {
            path: '/fandi',
            name: 'Fandi',
            component: Fandi
        },
        {
            path: '/tax-rates/list',
            name: 'ViewTaxRates',
            component: ViewTaxRates
        },
        {
            path: '/tax-rate/add',
            name: 'AddTaxRate',
            component: AddUpdateTaxRate,
            props: true
        },
        {
            path: '/tax-rate/edit/:id',
            name: 'UpdateTaxRate',
            component: AddUpdateTaxRate,
        },
        {
            path: '/transaction-sheet',
            name: 'TransactionSheet',
            component: TransactionSheet,
        },
        {
            path: '/location/detail/:id',
            name: 'LocationTabView',
            component: LocationDataTab,
        },
        {
            path: '/gvw/fee/list',
            name: 'ListGvwFee',
            component: ListGvwFee
        },
        {
            path: '/gvw/fee/add',
            name: 'AddUpdateGvwFee',
            component: AddUpdateGvwFee
        },
        {
            path: '/gvw/fee/edit/:id',
            name: 'UpdateGvwFee',
            component: AddUpdateGvwFee
        },
        {
            path: '/new-transaction-sheet',
            name: 'NewTransactionSheet',
            component: NewTransactionSheet,
        },
        {
            path: '/new-vehicle-worksheet',
            name: 'NewVehicleWorkSheet',
            component: NewVehicleWorkSheet,
        },
        {
            path: '/notification/setup',
            name: 'NotificationManager',
            component: NotificationManager
        },
        {
            path: '/notification/user/add',
            name: 'AddUserNotification',
            component: AddUserNotification
        },
        {
            path: '/notification/user/update/:id',
            name: 'UpdateUserNotification',
            component: AddUserNotification
        },
        {
            path: '/teams/list',
            name: "ListTeams",
            component: ListTeams,
        },
        {
            path: '/opportunity/tab/:id',
            name: 'OpportunityTab',
            component: OpportunityTab
        },
        {
            path: '/deals/review',
            name: 'DealsReview',
            component: DealsReview
        },
        {
            path: '/calendar',
            name: 'Calendar',
            component: Calendar
        },
        {
            path: '/tag/list',
            name: 'ListTags',
            component: ListTag
        },
        {
            path: '/tag/add',
            name: 'AddTag',
            component: AddTag
        },
        {
            path: '/tag/update/:id',
            name: 'UpdateTag',
            component: AddTag
        },
        {
            path: '*',
            name: 'PageNotFound',
            component: PageNotFound
        },
        {
          path: '/report/information',
          name: 'ListInformationReport',
          component: ListInformationReport
        },
        {
            path: '/report/sold-unit-by-branch',
            name: 'SoldUnitByBranchReport',
            component: SoldUnitByBranchReport
        },
        {
            path: '/report/flooring',
            name: 'FlooringReport',
            component: FlooringReport
        },
        {
            path: '/report/customer-info',
            name: 'CustomerInfoReport',
            component: CustomerInfoReport
        },
        {
            path: '/report/branch-location',
            name: 'BranchLocationReport',
            component: BranchLocationReport
        },
        {
            path: '/report/finance-reserve',
            name: 'FinanceReserveReport',
            component: FinanceReserveReport
        },
        {
            path: '/report/location-inventory',
            name: 'LocationInventoryReport',
            component: LocationInventoryReport
        },
        {
            path: '/report/all-inventory-for-accounting',
            name: 'AllInventoryForAccountingReport',
            component: AllInventoryForAccountingReport
        },
        {
            path: '/report/vehicle-warranty-report',
            name: 'VehicleWarrantyReport',
            component: VehicleWarrantyReport
        },
        {
            path: '/report/gp-report',
            name: 'GPReport',
            component: GPReport
        },
        {
            path: '/report/brand-value',
            name: 'BrandValueReport',
            component: BrandValueReport
        },
        {
            path: '/report/deal-level',
            name: 'DealLevelReport',
            component: DealLevelReport
        },
        {
            path: '/batch/list',
            name: 'ListBatch',
            component: ListBatch
        },
        {
            path: '/upload-guide-document',
            name: 'UploadGuideDocument',
            component: UploadGuideDocument
        },
    ]
})
