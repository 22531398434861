<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_inventory_master') || permissionNameArray.includes('can_view_inventory_master')">
            <div class="container-fluid xyz">
                <template v-if="isAddFrom">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>

                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                </template>
                <template v-else>
                    <!--<div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                            <span>{{pageTitle}}</span>
                        </div>
                    </div>-->
                </template>
            </div>

            <!--page content-->
            <div class="admin-page-content">
                <!--Forms-->

                <div :class="alertClass" id="success-alert">
                    <strong> {{alertMessage}} </strong>
                </div>

                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="row no-margin">
                        <!--Is Active -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.show_truck_online_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <label class="container">
                                        <input id="yes" v-model="showTruckOnline" value="true" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span v-if="showTruckOnline == true" class="form-control">Yes</span>
                                    <span v-else class="form-control">No</span>
                                </template>
                            </div>
                        </div>
                        <!-- Procede Branch -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.procede_branch}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <span class="form-control">{{procedeBranch}}</span>
                            </div>
                        </div>
                        <!-- Procede Branch -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.dmv_notes_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <span class="form-control">{{DMVNotes}}</span>
                            </div>
                        </div>

                        <!-- Company -->
                        <div class="col-6 forms-block" ref="validCompany">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.company_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select v-model="companyId" name="state"
                                            label="text"
                                            :filterable="false"
                                            :options="companyArr"
                                            @search="onSearchCompany"
                                            :clearable="true"
                                            @input="companyError = '', handleCompanyChange()"
                                            :placeholder="staticMessages.company_custom_place_holder"
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{staticMessages.company_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{companyId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Quantity-->
                        <!-- <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.quantity_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="quantityError = ''" id="quantity" v-model="quantity" class="form-control" type="text"/>
                                    <span class="help is-danger">{{quantityError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{quantity}}</span>
                                </template>

                            </div>
                        </div> -->

                        <!--Location-->
                        <div class="col-6 forms-block" ref="validLocation">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="location"
                                            @change="changedValueLocation"
                                            @input="locationError = '', handleLocationChange()"
                                            name="location"
                                            id="location"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="companyId ? locationArr : []"
                                            :placeholder="staticMessages.location_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{staticMessages.select_company__first}}
                                            </template>
                                        </span>    
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{location.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Area On Yard -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.area_on_yard_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                        v-model="areaOnYardId"
                                        @change="changedValue"
                                        @input="areaOnYardError = ''"
                                        name="areaOnYardId"
                                        id="areaOnYardId"
                                        label="text"
                                        :clearable="true"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="areaOnYardArr"
                                        :placeholder="staticMessages.area_on_yard_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAreaOnYardRecordNotFound">
                                                {{staticMessages.area_on_yard_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{areaOnYardError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{areaOnYardId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Lot Location-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.lot_location_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="lotLocationError = ''" id="lotLocation" v-model="lotLocation" class="form-control" type="text"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{lotLocationError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{lotLocation}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Status -->
                        <div class="col-6 forms-block" ref="validStatus">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select v-model="statusId"
                                            @change="changedValue"
                                            @input="statusError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="statusArr"
                                            :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{statusError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{statusId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Storage Location -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.storage_location_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select title="Select Storage Location"
                                          v-model="storageLocId"
                                          @change="changedValue"
                                          @input="storageLocError = ''"
                                          name="storageLocId"
                                          id="storageLocId"
                                          label="text"
                                          :clearable="true"
                                          :searchable="false"
                                          :filterable="false"
                                          :options="storageLocArr"
                                          :placeholder="staticMessages.storage_location_place_holder">
                                </v-select>
                                <!-- <div class="requiredstar">*</div>
                                <span class="help is-danger">{{storageLocError}}</span> -->
                            </div>
                        </div>

                        <!-- Acquire From -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.acquire_from_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select v-model="acquireFromId"
                                            @change="changedValue"
                                            @input="acquireFromError = ''"
                                            @search="onSearchAcquireFrom"
                                            @clear="onClearAcquireFrom"
                                            name="customPerPage"
                                            id="customPerPag"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="acquireFromArr"
                                            :placeholder="staticMessages.acquire_from_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAcquireFromRecordNotFound">
                                                {{staticMessages.acquire_from_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{acquireFromError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{acquireFromId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Acquired By -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.acquire_by_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select v-model="acquireById"
                                            @change="changedValue"
                                            @input="acquireByError = ''"
                                            @search="onSearchAcquireBy"
                                            @clear="onClearAcquireBy"
                                            name="customPerPage"
                                            id="customPerPag"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="acquireByArr"
                                            :placeholder="staticMessages.acquire_by_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAcquireByRecordNotFound">
                                                {{staticMessages.acquire_by_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{acquireByError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{acquireById.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Managed By -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.managed_by_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="managedById"
                                            @change="changedValue"
                                            @input="managedByError = ''"
                                            @search="onSearchManagedBy"
                                            @clear="onClearManagedBy"
                                            name="customPerPage"
                                            id="customPerPag"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="managedByArr"
                                            :placeholder="staticMessages.managed_by_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isManagedByRecordNotFound">
                                                {{staticMessages.managed_by_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{managedByError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{managedById.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Visibility Type -->
                        <div class="col-6 forms-block" ref="validVisibilityType">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.visibility_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="visibilityTypeId"
                                            @change="changedValue"
                                            @input="visibilityTypeError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="visibilityTypeArr"
                                            :placeholder="staticMessages.visibility_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{visibilityTypeError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{visibilityTypeId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Sale Type -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.sale_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="saleTypeId"
                                            @change="changedValue"
                                            @input="saleTypeError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="saleTypeArr"
                                            :placeholder="staticMessages.sale_type_place_holder">
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{saleTypeError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{saleTypeId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Date Received -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.date_received}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="dateReceived"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.date_received_place_holder"
                                            name="dateReceived"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{dateReceived}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Recon finished -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.recon_finished}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="reconFinished"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.recon_finished_place_holder"
                                            name="reconFinished"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{reconFinished}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Is Featured -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.is_featured_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="isFeaturedId"
                                            @change="changedValue"
                                            @input="isFeaturedError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="isFeaturedArr"
                                            :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{isFeaturedId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Order No.-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.order_no_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="orderNoError = ''" id="orderNo" v-model="orderNo" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{orderNo}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Order Date -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.order_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="orderDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.order_date_place_holder"
                                            name="issueDate"
                                            @input="chageOrderDate()"
                                    >
                                    </date-picker>
                                    <!--<div class="requiredstar">*</div>
                                    <span class="help is-danger">{{orderDateError}}</span>-->
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{orderDate}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Ordered For -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.ordered_for_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input id="orderedFor" v-model="orderedFor" class="form-control" type="text"/>
                            </div>
                        </div>

                        <!--Price Paid-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.price_paid_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="isAddFrom">
                                    <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <input @input="pricePaidError = ''" id="pricePaid" v-model="pricePaid" class="form-control" type="text"/>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{pricePaid}}</span>
                                    </template>
                                    <span class="help is-danger">{{pricePaidError}}</span>
                                </template>
                                <template v-else>
                                    <span class="form-control">{{pricePaid}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Retail Price-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.retail_price_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="retailPriceError = ''" id="retailPrice" v-model="retailPrice" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{retailPrice}}</span>
                                </template>
                                <span class="help is-danger">{{retailPriceError}}</span>
                            </div>
                        </div>

                        <!-- Purchase Date -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.purchase_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="purchaseDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.purchase_date_place_holder"
                                            name="issueDate"
                                            @input="changePurchaseDate()"
                                    >
                                    </date-picker>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{purchaseDateError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{purchaseDate}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Consignment-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.consignment_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="consignmentError = ''" id="consignment" v-model="consignment" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{consignment}}</span>
                                </template>
                                <span class="help is-danger">{{consignmentError}}</span>
                            </div>
                        </div>

                        <!-- Acquire on Date -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.acquire_on_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="acquireOnDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.acquire_on_date_place_holder"
                                            name="issueDate"
                                            @input="changeAcuireDate()"
                                    >
                                    </date-picker>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{acquireOnDateError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{acquireOnDate}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Is Bailment -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.is_bailment_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <v-select
                                         id="isBailment"
                                         name="isBailment"
                                         v-model="isBailment"
                                         label="text"
                                         :clearable="false"
                                         :searchable="false"
                                         :filterable="false"
                                         :options="isBailmentArr"
                                         :placeholder="staticMessages.select_yes_no">
                                        </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{isBailment.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--CONSIGNMENT PRICE-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.consignment_price_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="ConsignmentPriceError = ''" id="ConsignmentPrice" v-model="ConsignmentPrice" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{ConsignmentPrice}}</span>
                                </template>
                                <span class="help is-danger">{{ConsignmentPriceError}}</span>
                            </div>
                        </div>

                        <!--Description-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <textarea @input="descriptionError = ''" id="descrition" v-model="descrition" class="form-control"></textarea>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{descrition}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Website-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.website_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="websiteError = ''" id="website" v-model="website" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{website}}</span>
                                </template>
                                <span class="help is-danger">{{websiteError}}</span>
                            </div>
                        </div>

                        <!--Title In -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.title_in_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="titleInId"
                                            @change="changeTitleIn"
                                            @input="titleInError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="titleInArr"
                                            :placeholder="staticMessages.is_title_in_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{titleInId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Private Comment-->
                        <template v-if="permissionNameArray.includes('can_add_private_comment_inventory_master')">
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.private_comment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <textarea @input="privateCommentError = ''" id="privateComment" v-model="privateComment" class="form-control"></textarea>
                                    </template>
                                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                        <span class="form-control">{{privateComment}}</span>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.private_comment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{privateComment}}</span>
                                </div>
                            </div>-->
                        </template>


                        <!-- Title Out Date -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.title_out_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="titleOutDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.title_out_date_place_holder"
                                            name="issueDate"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{titleOutDate}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Available Date -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.available_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="availableDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.available_date_place_holder"
                                            name="issueDate"
                                            @input="changeAvailableDate()"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{availableDate}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Featured Text-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.featured_text_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <textarea @input="featuredTextError = ''" id="featuredText" v-model="featuredText" class="form-control"></textarea>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{featuredText}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Year-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.year_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text"/>
                                    <span class="help is-danger">{{yearError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{year}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Special Expiration Date -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.special_expiration_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="specialExpirationDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.special_expiration_date_place_holder"
                                            name="issueDate"
                                            @input="changeSpecialExpirationDate()"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{specialExpirationDate}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Make-->
                        <div class="col-6 forms-block" ref="validMake">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="makeId"
                                            @change="changedMakeValue"
                                            @input="makeError = ''"
                                            name="make"
                                            id="make"
                                            label="text"
                                            @search="onSearchMake"
                                            :filterable="false"
                                            :clearable="true"
                                            :searchable="true"
                                            :options="makeArr"
                                            :placeholder="staticMessages.make_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isMakeRecordNotFound">
                                                {{staticMessages.make_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{makeId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--YouTube Video URL-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.youtube_video_url_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="yupuTubeVideoUrlError = ''" id="yupuTubeVideoUrl" v-model="yupuTubeVideoUrl" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{yupuTubeVideoUrl}}</span>
                                </template>
                                <span class="help is-danger">{{yupuTubeVideoUrlError}}</span>
                            </div>
                        </div>

                        <!--Model-->
                        <div class="col-6 forms-block" ref="validModel">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="modelId"
                                            @change="changedValue"
                                            @input="modelError = ''"
                                            name="modelId"
                                            id="modelId"
                                            label="text"
                                            :clearable="true"
                                            @search="onSearchModel"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="modelArr"
                                            :placeholder="staticMessages.model_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isModelRecordNotFound">
                                                {{staticMessages.model_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{modelId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Warranty Type -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.warranty_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="warrantyTypeId"
                                            @change="changedValue"
                                            @input="warrantyTypeError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="warrantyTypeArr"
                                            :placeholder="staticMessages.warranty_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{warrantyTypeId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--VIN No.-->
                        <div class="col-6 forms-block" ref="validVin">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="vinNoError = ''" id="vinNo" v-model="vinNo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vinNoError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{vinNo}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Tag-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.tag_lable}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                    <v-select
                                    v-model="tagId"
                                    multiple
                                    label="text"
                                    name="tagId"
                                    id="tagId"
                                    :clearable="true"
                                    :searchable="true"
                                    :filterable="true"
                                    :options=tagArr
                                    :placeholder="staticMessages.select_tags">
                                    </v-select>
                            </div> 
                        </div>

                        <!--Unit No.-->
                        <div class="col-6 forms-block" ref="validStock">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.unit_no_lable}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="stockNoError = ''" id="stockNo" v-model="stockNo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{stockNoError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{stockNo}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Classification-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.classification_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="classificationError = ''" id="classification" v-model="classification" class="form-control" type="text"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{classificationError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{classification}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Stock No.-->
                        <div class="col-6 forms-block" ref="validStockNum">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.stock_no_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input id="stockNum" v-model="stockNum" class="form-control" type="text"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{stockNum}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Condition-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.condition_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select v-model="conditionId"
                                            @change="changedValue"
                                            @input="conditionError = ''"
                                            name="leadQuality"
                                            id="leadQuality"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="conditionArr"
                                            :placeholder="staticMessages.condition_place_holder">
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{conditionError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{conditionId.text}}</span>
                                </template>
                            </div>
                        </div>
                    <template v-if="closeFormType == 1">
                        <!--Is Stock-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.inv_order_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="InvOrderType"
                                            name="InvOrderType"
                                            id="InvOrderType"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="InvOrderTypeArr">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{InvOrderType.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Apu -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.apu_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="apuId"
                                            @change="changedValue"
                                            @input="apuError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="apuArr"
                                            :placeholder="staticMessages.apu_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{apuId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Body Color -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.body_color_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="bodyColorId"
                                            @change="changedValue"
                                            @input="bodyColorError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="bodyColorArr"
                                            :placeholder="staticMessages.body_color_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{bodyColorId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Body Notes -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.body_notes_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="bodyNotesId"
                                            @change="changedValue"
                                            @input="bodyNotesError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="bodyNotesArr"
                                            :placeholder="staticMessages.body_notes_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{bodyNotesId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Liftgate Capacity -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.liftgate_capacity_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="liftgateCapacityId"
                                            @change="changedValue"
                                            @input="liftgateCapacityError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="liftgateCapacityArr"
                                            :placeholder="staticMessages.liftgate_capacity_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{liftgateCapacityId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--PTO -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.pto_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="ptoId"
                                            @change="changedValue"
                                            @input="ptoError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="ptoArr"
                                            :placeholder="staticMessages.pto_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{ptoId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Body Length -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.body_length_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="bodyLengthId"
                                            @change="changedValue"
                                            @input="bodyLengthError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="bodyLengthArr"
                                            :placeholder="staticMessages.body_length_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{bodyLengthId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Liftgate-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.liftgate_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="liftgateId"
                                            @change="changedValue"
                                            @input="liftgateError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="liftgateArr"
                                            :placeholder="staticMessages.liftgate_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{liftgateId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Body Make-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.body_make_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="bodyMakeId"
                                            @change="changedBodyMakeValue"
                                            @input="bodyMakeError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="bodyMakeArr"
                                            :placeholder="staticMessages.body_make_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{bodyMakeId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Apu Model-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.apu_model_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="apuModelId"
                                            @change="changedValue"
                                            @input="apuModelError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="apuModelArr"
                                            :placeholder="staticMessages.apu_model_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{apuModelId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Body Model-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.body_model_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="bodyModelId"
                                            @change="changedValue"
                                            @input="bodyModelError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="bodyModelArr"
                                            :placeholder="staticMessages.body_model_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{bodyModelId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Liftgate Model-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.liftgate_model_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="liftgateModelId"
                                            @change="changedValue"
                                            @input="liftgateModelError = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="liftgateModelArr"
                                            :placeholder="staticMessages.liftgate_model_place_holder">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{liftgateModelId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--DTNA Customer Number-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.custno_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input id="custNo" v-model="custNo" class="form-control" type="text" maxlength="14"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{custNo}}</span>
                                </template>
                            </div>
                        </div>

                        <!--DTNA Concession No-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.concession_no_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input id="ConcessionNo" v-model="ConcessionNo" class="form-control" type="text" maxlength="49"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{ConcessionNo}}</span>
                                </template>
                            </div>
                        </div>

                        <!--DTNA PTOProvIndc-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.pto_prov_indc_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="PTOProvIndc"
                                            name="PTOProvIndc"
                                            id="PTOProvIndc"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="PTOProvIndcArr">
                                    </v-select>
                                    <!-- <input id="PTOProvIndc" v-model="PTOProvIndc" class="form-control" type="text"/> -->
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{PTOProvIndc.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!--DTNA OrderRecvDate-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.order_recv_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="OrderRecvDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.order_recv_date_place_holder"
                                            name="issueDate"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{OrderRecvDate}}</span>
                                </template>
                            </div>
                        </div>
                        <!--DTNA OrderReleaseDate-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.order_release_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="OrderReleaseDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.order_release_date_place_holder"
                                            name="issueDate"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{OrderReleaseDate}}</span>
                                </template>
                            </div>
                        </div>
                        <!--DTNA SchedBuildDate-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.sched_build_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="SchedBuildDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.sched_build_date_place_holder"
                                            name="issueDate"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{SchedBuildDate}}</span>
                                </template>
                            </div>
                        </div>
                        <!--DTNA RqstDelvDate-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.request_delivery_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="RqstDelvDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.request_delivery_date_place_holder"
                                            name="issueDate"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{RqstDelvDate}}</span>
                                </template>
                            </div>
                        </div>
                        <!--DTNA ProjDelvDate-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label color-0771b4">{{staticInputLabelMessages.project_delivery_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <date-picker
                                            v-model="ProjDelvDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.project_delivery_date_place_holder"
                                            name="issueDate"
                                    >
                                    </date-picker>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{ProjDelvDate}}</span>
                                </template>
                            </div>
                        </div>
                    </template>

                        <!--Asking Retail USD-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.asking_retail_usd_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="askingRetailUsdError = ''" id="askingRetailUsd" v-model="askingRetailUsd" class="form-control" type="text"/>
                                    <span class="help is-danger">{{askingRetailUsdError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{askingRetailUsd}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Min Retail USD-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.min_retail_usd_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="minRetailUsdError = ''" id="minRetailUsd" v-model="minRetailUsd" class="form-control" type="text"/>
                                    <span class="help is-danger">{{minRetailUsdError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{minRetailUsd}}</span>
                                </template>
                            </div>
                        </div>

                        <!--High Retail USD-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.high_retail_usd_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="highRetailUsdError = ''" id="highRetailUsd" v-model="highRetailUsd" class="form-control" type="text"/>
                                    <span class="help is-danger">{{highRetailUsdError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{highRetailUsd}}</span>
                                </template>
                            </div>
                        </div>

                        <!--Show Price Online -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.show_price_online_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <label class="container">
                                        <input id="yes" v-model="showPriceOnlineId" value="true" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span v-if="showPriceOnlineId == true" class="form-control">Yes</span>
                                    <span v-else class="form-control">No</span>
                                </template>
                            </div>
                        </div>

                        <!--Whole Shale USD-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.whole_sale_usd_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="wholeShaleUsdError = ''" id="wholeShaleUsd" v-model="wholeShaleUsd" class="form-control" type="text"/>
                                    <span class="help is-danger">{{wholeShaleUsdError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{wholeShaleUsd}}</span>
                                </template>
                            </div>
                        </div>
                        <!--Inventory Type-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.inventory_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <!-- <input @input="inventoryTypeError = ''" id="inventoryType" v-model="inventoryType" class="form-control" type="text"/> -->
                                    <v-select v-model="inventoryType"
                                            @change="changedValue"
                                            @input="inventoryTypeError = ''"
                                            name="inventoryType"
                                            id="inventoryType"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="inventoryArr"
                                            :placeholder="staticMessages.inventory_type_place_holder">
                                    </v-select>
                                    <span class="help is-danger">{{inventoryTypeError}}</span>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <!-- <span class="form-control">{{inventoryType}}</span> -->
                                    <span class="form-control">{{inventoryType.text}}</span>
                                </template>
                            </div>
                        </div>

                        <!-- Is Truck -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="isTruckType"
                                            name="isTruckType"
                                            id="isTruckType"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="isTruckTypeArr">
                                    </v-select>
                                    <span class="help is-danger">{{isTruckError}}</span>
                                    <!-- <input id="PTOProvIndc" v-model="PTOProvIndc" class="form-control" type="text"/> -->
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{isTruckType.text}}</span>
                                </template>
                            </div>
                            <!-- <div class="col-9 paddingleft0 custom-checkbox-v">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <label class="container">
                                        <input @input="isTruckError=''" id="yes" v-model="is_truck" value="true" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <div class="row clearfix">&nbsp;</div>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{inv_type}}</span>
                                </template>
                            </div> -->
                        </div>

                        <!--360 Exterior Video URL-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.exterior_360_video_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <input @input="exterior360VideoUrlError = ''" id="exterior360VideoUrl" v-model="exterior360VideoUrl" class="form-control" type="text" maxlength="10000"/>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{exterior360VideoUrl}}</span>
                                </template>
                                <span class="help is-danger">{{exterior360VideoUrlError}}</span>
                            </div>
                        </div>

                         <!-- Retail Ready -->
                         <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.retail_ready_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                                    <v-select
                                            v-model="retailReadyId"
                                            name="retailReady"
                                            id="retailReadyId"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="retailReadyArr"
                                            :placeholder="staticMessages.select_yes_no">
                                    </v-select>
                                </template>
                                <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                                    <span class="form-control">{{retailReadyId.text}}</span>
                                </template>
                            </div>
                        </div>

                        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                        <label for="dropzone">{{staticInputLabelMessages.upload_image_label}}</label>
                                        <vue-dropzone
                                                ref="dropzone"
                                                id="dropzone"
                                                :options="dropzoneOptions"
                                                @vdropzone-success = "uploadDocument"
                                                @vdropzone-removed-file="removedSelectedFile"
                                                @vdropzone-error="fileError"
                                                @vdropzone-file-added="fileAdd"
                                                @vdropzone-total-upload-progress="progressOfUloadBanner"
                                                :useCustomSlot=true
                                        >
                                            <div class="dropzone-custom-content">
                                                <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                                <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                            </div>
                                        </vue-dropzone>

                                </div>
                            </div>
                        </template>
                        <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                            <template v-if="imagesPaths.length">
                                <div class="cards-container">
                                    <template v-for="(text, index) in loadDocArr">
                                        <div class="col-4 text-alignment-center mb-5">
                                            <div class="cards-block">
                                                <div class="card">
                                                    <img class="card-img-top" :src="text.thumb_url" :key="index" >
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </div>

                    <!--Error Alert-->
                    <!--<div class="row clearfix" >
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="alert alert-danger" id="alertError">
                                &lt;!&ndash;<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>&ndash;&gt;
                                <strong id="errorMsg" class="error"></strong>
                            </div>
                        </div>
                    </div>-->
                    <div class="clearfix"></div>

                    <!--Buttons-->
                    <div class="row clearfix"></div>
                    <template v-if="permissionNameArray.includes('can_add_inventory_master')">
                        <div class="button-demo">
                            <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                            <input id="addAndContinueAssetBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addAsset(flag='Save and Continue')">
                            <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </template>
                    <template v-else-if="permissionNameArray.includes('can_view_inventory_master')">
                    </template>
                </div>


                <!-- Forms -->
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer v-show="isAddFrom"></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                inv_type : 'Truck',
                // is_truck: false,
                isTruckType: {text: "Trailer", value: "trailer"},
                isTruckTypeArr: [{text: "Bus", value: "bus"}, {text: "Trailer", value: "trailer"}, {text: "Truck", value: "truck"}],
                isTruckOrgVal : false,
                isTruckError : "",
                staticInputLabelMessages: "",
                staticMessages: "",
                contentClass: "admin-page-content",
                cityArr: [],
                cityError: "",
                state: "",
                stateArr: [],
                stateError: "",
                companyArr: [],
                isCompanyRecordNotFound: false,
                companyId: "",
                procedeBranch : "",
                DMVNotes : "",
                companyError: "",
                quantity: "",
                quantityError: "",
                lotLocation: "",
                editLotLocation: "",
                lotLocationError: "",
                statusArr: [],
                statusId: "",
                statusError: "",
                acquireOnDate: "",
                orderDateError: "",
                acquireOnDateError: "",
                visibilityTypeId: "",
                visibilityTypeArr: [{text: "Private", value: "private"}, {text: "Public", value: "public"}],
                visibilityTypeError: "",
                saleTypeId: "",
                saleTypeArr: [],
                saleTypeError: "",
                dateReceived: "",
                reconFinished: "",
                isFeaturedId: "",
                isFeaturedArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                isFeaturedError: "",
                orderNo: "",
                orderNoError: "",
                orderDate: "",
                orderDateError: "",
                orderedFor: "",
                pricePaid: "",
                pricePaidError: "",
                ConsignmentPrice: "",
                ConsignmentPriceError: "",
                location: "",
                locationArr: [],
                locationError: "",
                areaOnYardId: "",
                areaOnYardArr: [],
                areaOnYardError: "",
                descrition: "",
                descriptionError: "",
                website: "",
                websiteError: "",
                titleInId: "",
                titleInArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                titleInError: "",
                privateComment: "",
                privateCommentError: "",
                titleOutDate: "",
                availableDate: "",
                availableDateError: "",
                purchaseDate : "",
                purchaseDateError : "",
                featuredText: "",
                featuredTextError: "",
                specialFeature: "",
                specialFeatureError: "",
                specialExpirationDate: "",
                specialExpirationDateError: "",
                year: "",
                yearError: "",
                makeId: "",
                makeArr: [],
                makeError: "",
                modelId: "",
                modelArr: [],
                modelError: "",
                yupuTubeVideoUrl: "",
                yupuTubeVideoUrlError: "",
                warrantyTypeId: "",
                warrantyTypeArr: [],
                warrantyTypeError: "",
                vinNo: "",
                vinNoError: "",
                stockNum: "",
                stockNo: "",
                stockNoError: "",
                classification: "",
                classificationError: "",
                conditionId: "",
                conditionArr: [{text: "New", value: 1}, {text: "Used", value: 2}],
                conditionError: '',
                apuId: "",
                apuArr: [],
                apuError: "",
                bodyColorId: "",
                bodyColorArr: [],
                bodyColorError: "",
                bodyNotesId: "",
                bodyNotesArr: [],
                bodyNotesError: "",
                liftgateCapacityId: "",
                liftgateCapacityArr: [],
                liftgateCapacityError: "",
                ptoId: "",
                ptoArr: [],
                ptoError: "",
                bodyLengthId: "",
                bodyLengthArr: [],
                bodyLengthError: "",
                liftgateId: "",
                liftgateArr: [],
                liftgateError: "",
                bodyMakeId: "",
                bodyMakeArr: [],
                bodyMakeError: "",
                apuModelId: "",
                apuModelArr: [],
                apuModelError: "",
                bodyModelId: "",
                bodyModelArr: [],
                allBodyModelArr: [],
                bodyModelError: "",
                liftgateModelId: "",
                liftgateModelArr:[],
                liftgateModelError: "",
                custNo: "",
                ConcessionNo: "",
                PTOProvIndc: {text: "Select", value: ""},
                PTOProvIndcArr : [{text: "Select", value: ""},{text: "Yes", value: "y"}, {text: "No", value: "n"}],
                InvOrderType : {text: "Stock", value: "stock"},
                InvOrderTypeArr : [{text: "Customer", value: "customer"}, {text: "Stock", value: "stock"}],
                OrderRecvDate: "",
                OrderReleaseDate: "",
                SchedBuildDate : "",
                RqstDelvDate : "",
                ProjDelvDate : "",
                askingRetailUsd: "",
                askingRetailUsdError: "",
                minRetailUsd: "",
                minRetailUsdError: "",
                highRetailUsd: "",
                highRetailUsdError: "",
                showTruckOnline: true,
                showPriceOnlineId: true,
                showPriceOnlineError: "",
                inventoryType:"",
                inventoryArr: [],
                inventoryTypeError:"",
                wholeShaleUsd: "",
                wholeShaleUsdError: "",
                acquireFromError : "",
                acquireFromId : "",
                acquireFromArr : [],
                acquireById : "",
                acquireByArr : [],
                acquireByError : "",
                managedById : "",
                managedByArr : [],
                managedByError : "",

                retailPrice : "",
                retailPriceError : "",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                activetab: 1,

                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png",
                    maxFiles: 50,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                //Files
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                loadDocArr: [],
                isAddFrom: true,
                permissionNameArray: ['can_add_inventory_master','can_view_inventory_master'],

                isAcquireFromRecordNotFound: false,
                isAcquireByRecordNotFound: false,
                isManagedByRecordNotFound: false,
                isMakeRecordNotFound: false,
                isModelRecordNotFound: false,
                isAreaOnYardRecordNotFound: false,
                isConditionRecordNotFound: false,
                isUpdateFormLocFlag : false,
                closeFormType : 0,
                type: '',
                consignment : '',
                consignmentError : '',
                exterior360VideoUrl : '',
                exterior360VideoUrlError : '',
                storageLocId : '',
                storageLocArr : [],
                storageLocError : '',
                isBailment: [{text: "No", value: "0"}],
                isBailmentArr: [{text: "Yes", value: "1"}, {text: "No", value: "0"}],
                retailReadyId: [{ text : "No", value : "no"}],
                retailReadyArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}, {text: "Yes/Needs Detail", value: "yes-need-details"}],
                elementPosition: 0,
                tagId: [],
                tagArr: [],
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            permissionNameCoreTabArray:"",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        watch: {

            companyId: function (companyId) {
                
                let companyIdValue = companyId ? companyId.value : '';
                self.loadAllData(['Location'], true, self.loadLocationDataCallback, 'undefined', '', companyIdValue);
                
                setTimeout(function() {
                    let locationAvailable = false;
                    if (self.locationArr && self.location) {
                        $.each(self.locationArr, function (j) {
                            if (self.locationArr[j].value == self.location.value) {
                                locationAvailable = true;
                            }
                        });
                   }
                    if(self.isUpdateFormLocFlag){
                        self.isUpdateFormLocFlag = false;
                    } else {
                        if(!locationAvailable){
                            self.location = '';
                        }
                    }
                }, 500);
            },
            areaOnYardId() {
                if (self.location == null || !self.location.value) {
                    setTimeout(function() {
                        self.areaOnYardId = '';
                    }, 0.1);
                    self.showSAlert('Please Select Location First!', 'info', false);
                }
            },
            conditionId: function () {
                self.closeFormType = 0;
                if (self.conditionId == null || !self.conditionId.value) {
                    self.closeFormType = 0;
                } else {
                    self.closeFormType = self.conditionId.value;
                }
            },
            isTruckType: function () {
                self.isTruckError = '';
                if (self.isTruckType == null || !self.isTruckType.value) {

                } else {
                    // console.log(self.isTruckOrgVal + " - " + self.isTruckType.value);
                    if(self.isTruckOrgVal != self.isTruckType.value) {
                        self.isTruckError = "Please save the updates to get inventory type related inputs in other tab forms.";
                    } else {
                        self.isTruckError = '';
                    }
                }
            },
            /* is_truck: function(is_truck) {
                self.isTruckError = '';
                if(self.isTruckOrgVal != self.is_truck) {
                    self.isTruckError = "Please save the updates to get inventory type related inputs in other tab forms.";
                } else {
                    self.isTruckError = '';
                }
            }, */
        },
        mounted: function () {
            
            /* Redirect to login page logic start */
            let userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            
            if (self.asset && self.asset.content) {
                self.type = (self.asset.content && self.asset.content.asset_data && self.asset.content.asset_data.vehicle_type && self.asset.content.asset_data.vehicle_type != null) ? self.asset.content.asset_data.vehicle_type : 'used';
            } else {
                self.type = 'used'
            }
            self.loadAllData([
                                'Make',
                                //'ContactFilterDropDown', 
                                'Condition'
                            ], false, self.loadTypeMasterDataCallback, "undefined", '', '', '', '', '', '', '', self.type.toLowerCase());
            
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {

                self.isAddFrom = false;
                self.isUpdateFormLocFlag = true;
                self.contentClass = "spaced-container";
                self.getAssetDetailsById(self.asset);
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
                self.permissionNameArray = self.permissionNameCoreTabArray;

            } else {

                self.getLoginIdPermissions();
                self.loadAllData(['Location'], true, self.loadLocationDataCallback, "undefined", '');
                self.quantity = 1;
                self.statusId = {text: "Active", value: 1};
                self.conditionId = {text: "New", value: 1};

            }

            EventBus.$on("assetDataIsUpdated", function (data) {
                //empty out all selected tags
                self.tagId = [];
            
                self.removeAllFiles();
                self.getAssetDetailsById(data);
            });
            self.loadAllData(
                ["Tag"],
                false,
                self.loadTagsDataCallback,
                "undefined",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "AssetMaster"
            );
        },
        methods: {
            handleCompanyChange() {
                if (!self.companyId) {
                    // Clear the selected location when company is cleared
                    self.location = "";
                }
            },
            handleLocationChange() {
                if (!self.location) {
                    // Clear the selected lot location when location is cleared
                    self.lotLocation = "";
                }
            },
            onClearAcquireFrom : function() {
                self.acquireFromError = "";
            },
            onClearAcquireBy : function() {
                self.acquireByError= "";
            },
            onClearManagedBy : function() {
                self.manageByError = "";
            },
            returnData: function () {
                let orderDate = "";
                let acquireOnDate = "";
                let titleOutDate = "";
                let availableDate = "";
                let dateReceived = "";
                let reconFinished = "";
                let purchaseDate = "";
                let specialExpirationDate = "";
                let OrderRecvDate = "";
                let OrderReleaseDate = "";
                let SchedBuildDate = "";
                let RqstDelvDate = "";
                let ProjDelvDate = "";
                if (self.purchaseDate != null && typeof self.purchaseDate != "undefined" && self.purchaseDate != '') {
                    purchaseDate = self.apiRequestDateFormat(self.purchaseDate);
                }
                if (self.orderDate != null && typeof self.orderDate != "undefined" && self.orderDate != '') {
                    orderDate = self.apiRequestDateFormat(self.orderDate);
                }
                if (self.acquireOnDate != null && typeof self.acquireOnDate != "undefined" && self.acquireOnDate != '') {
                    acquireOnDate = self.apiRequestDateFormat(self.acquireOnDate);
                }
                if (self.titleOutDate != null && typeof self.titleOutDate != "undefined" && self.titleOutDate != '') {
                    titleOutDate = self.apiRequestDateFormat(self.titleOutDate);
                }
                if (self.dateReceived != null && typeof self.dateReceived != "undefined" && self.dateReceived != '') {
                    dateReceived = self.apiRequestDateFormat(self.dateReceived);
                }
                if (self.reconFinished != null && typeof self.reconFinished != "undefined" && self.reconFinished != '') {
                    reconFinished = self.apiRequestDateFormat(self.reconFinished);
                }
                if (self.availableDate != null && typeof self.availableDate != "undefined" && self.availableDate != '') {
                    availableDate = self.apiRequestDateFormat(self.availableDate);
                }
                if (self.specialExpirationDate != null && typeof self.specialExpirationDate != "undefined" && self.specialExpirationDate != '') {
                    specialExpirationDate = self.apiRequestDateFormat(self.specialExpirationDate);
                }

                if (self.OrderRecvDate != null && typeof self.OrderRecvDate != "undefined" && self.OrderRecvDate != '') {
                    OrderRecvDate = self.apiRequestDateFormat(self.OrderRecvDate);
                }
                if (self.OrderReleaseDate != null && typeof self.OrderReleaseDate != "undefined" && self.OrderReleaseDate != '') {
                    OrderReleaseDate = self.apiRequestDateFormat(self.OrderReleaseDate);
                }
                if (self.SchedBuildDate != null && typeof self.SchedBuildDate != "undefined" && self.SchedBuildDate != '') {
                    SchedBuildDate = self.apiRequestDateFormat(self.SchedBuildDate);
                }
                if (self.RqstDelvDate != null && typeof self.RqstDelvDate != "undefined" && self.RqstDelvDate != '') {
                    RqstDelvDate = self.apiRequestDateFormat(self.RqstDelvDate);
                }
                if (self.ProjDelvDate != null && typeof self.ProjDelvDate != "undefined" && self.ProjDelvDate != '') {
                    ProjDelvDate = self.apiRequestDateFormat(self.ProjDelvDate);
                }
                var companyId = "";
                if (self.companyId == null || !self.companyId.value) {
                    companyId = "";
                }else{
                    companyId = self.companyId.value;
                }
                
                var acquireFromId = "";
                if (self.acquireFromId == null || !self.acquireFromId.value) {
                    acquireFromId = "";
                }else{
                    acquireFromId = self.acquireFromId.value;
                }
                var acquireById = "";
                if (self.acquireById == null || !self.acquireById.value) {
                    acquireById = "";
                }else{
                    acquireById = self.acquireById.value;
                }
                var managedById = "";
                if (self.managedById == null || !self.managedById.value) {
                    managedById = "";
                }else{
                    managedById = self.managedById.value;
                }
                var location = "";
                if (self.location == null || !self.location.value) {
                    location = "";
                }else{
                    location = self.location.value;
                }
                var areaOnYard = "";
                if (self.areaOnYardId == null || !self.areaOnYardId.value) {
                    areaOnYard = "";
                }else{
                    areaOnYard = self.areaOnYardId.value;
                }
                var conditionId = "";
                if (self.conditionId == null || !self.conditionId.value) {
                    conditionId = "";
                } else {
                    conditionId = self.conditionId.value;
                }
                var titleInId = "";
                if (self.titleInId == null || !self.titleInId.value) {
                    titleInId = "";
                } else {
                    titleInId = self.titleInId.value;
                }
                var statusId = "";
                if (self.statusId == null || !self.statusId.value) {
                    statusId = "";
                } else {
                    statusId = self.statusId.value;
                }
                var visibilityTypeId = "";
                if (self.visibilityTypeId == null || !self.visibilityTypeId.value) {
                    visibilityTypeId = "";
                } else {
                    visibilityTypeId = self.visibilityTypeId.value;
                }
                var saleTypeId = "";
                if (self.saleTypeId == null || !self.saleTypeId.value) {
                    saleTypeId = "";
                } else {
                    saleTypeId = self.saleTypeId.value;
                }
                var isFeaturedId = "";
                if (self.isFeaturedId == null || !self.isFeaturedId.value) {
                    isFeaturedId = "";
                } else {
                    isFeaturedId = self.isFeaturedId.value;
                }
                var warrantyTypeId = "";
                if (self.warrantyTypeId == null || !self.warrantyTypeId.value) {
                    warrantyTypeId = "";
                } else {
                    warrantyTypeId = self.warrantyTypeId.value;
                }
                var apuId = "";
                if (self.apuId == null || !self.apuId.value) {
                    apuId = "";
                } else {
                    apuId = self.apuId.value;
                }
                var bodyColorId = "";
                if (self.bodyColorId == null || !self.bodyColorId.value) {
                    bodyColorId = "";
                } else {
                    bodyColorId = self.bodyColorId.value;
                }
                var bodyNotesId = "";
                if (self.bodyNotesId == null || !self.bodyNotesId.value) {
                    bodyNotesId = "";
                } else {
                    bodyNotesId = self.bodyNotesId.value;
                }
                var liftgateCapacityId = "";
                if (self.liftgateCapacityId == null || !self.liftgateCapacityId.value) {
                    liftgateCapacityId = "";
                } else {
                    liftgateCapacityId = self.liftgateCapacityId.value;
                }
                var ptoId = "";
                if (self.ptoId == null || !self.ptoId.value) {
                    ptoId = "";
                } else {
                    ptoId = self.ptoId.value;
                }
                var bodyLengthId = "";
                if (self.bodyLengthId == null || !self.bodyLengthId.value) {
                    bodyLengthId = "";
                } else {
                    bodyLengthId = self.bodyLengthId.value;
                }
                var liftgateId = "";
                if (self.liftgateId == null || !self.liftgateId.value) {
                    liftgateId = "";
                } else {
                    liftgateId = self.liftgateId.value;
                }
                var bodyMakeId = "";
                if (self.bodyMakeId == null || !self.bodyMakeId.value) {
                    bodyMakeId = "";
                } else {
                    bodyMakeId = self.bodyMakeId.value;
                }
                var apuModelId = "";
                if (self.apuModelId == null || !self.apuModelId.value) {
                    apuModelId = "";
                } else {
                    apuModelId = self.apuModelId.value;
                }
                var bodyModelId = "";
                if (self.bodyModelId == null || !self.bodyModelId.value) {
                    bodyModelId = "";
                } else {
                    bodyModelId = self.bodyModelId.value;
                }
                var liftgateModelId = "";
                if (self.liftgateModelId == null || !self.liftgateModelId.value) {
                    liftgateModelId = "";
                } else {
                    liftgateModelId = self.liftgateModelId.value;
                }
                var PTOProvIndc = "";
                if (self.PTOProvIndc == null || !self.PTOProvIndc.value) {
                    PTOProvIndc = "";
                } else {
                    PTOProvIndc = self.PTOProvIndc.value;
                }
                var InvOrderType = "";
                if (self.InvOrderType == null || !self.InvOrderType.value) {
                    InvOrderType = "";
                } else {
                    InvOrderType = self.InvOrderType.value;
                }
                var InventoryType = "";
                if (self.inventoryType == null || !self.inventoryType.value) {
                    InventoryType = "";
                } else {
                    InventoryType = self.inventoryType.value;
                }
                var storage_location = "";
                if (self.storageLocId == null || !self.storageLocId.value) {
                    storage_location = "";
                }else{
                    storage_location = self.storageLocId.value;
                }

                var data = {
                    /* inventory_type: self.inventoryType, */
                    inventory_type : InventoryType,
                    // is_truck : (self.is_truck) ? 'truck' : 'trailer',
                    tag_id : self.tagId,
                    is_truck : self.isTruckType.value,
                    acquire_from_id : acquireFromId,
                    acquire_by_id : acquireById,
                    managed_by : managedById,

                    company_id: companyId,
                    location_id: location,
                    area_on_yard_id: areaOnYard,
                    lot_location: self.lotLocation,
                    state_id: self.state.value,
                    order_no: self.orderNo,
                    order_date: orderDate,
                    ordered_for: self.orderedFor,
                    price_paid: self.pricePaid,
                    consignment_price: self.ConsignmentPrice,
                    acquired_on_date: acquireOnDate,
                    title_out_date: titleOutDate,
                    date_received: dateReceived,
                    recon_finished: reconFinished,
                    special_expiration_date: specialExpirationDate,
                    year: self.year,
                    make_id: self.makeId.value,
                    model_id: self.modelId.value,
                    vinNo: self.vinNo,
                    stock_num: self.stockNum,
                    stock_no: self.stockNo,
                    classification: self.classification,
                    condition_id: conditionId,
                    asking_ratail_usd: self.askingRetailUsd,
                    inv_order_type : InvOrderType,
                    dtna_cusno:self.custNo,
                    dtna_concessionno:self.ConcessionNo,
                    dtna_ptoprovindc : PTOProvIndc,
                    dtna_orderrecvdate : OrderRecvDate,
                    dtna_orderreleasedate : OrderReleaseDate,
                    dtna_schedbuilddate : SchedBuildDate,
                    dtna_rqstdelvdate : RqstDelvDate,
                    dtna_projdelvdate : ProjDelvDate,
                    min_retail_usd: self.getValidPriceVal(self.minRetailUsd),
                    high_retail_usd: self.getValidPriceVal(self.highRetailUsd),
                    title_in_id: titleInId,
                    show_price_online_id: (self.showPriceOnlineId) ? 'yes' : 'no',
                    show_truck_online : (self.showTruckOnline) ? 1 : 0,
                    available_date: availableDate,
                    wholeshale_usd: self.getValidPriceVal(self.wholeShaleUsd),
                    quantity: self.quantity,
                    status_id: statusId,
                    visibility_type_id: visibilityTypeId,
                    sale_type_id: saleTypeId,
                    is_featured_id: isFeaturedId,
                    description: self.descrition,
                    website: self.website,
                    private_comment: self.privateComment,
                    featured_text: self.featuredText,
                    special_feature: self.specialFeature,
                    youtube_video_url: self.yupuTubeVideoUrl,
                    warranty_type_id: warrantyTypeId,
                    apu_id: apuId,
                    body_color_id: bodyColorId,
                    body_notes_id: bodyNotesId,
                    liftgate_capacity_id: liftgateCapacityId,
                    pto_id: ptoId,
                    body_length_id: bodyLengthId,
                    liftgate_id: liftgateId,
                    body_make_id: bodyMakeId,
                    apu_model_id: apuModelId,
                    body_model_id: bodyModelId,
                    liftgate_model_id: liftgateModelId,
                    imagePath: self.imagesPaths,
                    imageSize: self.imagesSize,
                    imageType: self.imagesType,
                    retail_price: self.getValidPriceVal(self.retailPrice),
                    purchase_date : purchaseDate,
                    consignment : self.consignment,
                    exterior_360_video_url: self.exterior360VideoUrl,
                    storage_location_id: storage_location,
                    is_bailment : (self.isBailment == null || !self.isBailment.value) ? 0 : self.isBailment.value,
                    retail_ready_id: (self.retailReadyId == null || !self.retailReadyId.value) ? "no" : self.retailReadyId.value,
                }
                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    data.id = self.$route.params.id;
                }

                return data;
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.imagesPaths.push(response.content.media_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                this.$refs.dropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                this.$refs.dropzone.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.media_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        if(err && err.response && err.response.status != '') {
                            if (err.response.status == 0) {
                                self.error = "Remote server can not be reachable";
                            } else if(err.response.status == 401){
                                //redirect to login page if user not authenticated
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                                self.error = err.response.data.message;
                            }
                        }else{
                            self.catchHandler(err, function () {});
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            uploadManually: function (file) {
                var self = this;
                // Check if the dropzone ref exists before accessing its properties or methods as dropzone only available when logged in user have permission
                if(self.loadDocArr.length > 0 && self.$refs.dropzone){
                    for (var i = 0; i < self.loadDocArr.length; i++) {
                        if (self.loadDocArr[i]['media_from'] == 'media_url') {
                            var mockFile = {name: "image_" + i + ".jpg", size: self.loadDocArr[i]['size']};
                            let imgUrl = self.loadDocArr[i]['thumb_url'];
                            let imageName = imgUrl.split("https://www.imanpro.net/pub/co/sac/photo/");
                            this.$refs.dropzone.dropzone.options.addedfile.call(this.$refs.dropzone.dropzone, mockFile);
                            this.$refs.dropzone.dropzone.options.thumbnail.call(this.$refs.dropzone.dropzone, mockFile, imgUrl);
                        } else {
                            var url = self.loadDocArr[i]['media_path'];
                            var file_name = self.getFileName(self.loadDocArr[i]['media_path']);
                            var file_type = self.loadDocArr[i]['type'];
                            var file_size = self.loadDocArr[i]['size'];
                            self.imagesType.push(file_type);
                            self.imagesSize.push(Number(file_size));
                            var file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};


                            self.$refs.dropzone.manuallyAddFile(file_data, url);
                        }
                    }
                }
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

            },
            getFileType: function (file_name) {

                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            getFileName: function (file) {

                var self = this;
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];

                self.imagesPaths.push(String(orgPath + "/" + file_name));

                return file_name;
            },
            removeAllFiles: function () {
                this.$refs.dropzone.removeAllFiles();
            },
            changeSpecialExpirationDate: function () {
                self.specialExpirationError = "";
            },
            changedBodyMakeValue: function (value) {
                self.bodyModelArr = [];
                self.allBodyModelArr.forEach(function(item) {
                    if(item.bodyMakeId == value.value) {
                        self.bodyModelArr.push(JSON.parse(JSON.stringify(item)));
                    }
                });
                self.bodyModelId = "";
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            
            loadTypeMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.isMakeRecordNotFound = self.isModelRecordNotFound = self.isConditionRecordNotFound = false;
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
                // self.modelArr = callbackData.Model;
                // if(self.modelArr.length == 0) {
                //     self.isModelRecordNotFound = true;
                // }
                self.conditionArr = callbackData.Condition;
                if(self.conditionArr.length == 0) {
                    self.isConditionRecordNotFound = true;
                }
                //Load dropdowns
                self.loadAllData(
                    [
                        'Status', 
                        'SaleType', 
                        'WarrantyType', 
                        'Company', 
                        'BodyModel', 
                        'LiftgateModel', 
                        'User', 
                        'AreaOnYardMapper', 
                        'InventoryType', 
                        'Location'
                    ], 
                    false, 
                    self.loadMasterDataCallback, 
                    "undefined", 
                    ''
                );
                setTimeout(function () {
                    //Load APU Dropdowns#2
                    self.loadAllData(
                        [
                            'Apu', 
                            'BodyColor', 
                            'BodyNotes', 
                            'LiftgateCapacity', 
                            'PTO', 
                            'BodyLength', 
                            'Liftgate', 
                            'BodyMake', 
                            'ApuModel', 
                        ], 
                        false, 
                        self.loadMasterDataCallback2, 
                        "undefined", 
                        ''
                    );
                }, 1500);
            },
            loadMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.isAreaOnYardRecordNotFound = self.isCompanyRecordNotFound = false;
                self.areaOnYardArr = callbackData.AreaOnYardMapper;
                if(self.areaOnYardArr.length == 0) {
                    self.isAreaOnYardRecordNotFound = true;
                }
                self.inventoryArr = callbackData.InventoryType;
                self.statusArr = callbackData.Status;
                self.saleTypeArr = callbackData.SaleType;
                self.warrantyTypeArr = callbackData.WarrantyType;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }

                self.allBodyModelArr = callbackData.BodyModel;
                self.bodyModelArr = callbackData.BodyModel;
                self.liftgateModelArr = callbackData.LiftgateModel;
                self.storageLocArr = callbackData.Location;
                self.isAcquireFromRecordNotFound = self.isAcquireByRecordNotFound = self.isManagedByRecordNotFound = false;
                self.acquireFromArr = callbackData.Vendor;
                if(self.acquireFromArr.length == 0) {
                    self.isAcquireFromRecordNotFound = true;
                }
                self.acquireByArr = callbackData.User;
                if(self.acquireByArr.length == 0) {
                    self.isAcquireByRecordNotFound = true;
                }
                self.managedByArr = callbackData.User;
                if(self.managedByArr.length == 0) {
                    self.isManagedByRecordNotFound = true;
                }
            },
            loadTagsDataCallback: function (callbackData) {
                self.tagArr = callbackData.Tag;
            },
            loadMasterDataCallback2: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.apuArr = callbackData.Apu;
                self.bodyColorArr = callbackData.BodyColor;
                self.bodyNotesArr = callbackData.BodyNotes;
                self.liftgateCapacityArr = callbackData.LiftgateCapacity;
                self.ptoArr = callbackData.PTO;
                self.bodyLengthArr = callbackData.BodyLength;
                self.liftgateArr = callbackData.Liftgate;
                self.bodyMakeArr = callbackData.BodyMake;
                self.apuModelArr = callbackData.ApuModel;
            },
            changedMakeValue : function(){
                let make_id = "";
                if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0) {
                    make_id = self.makeId.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, "undefined", '', make_id,'','','','','',self.type.toLowerCase());
            },
            onSearchMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Make'], true, self.loadMakeDataCallback, loading, search);
                }
            },
            loadMakeDataCallback: function (callbackData) {
                self.isMakeRecordNotFound = false;
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
            },
            onSearchModel(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    let make_id = "";
                    if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0) {
                        make_id = self.makeId.value;
                    }
                    self.loadAllData(['Model'], true, self.loadModelDataCallback, loading, search, make_id);
                }
            },
            loadModelDataCallback: function (callbackData) {
                self.isModelRecordNotFound = false;
                self.modelArr = typeof callbackData.Model != 'undefined' ? callbackData.Model : [];
                if(self.modelArr.length == 0) {
                    self.isModelRecordNotFound = true;
                }
            },
            loadAreaOnYardDataCallback: function (callbackData) {
                self.isAreaOnYardRecordNotFound = false;
                self.areaOnYardArr = typeof callbackData.AreaOnYardMapper != 'undefined' ? callbackData.AreaOnYardMapper : [];
                if(self.areaOnYardArr.length == 0) {
                    self.isAreaOnYardRecordNotFound = true;
                }
            },
            onSearchCondition(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Condition'], true, self.loadConditionCallback, loading, search);
                }
            },
            loadConditionCallback: function (callbackData) {
                self.isConditionRecordNotFound = false;
                self.conditionArr = callbackData.Condition;
                if(self.conditionArr.length == 0) {
                    self.isConditionRecordNotFound = true;
                }
            },
            /* loadStatusCallback: function (callbackData) {
                self.statusArr = callbackData.Status;
            },
            loadSaleTypeCallback: function (callbackData) {
                self.saleTypeArr = callbackData.SaleType;
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArr = callbackData.WarrantyType;
            },
            loadApuCallback: function (callbackData) {
                self.apuArr = callbackData.Apu;
            },
            loadBodyColorCallback: function (callbackData) {
                self.bodyColorArr = callbackData.BodyColor;
            },
            loadBodyNotesCallback: function (callbackData) {
                self.bodyNotesArr = callbackData.BodyNotes;
            },
            loadLiftgateCapacityCallback: function (callbackData) {
                self.liftgateCapacityArr = callbackData.LiftgateCapacity;
            },
            loadPtoCallback: function (callbackData) {
                self.ptoArr = callbackData.PTO;
            },
            loadBodyLengthCallback: function (callbackData) {
                self.bodyLengthArr = callbackData.BodyLength;
            },
            loadLiftgateCallback: function (callbackData) {
                self.liftgateArr = callbackData.Liftgate;
            },
            loadBodyMakeCallback: function (callbackData) {
                self.bodyMakeArr = callbackData.BodyMake;
            },
            loadApuModelCallback: function (callbackData) {
                self.apuModelArr = callbackData.ApuModel;
            },
            loadBodyModelCallback: function (callbackData) {
                self.bodyModelArr = callbackData.BodyModel;
            },
            loadLiftgateModelCallback: function (callbackData) {
                self.liftgateModelArr = callbackData.LiftgateModel;
            }, */
            onSearchAcquireFrom(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Vendor'], true, self.loadAcquireFromCallback, loading, search);
                }
            },
            loadAcquireFromCallback: function (callbackData) {
                self.isAcquireFromRecordNotFound = false;
                self.acquireFromArr = callbackData.Vendor;
                if(self.acquireFromArr.length == 0) {
                    self.isAcquireFromRecordNotFound = true;
                }
            },
            onSearchAcquireBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadAcquireByCallback, loading, search);
                }
            },
            loadAcquireByCallback: function (callbackData) {
                self.isAcquireByRecordNotFound = false;
                self.acquireByArr = callbackData.User;
                if(self.acquireByArr.length == 0) {
                    self.isAcquireByRecordNotFound = true;
                }
            },
            onSearchManagedBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadManagedByCallback, loading, search);
                }
            },
            loadManagedByCallback: function (callbackData) {
                self.isManagedByRecordNotFound = false;
                self.managedByArr = callbackData.User;
                if(self.managedByArr.length == 0) {
                    self.isManagedByRecordNotFound = true;
                }
            },
            changePurchaseDate: function () {
                self.purchaseDateError = "";
            },
            changeAvailableDate: function () {
                self.availableDateError = "";
            },
            changeAvailableDateDate: function () {
                self.availableDateError = "";
            },
            changeTitleIn: function () {

            },
            changeAcuireDate: function () {
                self.acquireOnDateError = "";
            },
            chageOrderDate: function () {
                self.orderDateError = "";
            },
            changedValueLocation: function () {
                if(self.isAddFrom){
                    self.lotLocation = self.location && self.location.text ? self.location.text : '';
                } else {
                    if (typeof (self.editLotLocation) != 'undefined' && self.editLotLocation != 0 && self.editLotLocation != '' && self.editLotLocation != null ) {
                    } else {
                        self.lotLocation = self.location.text;
                    }
                }
                let location_id = "";
                self.areaOnYardId = '';
                if (typeof(self.location) != "undefined" && self.location != null && self.location != "" && self.location != 0) {
                    location_id = self.location.value;
                }
                self.loadAllData(['AreaOnYardMapper'], false, self.loadAreaOnYardDataCallback, "undefined", '', location_id);
            },
            changeIsTruck: function () {

            },
            onSearchState(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
                }
            },
            loadStateDataCallback: function (callbackData) {
                self.stateArr = callbackData.States;
            },
            loadCityDataCallback: function (callbackData) {
                self.cityArr = callbackData.Cities;
            },
            onSearchCity(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select State First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityDataCallback, loading, search, self.state.value);
                    }
                }
            },
            onSearchCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            ucfirstString : function (str){
                str = (str && str != null) ? str : '';
                str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                    return letter.toUpperCase();
                });
                return str;
            },
            getAssetDetailsById: function (data) {
                
                // populate selected tags in tags dropdown
                if ( data.content['asset_data'].tags.length > 0 ) {
                    
                    for ( var asstCnt=0; asstCnt<data.content['asset_data'].tags.length; asstCnt++ ) {
                        // check if tag is not null. This can be happen in case if tag is deleted by user from master module
                        if ( data.content['asset_data'].tags[asstCnt].tag != null ) {
                            self.tagId.push({text: data.content['asset_data'].tags[asstCnt].tag.tag_label, value: data.content['asset_data'].tags[asstCnt].tag.id});
                        }
                    }
                }

                self.isTruckType = {text: self.capitalizeFirstLetter(data.content['asset_data'].is_truck), value: data.content['asset_data'].is_truck};
                self.inv_type = self.capitalizeFirstLetter(data.content['asset_data'].is_truck);
                // self.is_truck = (data.content['asset_data'].is_truck == "trailer") ? false : true;
                // self.isTruckOrgVal = (data.content['asset_data'].is_truck == "trailer") ? false : true;
                self.isTruckOrgVal = data.content['asset_data'].is_truck;
                self.showPriceOnlineId = (data.content['asset_data'].show_price_online == "yes") ? true : false;
                self.showTruckOnline = (data.content['asset_data'].is_active == 1) ? true : false;
                /* if (data.content['asset_data'].show_price_online == 'yes') {
                    self.showPriceOnlineId = {text: self.capitalizeFirstLetter(data.content['asset_data'].show_price_online), value: data.content['asset_data'].show_price_online};
                } else {
                    self.showPriceOnlineId = {text: self.capitalizeFirstLetter(data.content['asset_data'].show_price_online), value: data.content['asset_data'].show_price_online};
                } */
                self.acquireFromId = {text: self.capitalizeFirstLetter(data.content['asset_data'].acquired_from_name_full), value: data.content['asset_data'].acquired_from_id};
                self.acquireById = {text: self.capitalizeFirstLetter(data.content['asset_data'].acquired_by_name_full), value: data.content['asset_data'].acquired_by_id};
                self.managedById = {text: self.capitalizeFirstLetter(data.content['asset_data'].managed_by_name_full), value: data.content['asset_data'].managed_by_id};
                self.editLotLocation = self.lotLocation = data.content['asset_data'].lot_location;
                self.companyId = {text: self.capitalizeFirstLetter(data.content['asset_data'].company_name), value: data.content['asset_data'].company_id};
                self.statusId = {text: self.capitalizeFirstLetter(data.content['asset_data'].status_name), value: data.content['asset_data'].status_id};
                self.quantity = data.content['asset_data'].quantity;
                if (data.content['asset_data'].visibility_type == "public") {
                    self.visibilityTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].visibility_type), value: data.content['asset_data'].visibility_type};
                } else {
                    self.visibilityTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].visibility_type), value: data.content['asset_data'].visibility_type};
                }
                self.saleTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].sale_type_name), value: data.content['asset_data'].sale_type_id};
                if (data.content['asset_data'].is_featured == 'yes') {
                    self.isFeaturedId = {text: self.capitalizeFirstLetter(data.content['asset_data'].is_featured), value: 1};
                } else {
                    self.isFeaturedId = {text: self.capitalizeFirstLetter(data.content['asset_data'].is_featured), value: 2};
                }
                self.orderNo = data.content['asset_data'].order_no;
                if (data.content['asset_data'].order_date != null && data.content['asset_data'].order_date != '') {
                    self.orderDate = data.content['asset_data'].order_date;
                }
                if (data.content['asset_data'].acquire_on_date != null && data.content['asset_data'].acquire_on_date != '') {
                    self.acquireOnDate = data.content['asset_data'].acquire_on_date;
                }
                self.orderedFor = data.content['asset_data'].ordered_for;
                self.pricePaid = data.content['asset_data'].price_paid;
                self.ConsignmentPrice = data.content['asset_data'].consignment_price;
                self.descrition = data.content['asset_data'].description;
                self.website = data.content['asset_data'].website;
                if (data.content['asset_data'].title_in == 'yes') {
                    self.titleInId = {text: self.capitalizeFirstLetter(data.content['asset_data'].title_in), value: 1};
                } else {
                    self.titleInId = {text: self.capitalizeFirstLetter(data.content['asset_data'].title_in), value: 2};
                }
                self.privateComment = data.content['asset_data'].private_comment;
                if (data.content['asset_data'].title_out_date != null && data.content['asset_data'].title_out_date != '') {
                    self.titleOutDate = data.content['asset_data'].title_out_date;
                }
                if (data.content['asset_data'].date_receive != null && data.content['asset_data'].date_receive != '') {
                    self.dateReceived = data.content['asset_data'].date_receive;
                }
                if (data.content['asset_data'].recon_finished != null && data.content['asset_data'].recon_finished != '') {
                    self.reconFinished = data.content['asset_data'].recon_finished;
                }
                if (data.content['asset_data'].location_id != null && data.content['asset_data'].location_id != '') {
                    self.location={text: data.content['asset_data'].location_name, value: data.content['asset_data'].location_id};
                    setTimeout(function() {
                        if (data.content['asset_data'].area_on_yard_id != null && data.content['asset_data'].area_on_yard_id != '') {
                            self.areaOnYardId = {text: data.content['asset_data'].area_on_yard_name, value: data.content['asset_data'].area_on_yard_id};
                        }
                    }, 50);
                }
                if (data.content['asset_data'].available_date != null && data.content['asset_data'].available_date != '') {
                    self.availableDate = data.content['asset_data'].available_date;
                }
                self.year = data.content['asset_data'].year;
                if (data.content['asset_data'].special_expiration_date != null && data.content['asset_data'].special_expiration_date != '') {
                    self.specialExpirationDate = data.content['asset_data'].special_expiration_date;
                }
                if (data.content['asset_data'].purchase_date != null && data.content['asset_data'].purchase_date != '') {
                    self.purchaseDate = data.content['asset_data'].purchase_date;
                }
                self.retailPrice = data.content['asset_data'].retail_price;
                self.featuredText = data.content['asset_data'].featured_text;
                self.makeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].make_name), value: data.content['asset_data'].make_id};
                self.yupuTubeVideoUrl = data.content['asset_data'].youtube_video_url;
                self.modelId = {text: data.content['asset_data'].model_name_full, value: data.content['asset_data'].mode_id};
                self.warrantyTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].warranty_type_name), value: data.content['asset_data'].warranty_type_id};
                self.vinNo = data.content['asset_data'].vin_no;
                self.stockNum = data.content['asset_data'].stock_num;
                self.stockNo = data.content['asset_data'].unit;
                self.classification = data.content['asset_data'].classification;
                self.conditionId = {text: self.capitalizeFirstLetter(data.content['asset_data'].condition_name), value: data.content['asset_data'].condition_id};
                self.apuId = {text: self.capitalizeFirstLetter(data.content['asset_data'].apu_name), value: data.content['asset_data'].apu_id};
                self.bodyColorId = {text: self.capitalizeFirstLetter(data.content['asset_data'].body_color_name), value: data.content['asset_data'].body_color_id};
                self.bodyNotesId = {text: self.capitalizeFirstLetter(data.content['asset_data'].body_notes_name), value: data.content['asset_data'].body_notes_id};
                self.liftgateCapacityId = {text: self.capitalizeFirstLetter(data.content['asset_data'].liftgate_capacity_name), value: data.content['asset_data'].liftgate_capacity_id};
                self.ptoId = {text: self.capitalizeFirstLetter(data.content['asset_data'].pto_name), value: data.content['asset_data'].pto_id};
                self.bodyLengthId = {text: self.capitalizeFirstLetter(data.content['asset_data'].body_length_name), value: data.content['asset_data'].body_length_id};
                self.liftgateId = {text: self.capitalizeFirstLetter(data.content['asset_data'].liftgate_name), value: data.content['asset_data'].liftgate_id};
                self.bodyMakeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].body_make_name), value: data.content['asset_data'].body_make_id};
                self.apuModelId = {text: self.capitalizeFirstLetter(data.content['asset_data'].apu_model_name), value: data.content['asset_data'].apu_model_id};
                self.bodyModelId = {text: self.capitalizeFirstLetter(data.content['asset_data'].body_model_name), value: data.content['asset_data'].body_model_id};
                self.liftgateModelId = {text: self.capitalizeFirstLetter(data.content['asset_data'].liftgate_model_name), value: data.content['asset_data'].liftgate_model_id};
                self.askingRetailUsd = data.content['asset_data'].asking_retail_usd;
                self.custNo = data.content['asset_data'].dtna_cust_no;
                self.ConcessionNo = data.content['asset_data'].dtna_concession_no;
                if (data.content['asset_data'].is_stock != null && data.content['asset_data'].is_stock != '') {
                    self.InvOrderType = {text: self.capitalizeFirstLetter(data.content['asset_data'].is_stock), value: data.content['asset_data'].is_stock};
                }
                if (data.content['asset_data'].dtna_pto_prov_indc != null && data.content['asset_data'].dtna_pto_prov_indc != '') {
                    if(data.content['asset_data'].dtna_pto_prov_indc == 'n'){
                        self.PTOProvIndc = {text: "No", value: "n"};
                    } else {
                        self.PTOProvIndc = {text: "Yes", value: "y"};
                    }
                }

                if (data.content['asset_data'].dtna_order_recv_date != null && data.content['asset_data'].dtna_order_recv_date != '') {
                    self.OrderRecvDate = data.content['asset_data'].dtna_order_recv_date;
                }
                if (data.content['asset_data'].dtna_order_release_date != null && data.content['asset_data'].dtna_order_release_date != '') {
                    self.OrderReleaseDate = data.content['asset_data'].dtna_order_release_date;
                }
                if (data.content['asset_data'].dtna_sched_build_date != null && data.content['asset_data'].dtna_sched_build_date != '') {
                    self.SchedBuildDate = data.content['asset_data'].dtna_sched_build_date;
                }
                if (data.content['asset_data'].dtna_rqst_delv_date != null && data.content['asset_data'].dtna_rqst_delv_date != '') {
                    self.RqstDelvDate = data.content['asset_data'].dtna_rqst_delv_date;
                }
                if (data.content['asset_data'].dtna_proj_delv_date != null && data.content['asset_data'].dtna_proj_delv_date != '') {
                    self.ProjDelvDate = data.content['asset_data'].dtna_proj_delv_date;
                }
                self.minRetailUsd = data.content['asset_data'].min_retail_usd;
                self.highRetailUsd = data.content['asset_data'].high_retail_usd;
                self.wholeShaleUsd = data.content['asset_data'].wholeshale_usd;
                if (data.content['asset_data'].inventory_type_id != null && data.content['asset_data'].inventory_type_id != '') {
                    /* self.inventoryType = data.content['asset_data'].inventory_type; */
                    self.inventoryType = {text: data.content['asset_data'].inventory_type, value: data.content['asset_data'].inventory_type_id};
                } /* else {
                    self.inventoryType = self.capitalizeFirstLetter(data.content['asset_data'].is_truck);
                } */
                if (typeof (data.content['asset_data'].procede_branch) != 'undefined' && data.content['asset_data'].procede_branch != 0 && data.content['asset_data'].procede_branch != '' && data.content['asset_data'].procede_branch != null) {
                    self.procedeBranch = data.content['asset_data'].procede_branch;
                }
                if (typeof (data.content['asset_data'].dmv_notes) != 'undefined' && data.content['asset_data'].dmv_notes != 0 && data.content['asset_data'].dmv_notes != '' && data.content['asset_data'].dmv_notes != null) {
                    self.DMVNotes = data.content['asset_data'].dmv_notes;
                }
                if (typeof (data.content['asset_data'].consignment) != 'undefined' && data.content['asset_data'].consignment != '' && data.content['asset_data'].consignment != null) {
                    self.consignment = data.content['asset_data'].consignment;
                }
                if (data.content['asset_data'].exterior_360_video_url != null && data.content['asset_data'].exterior_360_video_url != '') {
                    self.exterior360VideoUrl = data.content['asset_data'].exterior_360_video_url;
                }
                if (data.content['asset_data'].storage_location_id != null && data.content['asset_data'].storage_location_id != '') {
                    self.storageLocId = {text: data.content['asset_data'].storage_location_name, value: data.content['asset_data'].storage_location_id};
                }
                
                self.loadDocArr = data.content['media'];
                if (data.content['asset_data'].is_bailment) {
                    console.log('1232 :>> ', data.content['asset_data'].is_bailment);
                    // Check the value of is_bailment
                    self.isBailment = {
                        text: data.content['asset_data'].is_bailment === 1 ? "Yes" : "No", // Show "Yes" if is_bailment is "1", otherwise "No"
                        value: data.content['asset_data'].is_bailment
                    };
                }

                if (data.content['asset_data'].retail_ready) {
                    if (data.content['asset_data'].retail_ready === 'yes-need-details') {
                        self.retailReadyId = {
                            text: 'Yes/Needs Detail',
                            value: data.content['asset_data'].retail_ready
                        };
                    } else {
                        self.retailReadyId = {
                            text: self.capitalizeFirstLetter(data.content['asset_data'].retail_ready),
                            value: data.content['asset_data'].retail_ready
                        };
                    }
                }

                //as called this function on event,no need to use timeout
                // setTimeout(function() {
                    self.uploadManually();
                // }, 500);
            },

            checkForm: function () {
                var checked = true;
                let fieldPosition = 0;
                if (self.ConsignmentPrice && !self.numberFormateValidation(self.ConsignmentPrice)) {
                    self.ConsignmentPriceError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.yupuTubeVideoUrl && !self.websiteUrlValidation(self.yupuTubeVideoUrl)) {
                    self.yupuTubeVideoUrlError = "Please enter valid url";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.website && !self.websiteUrlValidation(self.website)) {
                    self.websiteError = "Please enter valid url";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.pricePaid && !self.numberFormateValidation(self.pricePaid)) {
                    self.pricePaidError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.retailPrice && !self.numberFormateValidation(self.retailPrice)) {
                    self.retailPriceError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.askingRetailUsd && !self.numberFormateValidation(self.askingRetailUsd)) {
                    self.askingRetailUsdError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.minRetailUsd &&!self.numberFormateValidation(self.minRetailUsd)) {
                    self.minRetailUsdError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.highRetailUsd && !self.numberFormateValidation(self.highRetailUsd)) {
                    self.highRetailUsdError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.wholeShaleUsd && !self.numberFormateValidation(self.wholeShaleUsd)) {
                    self.wholeShaleUsdError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.year && !self.numberFormateValidation(self.year)) {
                    self.yearError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.quantity && !self.numberFormateValidation(self.quantity)) {
                    self.quantityError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.companyId == null || !self.companyId.value) {
                    self.companyError = "Please select company name";
                    fieldPosition = 0;
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.location == null || !self.location.value) {
                    self.locationError = "Please select location";
                    if (checked) {
                        fieldPosition = self.$refs.validLocation.getBoundingClientRect().top + window.scrollY - self.$refs.validLocation.offsetHeight;
                        checked = false;
                    }
                }
                /* if (self.acquireFromId == null || !self.acquireFromId.value) {
                    self.acquireFromError = "Please select Contact";
                    if (checked) {
                        checked = false;
                    }
                } */
                /*if (self.acquireById == null || !self.acquireById.value) {
                    self.acquireByError = "Please select User";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.managedById == null || !self.managedById.value) {
                    self.managedByError = "Please select User";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (!self.quantity) {
                    self.quantityError = "Please select quantity";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (!self.lotLocation) {
                 self.lotLocationError = "Please select lot location";
                 if (checked) {
                 checked = false;
                 }
                 }*/
                if (self.statusId == null || !self.statusId.value) {
                    self.statusError = "Please select status";
                    if (checked) {
                        fieldPosition = self.$refs.validStatus.getBoundingClientRect().top + window.scrollY - self.$refs.validStatus.offsetHeight;
                        checked = false;
                    }
                }
                if (self.visibilityTypeId == null || !self.visibilityTypeId.value) {
                    self.visibilityTypeError = "Please select visibility type";
                    if (checked) {
                        fieldPosition = self.$refs.validVisibilityType.getBoundingClientRect().top + window.scrollY - self.$refs.validVisibilityType.offsetHeight;
                        checked = false;
                    }
                }
                /*if (self.saleTypeId == null || !self.saleTypeId.value) {
                    self.saleTypeError = "Please select sale type";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /* if (!self.acquireOnDate) {
                    self.acquireOnDateError = "Please select acquire on date";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.purchaseDate) {
                    self.purchaseDateError = "Please select purchase date";
                    if (checked) {
                        checked = false;
                    }
                } */
                /*if (!self.orderDate) {
                 self.orderDateError = "Please select order date";
                 if (checked) {
                 checked = false;
                 }
                 }*/
                if (self.makeId == null || !self.makeId.value) {
                    self.makeError = "Please select make";
                    if (checked) {
                        fieldPosition = self.$refs.validMake.getBoundingClientRect().top + window.scrollY - self.$refs.validMake.offsetHeight;
                        checked = false;
                    }
                }
                if (self.modelId == null || !self.modelId.value) {
                    self.modelError = "Please select model";
                    if (checked) {
                        fieldPosition = self.$refs.validModel.getBoundingClientRect().top + window.scrollY - self.$refs.validModel.offsetHeight;
                        checked = false;
                    }
                }
                if (!self.vinNo) {
                    self.vinNoError = "Please enter vin no.";
                    if (checked) {
                        fieldPosition = self.$refs.validVin.getBoundingClientRect().top + window.scrollY - self.$refs.validVin.offsetHeight;
                        checked = false;
                    }
                } else {
                    if(self.isAddFrom == true){
                        if (!self.validateStringLength(self.vinNo,10)) {
                            self.vinNoError = "Please enter vinNo greater than " + 10 + " characters";
                            if (checked) {
                                fieldPosition = self.$refs.validVin.getBoundingClientRect().top + window.scrollY - self.$refs.validVin.offsetHeight;
                                checked = false;
                            }
                        }
                    }
                }
                if (!self.stockNo) {
                    self.stockNoError = "Please enter unit no.";
                    if (checked) {
                        fieldPosition = self.$refs.validVin.getBoundingClientRect().top + window.scrollY - self.$refs.validStock.offsetHeight;
                        checked = false;
                    }
                }
                /*if (!self.classification) {
                    self.classificationError = "Please select classification";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.conditionId == null || !self.conditionId.value) {
                    self.conditionError = "Please select condition";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if (self.exterior360VideoUrl && !self.websiteUrlValidation(self.exterior360VideoUrl)) {
                    self.exterior360VideoUrlError = "Please enter valid url";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.storageLocId == null || !self.storageLocId.value) {
                    self.storageLocError = "Please select storage location";
                    if (checked) {
                        checked = false;
                    }
                } */

                self.elementPosition = fieldPosition;

                if(!checked) {
                    window.scrollTo({
                        top: self.elementPosition,
                        behavior: 'smooth'
                    });
                }
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/asset/add/update/core", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.$route.params.id) {
                                                self.$emit('updateDisplayData',2);
                                                //used assetDataIsUpdated event(becasue assetdata is not updating at this timeout)
                                                // setTimeout(function() {
                                                //     self.removeAllFiles();
                                                //     self.getAssetDetailsById(self.asset);
                                                // }, 1500);
                                            } else {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        let err = "";
                                        for (const element of response.data.content) {
                                            err += element + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                                self.isTruckError = '';
                            })
                            .catch(function (err) {
                                self.isTruckError = '';
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
            clearDataOnSaveAndContinue: function () {
                self.removeAllFiles();
                self.companyId = '';
                self.quantity = '';
                self.lotLocation = '';
                self.editLotLocation = '';
                self.statusId = '';
                self.acquireFromId = '';
                self.acquireById = '';
                self.managedById = '';
                self.visibilityTypeId = '';
                self.saleTypeId = '';
                self.isFeaturedId = '';
                self.orderNo = '';
                self.orderDate = '';
                self.pricePaid = '';
                self.ConsignmentPrice = '';
                self.location = '';
                self.acquireOnDate = '';
                self.descrition = '';
                self.website = '';
                self.titleInId = '';
                self.privateComment = '';
                self.titleOutDate = '';
                self.availableDate = '';
                self.featuredText = '';
                self.year = '';
                self.specialExpirationDate = '';
                self.makeId = '';
                self.yupuTubeVideoUrl = '';
                self.modelId = '';
                self.warrantyTypeId = '';
                self.apuId = '';
                self.bodyColorId = '';
                self.bodyNotesId = '';
                self.liftgateCapacityId = '';
                self.ptoId = '';
                self.bodyLengthId = '';
                self.liftgateId = '';
                self.bodyMakeId = '';
                self.apuModelId = '';
                self.bodyModelId = '';
                self.liftgateModelId = '';
                self.vinNo = '';
                self.stockNum = '';
                self.stockNo = '';
                self.classification = '';
                self.conditionId = '';
                self.askingRetailUsd = '';
                self.minRetailUsd = '';
                self.highRetailUsd = '';
                self.showPriceOnlineId = true;
                self.showTruckOnline = true;
                self.wholeShaleUsd = '';
                self.inventoryType = '';
                self.consignment = '';
                self.storageLocId = '';
                self.isBailment = '';
                self.retailReadyId = '';
                self.elementPosition = 0;
                self.tagId = [];
            },
            /*
            * This function returns a valid price value.
            * If the provided priceVal is a non-empty string, not null, and not undefined, it returns the priceVal.
            * Otherwise, it returns 0.
            */
            getValidPriceVal: function(priceVal) {
                if (priceVal && priceVal !== '' && priceVal !== null && typeof priceVal !== 'undefined') {
                    return priceVal;
                } else {
                    return 0;
                }
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            
            self.permissionNameArray = [];
            for (const element of permissions) {
                self.permissionNameArray.push(element.permission_name)
            }
        }
    });
</script>
