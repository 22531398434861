import EventBus from "../components/event-bus";
import { HTTP } from '../http-common';
import messages from '../json/messages_en';
import label_messages from '../json/label_en';
//import moment from 'moment';
var moment = require("moment-timezone");

var self;

export const commonMixins = {
    data: () => ({
        allStaticMessages: messages,
        allLabelMessages: label_messages,
        roleId: 0,
        activeMenu: '',
        currentEnvironment: false,
        rowClass: {},
        currentPageName: "",
        listExecutionState: "",
    }),
    mounted: function () {

        /* $(window).scroll(function(e){
            var $el = $('.page_title');
            var isPositionFixed = ($el.css('position') == 'fixed');
            if ($(this).scrollTop() > 20 && !isPositionFixed){
                $el.css({'position': 'fixed', 'top': $(".navbar-default").outerHeight() + 'px','background-color' :'#FFFFFF','z-index':'999'});
            }
            if ($(this).scrollTop() < 20 && isPositionFixed){
                $el.css({'position': 'static', 'top': '0px','background-color' :'transparent','z-index':'unset'});
            }
        }); */
        /* var heightToListBody = (screen.availHeight - ($(".page_title").outerHeight() + $(".vdc-filters-container").outerHeight() + $(".vgt-wrap__footer").outerHeight() + $(".admin-footer").outerHeight()));
        $('.table-listing').css('max-height', (heightToListBody - 150) + 'px'); */
        self.currentPageName = self.returnCurrentPageUrl();
        if (process.env.VUE_APP_NODE_ENV == 'production') {
            this.currentEnvironment = false;
        } else {
            this.currentEnvironment = true;
        }
        if (window.location.href.indexOf("/resetpassword") > -1 && window.location.href.indexOf("/login") > -1 && window.location.href.indexOf("/forgotpassword") > -1 && $route.path.indexOf('/pub/print') > -1 && $route.path.indexOf('/pub/print/promo') > -1 && $route.path.indexOf('/email/promo/sheet') > -1) {
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({ "-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease" });
            $(".loginbg").show();
        } else {
            $(".app-sidebar-right").removeAttr("style");
            $(".loginbg").hide();
        }
        /* $(window).scroll(function() {
            var viewportWidth = $(window).width();
            var scroll = $(window).scrollTop();
            if (scroll >= 0.5 * $(window).height()) {
                if (viewportWidth <= 667) {
                    // console.log($(".app-sidebar-right").hasClass("toggled"));
                    if($(".app-sidebar-right").hasClass("toggled-2")){
                        $(".vgt-wrap__footer").addClass("sticky_pagination_manuopen");
                    } else {
                        $(".vgt-wrap__footer").removeClass("sticky_pagination_manuopen");
                    }
                } else {
                    // console.log($(".app-sidebar-right").hasClass("toggled-2"));
                    if($(".app-sidebar-right").hasClass("toggled-2")){
                        $(".vgt-wrap__footer").removeClass("sticky_pagination_manuopen");
                    } else {
                        $(".vgt-wrap__footer").addClass("sticky_pagination_manuopen");
                    }
                }
                $(".admin-footer").addClass("copyright-sticky");
                $(".table-listing").addClass("margin_bottom_100");
                $(".vgt-wrap__footer").addClass('sticky_pagination');
            } else {
                if (viewportWidth <= 667) {
                    // console.log($(".app-sidebar-right").hasClass("toggled"));
                    if($(".app-sidebar-right").hasClass("toggled-2")){
                        $(".vgt-wrap__footer").addClass("sticky_pagination_manuopen");
                    } else {
                        $(".vgt-wrap__footer").removeClass("sticky_pagination_manuopen");
                    }
                } else {
                    // console.log($(".app-sidebar-right").hasClass("toggled-2"));
                    if($(".app-sidebar-right").hasClass("toggled-2")){
                        $(".vgt-wrap__footer").addClass("sticky_pagination_manuopen");
                    } else {
                        $(".vgt-wrap__footer").removeClass("sticky_pagination_manuopen");
                    }
                }
                $(".admin-footer").removeClass("copyright-sticky");
                $(".table-listing").removeClass('margin_bottom_100');
                $(".vgt-wrap__footer").removeClass('sticky_pagination');
            }
        }); */
        localStorage.setItem("filterHide", 0);
        /*$(window).scroll(function() {
            if ($(".top_pagination").length > 0) {
                var bodyHeight = $("body").innerHeight();
                var viewportWidth = $(window).width();
                var scroll = $(window).scrollTop();
                if (scroll >= 1) {
                    if(bodyHeight > 1400){
                        if (viewportWidth <= 800) {

                        } else {
                            if($(".app-sidebar-right").hasClass("toggled-2")){
                                /* Menu closed */
        /*$(".page_title").removeClass("sticky_page_title_open_menu");
        $(".bottom_pagination").removeClass("sticky_vgt-wrap__footer_open_menu");
        $(".vdc-filters-container").removeClass("sticky_vdc-filters-container_open_menu");
        setTimeout(function () {
            $(".page_title").addClass("sticky_page_title");
            $(".bottom_pagination").addClass("sticky_vgt-wrap__footer");
            $(".vdc-filters-container").addClass("sticky_vdc-filters-container");
        }, 100);
    } else {
        /* Menu Open */
        /*$(".page_title").addClass("sticky_page_title sticky_page_title_open_menu");
        $(".bottom_pagination").addClass("sticky_vgt-wrap__footer sticky_vgt-wrap__footer_open_menu");
        $(".vdc-filters-container").addClass("sticky_vdc-filters-container sticky_vdc-filters-container_open_menu");
    }
    $(".top_pagination").css({'height': ($(".page_title").outerHeight() + $(".bottom_pagination").outerHeight() + $(".vdc-filters-container").outerHeight()) + 'px','border-color' : '#FFFFFF'});
}
$(".top_pagination").css({
    'height': ($(".page_title").outerHeight() + $(".bottom_pagination").outerHeight() + $(".vdc-filters-container").outerHeight() - 30) + 'px'
    ,'border-color' : '#FFFFFF'
    ,'top' : '400px'
});
setTimeout(function () {
    $(".bottom_pagination").css({
        'top' : ($(".page_title").outerHeight()  + $(".vdc-filters-container").outerHeight() + 55) + 'px'
    });
}, 100);
if (scroll >= ($(".vdc-filters-container").outerHeight()/3)) {
    /* if(localStorage.getItem("filterHide") == 0){
        localStorage.setItem("filterHide", 1);
        $(".vdc-filters-area").css({'display' : 'none'});
        // $(".vdc-filters-area").addClass('hideInWeb');
    } */
        /*}
    }
} else {
    $(".page_title").removeClass("sticky_page_title sticky_page_title_open_menu");
    $(".bottom_pagination").removeClass("sticky_vgt-wrap__footer sticky_vgt-wrap__footer_open_menu");
    $(".vdc-filters-container").removeClass("sticky_vdc-filters-container sticky_vdc-filters-container_open_menu");
    $(".top_pagination").removeAttr("style");
}
} else {
$(".page_title").removeClass("sticky_page_title sticky_page_title_open_menu");
$(".bottom_pagination").removeClass("sticky_vgt-wrap__footer sticky_vgt-wrap__footer_open_menu");
$(".vdc-filters-container").removeClass("sticky_vdc-filters-container sticky_vdc-filters-container_open_menu");
$(".top_pagination").removeAttr("style");
$(".bottom_pagination").removeAttr("style");
/* if(localStorage.getItem("filterHide") == 0){
    localStorage.setItem("filterHide", 1);
    console.log(localStorage.getItem("filterHide"));
    // $(".vdc-filters-area").removeClass('hideInWeb');
} */
        /*}
    });*/
        /* var expiry = localStorage.getItem("expiry");
        if (expiry != null) {
            if (new Date(expiry) < new Date()) {
                self.logout();
            }
        } else {
            localStorage.setItem("expiry", moment(new Date()).add(720, 'h').toDate());
        } */
    },
    created() {
    },
    beforeMount() {
        self = this;
    },
    computed: {
    },
    watch: {
    },
    methods: {
        apiRequestDateFormat: function (date) {
            if (date) {
                try {
                    return moment(date, process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT).format('YYYY-MM-DD');
                } catch (error) {
                    return '';
                }
            }
            return '';
        },
        apiRequestDateTimeFormat: function (date) {
            if (date) {
                try {
                    return moment(date, process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT).format('YYYY-MM-DD HH:mm:ss');
                } catch (error) {
                    return '';
                }
            }
            return '';
        },
        datePickerDateFormat: function (date) {
            if (date) {
                try {
                    return moment(date, 'YYYY-MM-D').format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT);
                } catch (error) {
                    return '';
                }
            }
            return '';
        },
        prodCountryObj: function () {
            return JSON.parse(localStorage.getItem('selected_prod_country'));
        },
        returnCurrentPageUrl: function () {
            localStorage.setItem("currentPageName", window.location.pathname);
            let currentPageName = localStorage.getItem("currentPageName");
            return currentPageName;
        },
        toggelExpandCollespeIcon: function (id, dataArray) {
            let accordianIds = dataArray;
            var icon = $('#accordion-' + id).children("i");
            if (icon.hasClass("fa-plus") === true) {
                // self.accordianIds = [];
                accordianIds.push(id);
            } else {
                const index = accordianIds.indexOf(id);
                if (index > -1) {
                    accordianIds.splice(index, 1);
                }
            }
            return accordianIds;
        },
        arrayContainsArray: function (superset, subset) {
            var resp = false;
            $.each(superset, function (i) {
                $.each(subset, function (j) {
                    if (superset[i] == subset[j]) {
                        resp = true;
                    }
                });
            });
            /*return subset.every(function (value) {
             return (superset.indexOf(value) >= 0);
             });*/
            return resp;
        },
        getLoginIdPermissions: function () {
            var apiToken = localStorage.getItem("api_token");
            if (apiToken != null) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                };
                HTTP.get('/permission/loginid/get', config)
                    .then(response => {
                        let permissions = [];

                        let per = response.data.content.role_permissions;
                        let extraPer = response.data.content.permissions;
                        for (let i = 0; i < per.length; i++) {
                            let obj = {};
                            obj["permission_name"] = per[i].permission_name;
                            permissions.push(obj);
                        }

                        for (let j = 0; j < extraPer.length; j++) {
                            let obj = {};
                            obj["permission_name"] = extraPer[j].permission_name;
                            permissions.push(obj);
                        }
                        EventBus.$emit('login-permissions', permissions);
                        let companies = [];
                        let comp = response.data.content.company;
                        for (let k = 0; k < comp.length; k++) {
                            let obj = {};
                            obj["company"] = Number(comp[k]);
                            companies.push(obj);
                        }
                        EventBus.$emit('login-company', companies);

                        EventBus.$emit('system-year', response.data.content.year);
                        
                        EventBus.$emit('build-tag', response.data.content.build_tag);

                        /* Build version checking start*/
                        let current_build = window.localStorage.getItem("build_version");
                        let latest_build = response.data.content.build;
                        if (current_build == null || current_build == 'null') {
                            localStorage.setItem("build_version", response.data.content.build);
                        } else if (current_build != undefined && current_build == '') {
                            localStorage.setItem("build_version", response.data.content.build);
                        } else if (current_build != latest_build) {
                            localStorage.setItem("build_version", response.data.content.build);
                            location.reload(true);
                        }
                        /* Build version checking end*/


                        if (response.data.content.prod_country) {
                            localStorage.setItem("prod_country", response.data.content.prod_country);
                        }

                        if (typeof response.data.content.prod_country_data != 'undefined') {
                            localStorage.setItem("selected_prod_country", JSON.stringify(response.data.content.prod_country_data));

                        }
                    })
                    .catch(function (err) {
                        /*self.logout();*/
                        /* console.log(err); */
                        /*self.catchHandler(err, function () {});*/
                    });
            }
        },
        logout: function () {
            let current_build = window.localStorage.getItem("build_version");
            localStorage.clear();
            localStorage.setItem("build_version", current_build);
            self.$router.push("/");
        },
        capitalizeFirstLetter: function (string) {
            let returnString = "";
            if (string != null && string != '') {
                returnString = string.charAt(0).toUpperCase() + string.slice(1);
            }
            return returnString;
        },
        calculateStartRecordEndRecord: function (currentPage1, perPage1, totalRecords1) {
            let serverPage = 0;
            let perPage = 0;
            let totalRecords = 0;
            var firstLoad = true;
            var firstRecord = 0;
            var lastRecord = 0;
            if (perPage1 == "All") {
                if (totalRecords1 == 0) {
                    firstRecord = 1;
                } else {
                    firstRecord = 1;
                }
                lastRecord = totalRecords1;
            } else {
                serverPage = Number(currentPage1);
                perPage = Number(perPage1);
                totalRecords = Number(totalRecords1);
                firstLoad = true;
                firstRecord = 0;
                lastRecord = 0;

                var page = serverPage;
                for (var i = 1; i <= page; i++) {
                    if (firstLoad == true) {
                        firstRecord = lastRecord + 1;
                        lastRecord = perPage;
                        firstLoad = false;
                    } else {
                        firstRecord = lastRecord + 1;
                        lastRecord = (perPage * i);
                    }
                }

                if (totalRecords != 0) {
                    if (lastRecord > totalRecords) {
                        lastRecord = totalRecords;
                    }
                } else if (totalRecords == 0) {
                    firstRecord = 0;
                    lastRecord = 0;
                }
            }

            return firstRecord + " - " + lastRecord;
        },

        getActiveMenu: function () {
            return self.activeMenu;
        },
        setActiveMenu: function (menu) {
            self.activeMenu = menu;
        },
        showLoader: function () {
            $(".loading-container").show();
        },
        hideLoader: function () {
            $(".loading-container").hide();
        },

        showLoaderForDiv: function (divId) {
            $("#" + divId).find(".loading-container.loading").show();
        },

        /* hideShowHeaderFooter: function() {
         $('#main-header').show();
         $('#sidebar-wrapper').show();
         $("#wrapper").removeClass("login-div-full");
         }, */
        showSAlert: function (message, type, callback, timer) {
            /*this.$swal.fire('', message, type);*/
            var obj = {};
            if (message === null || typeof message === "undefined" || message == '') {
            } else {
                obj['html'] = message;
            }
            if (type === null || typeof type === "undefined" || type == '') {
            } else {
                obj['type'] = type;
            }
            if (timer === null || typeof timer === "undefined" || timer == '') {
            } else {
                timer = (timer * 1000);
                obj['timer'] = timer;
                obj['showConfirmButton'] = false;
            }
            this.$swal.fire(obj).then(callback);
        },
        catchHandler: function (err, callback, isVendorPortal = 0) {
            var self = this;
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 0:
                        this.showSAlert('Remote server can not be reachable', 'error', callback);
                        break;
                    case 400:
                    case 403:
                    case 404:
                    case 409:
                        let errorMsg = (err.response.data && err.response.data.content) ? err.response.data.content : "Exception error ";
                        this.showSAlert(errorMsg, 'error', callback);
                        break;
                    case 401:
                        if(isVendorPortal) {
                            let current_build = window.localStorage.getItem("build_version");
                            localStorage.clear();
                            localStorage.setItem("build_version", current_build);
                            if (self.$route.path !== "/vendor/login") {
                                self.$router.push("/vendor/login");
                            }
                        } else {
                            localStorage.removeItem("user_obj");
                            localStorage.removeItem("tkn");
                            //Prevent redundant navigation AB#2217
                            if (self.$route.path !== "/") {
                                self.$router.push("/");
                            }
                        }
                        break;
                }
            }
        },
        jumpToInputPage: function (pageNumber, totalRecord, perPage) {
            var self = this;
            var reg = /^\d+$/;
            if (pageNumber <= 0 || pageNumber == '') {
                pageNumber = 1;
            } else if (pageNumber > self.pageNumberList(totalRecord, perPage)) {
                pageNumber = self.pageNumberList(totalRecord, perPage);
                if (pageNumber <= 0 || pageNumber == '') {
                    pageNumber = 1;
                }
            } else if (!String(pageNumber).match(reg)) {
                pageNumber = 1;
            }
            return pageNumber;
        },
        pageNumberList: function (totalRecord, perPage) {
            var self = this;
            var totalRecord = totalRecord;
            var count = 0;
            for (var i = 0; totalRecord > 0; i++) {
                totalRecord = totalRecord - perPage;
                count++;
            }
            return count;
        },
        loadAllMasterData: function (modelName, callback, search, dataObj = {}) {

            let reqData = JSON.parse(JSON.stringify(dataObj));
            reqData.modelName = modelName;
            reqData.isSearch = false;
            reqData.search = search;

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                },
                params: reqData
            };
            HTTP.get('/alldata/get', config)
                .then(response => {

                    if (callback != null) {
                        callback(response.data.content);
                    }
                })
                .catch(function (err) {

                    if (callback != null) {
                        callback([]);
                    }
                    // self.catchHandler(err, function () {});
                });
        },
        loadAllData: function (modelName, isSearch, callback, loading, search, commonId, IdsLimit, isMultiEdit, isCustomer, isQuotationLocation, isExcedeBranch, type, customParams = {}) {
            loading = (loading === null || typeof (loading) === "undefined" || loading == '' || loading == 0) ? 'undefined' : loading;
            search = (search === null || typeof (search) === "undefined" || search == '' || search == 0) ? '' : search;
            commonId = (commonId === null || typeof (commonId) === "undefined" || commonId == '' || commonId == 0) ? 0 : commonId;
            IdsLimit = (IdsLimit === null || typeof (IdsLimit) === "undefined" || IdsLimit == '' || IdsLimit == 0) ? '' : IdsLimit;
            isMultiEdit = (isMultiEdit === null || typeof (isMultiEdit) === "undefined" || isMultiEdit == '' || isMultiEdit == 0) ? 0 : isMultiEdit;
            isCustomer = (isCustomer === null || typeof (isCustomer) === "undefined" || isCustomer == '' || isCustomer == 0) ? 0 : isCustomer;
            isQuotationLocation = (isQuotationLocation === null || typeof (isQuotationLocation) === "undefined" || isQuotationLocation == '' || isQuotationLocation == 0) ? 0 : isQuotationLocation;
            isExcedeBranch = (isExcedeBranch === null || typeof (isExcedeBranch) === "undefined" || isExcedeBranch == '' || isExcedeBranch == 0) ? 0 : isExcedeBranch;
            type = (type === null || typeof (type) === "undefined" || type == '' || type == 0) ? 'used' : type;

            let reqData = typeof customParams == 'object' ? customParams : {};
            reqData.modelName = modelName;
            reqData.isSearch = isSearch;
            reqData.search = search;
            reqData.commonId = commonId;

            reqData.ids_limit = IdsLimit;
            reqData.is_customer = isCustomer;
            reqData.is_multi_edit = isMultiEdit;
            reqData.is_quotation_location = isQuotationLocation;
            reqData.is_excede_branch = isExcedeBranch;
            reqData.type = type;

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                },
                params: reqData
            };
            HTTP.get('/alldata/get', config)
                .then(response => {
                    if (isSearch && loading != "undefined") {
                        loading(false);
                    }
                    if (callback != null) {
                        callback(response.data.content);
                    }
                })
                .catch(function (err) {
                    if (isSearch && loading != "undefined") {
                        loading(false);
                    }
                    if (callback != null) {
                        callback([]);
                    }
                    // self.catchHandler(err, function () {});
                });
        },
        loadSalesmanUser: function (loading, search, vm, callback) {
            search = (search === null || typeof (search) === "undefined" || search == '') ? '' : search;
            var config = {
                headers: { Authorization: "Bearer " + localStorage.getItem("api_token") },
                params: {
                    search: search
                }
            };
            HTTP.get("/salesmanuser/get", config)
                .then(function (response) {
                    callback(response.data.content);
                    // self.salesmanIdArr = response.data.content;
                    if (typeof (loading) != "undefined") {
                        loading(false);
                    }
                })
                .catch(function (error) {
                    /* console.log(error); */
                    // self.catchHandler(err, function () {});
                })
        },
        formatString(data) {
            if (typeof (data) != 'undefined' && data != null) {
                var value = data.replace(/_/g, " ");
                var upp = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                });
                return upp;
            } else {
                return false;
            }
        },
        sortFieldDisplay(field, sort) {
            $(".column-header").removeClass("desc");
            $(".column-header").removeClass("asc");
            $("." + field).addClass(sort);
        },

        addColumnClass(headers) {
            let classArray = {};
            for (let i = 0; i < headers.length; i++) {
                let key = headers[i].column_name;
                classArray['' + key] = headers[i].column_class;
            }
            this.rowClass = classArray;
        },

        numberWithCommasAndDollerSign: function (x) {
            if (x === 0) {
                return null;
            }
            let num = "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            return num;
        },

        capitalEachLatter: function (txt) {
            var text = txt;
            text = text.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');

            return text;
        },
        /* validate functions Start*/
        websiteUrlValidation: function (url) {
            if (url != '' && url != null) {
                var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (res == null) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
        urlValidation: function (url) {
            var a = ["http://www.sample.com", "https://www.sample.com/", "https://www.sample.com#", "http://www.sample.com/xyz", "http://www.sample.com/#xyz", "www.sample.com", "www.sample.com/xyz/#/xyz", "sample.com", "sample.com?name=foo", "http://www.sample.com#xyz", "http://www.sample.c"];
            var re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
            return re.test(url);
        },
        numberFormateValidation: function (inputtxt) {
            var valid = !/^\s*$/.test(inputtxt) && !isNaN(inputtxt);
            return valid;
        },
        numberFormateWithSpaceValidation: function (inputtxt) {
            var valid = !/^\s*$/.test(inputtxt) && !isNaN(inputtxt);
            return valid;
        },
        validEmail: function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validNumeric: function (number) {
            var valid = (number.match(/^-?\d*(\.\d+)?$/));
            return valid.test(number);
        },
        validContactNumber: function (number) {
            return /^[0-9 -()+,-]*$/.test(number);
        },
        validPageNumberInput: function (number) {
            return /^[0-9-,-]*$/.test(number);
        },
        checkContactNoLength: function (val) {
            if (val.length > 13) {
                return false;
            } else {
                return true;
            }
        },
        validateStringLength: function (string, length) {
            string = string.trim();
            if (string.length < length) {
                return false;
            } else {
                return true;
            }
        },
        /* validate functions End*/
        /* Store and apply filters for different lists Start */
        /**
         * Store Filters in user's browser local storage of the pages by using this function.
         * @param {string} listType List identifier for stored filters fot the specific filter
         * @param {object} pageFilters object of the page filters for the pag which is identified by identifier
         * @param {boolean} clear Need to clear filters of other pages.
         */
        storeFilters: function (listType, pageFilters, clear) {
            if (typeof clear === 'undefined' || clear === null) {
                clear = false;
            }
            var getfilters = {};
            if (!clear) {
                var filterObj = localStorage.getItem("filters");
                if (filterObj != null) {
                    getfilters = JSON.parse(filterObj);
                }
            }
            getfilters[listType] = pageFilters;
            window.localStorage.setItem("filters", JSON.stringify(getfilters));
        },
        /**
         * It will load filters to the list from stored filter data to the list identifier.
         * @param {string} listType List identifier for load filters fot the specific list
         * @param {object} dataObj self object of the list page which is linked with list identifier.
         */
        loadFilters: function (listType, dataObj) {
            var appliedFilters = localStorage.getItem('filters');
            if (appliedFilters != null) {
                appliedFilters = JSON.parse(appliedFilters);
                if (appliedFilters[listType] != null) {
                    dataObj.filters = appliedFilters[listType].filters;
                    dataObj.perPage = appliedFilters[listType].per_page;
                    dataObj.perPageValue = appliedFilters[listType].per_page;
                    dataObj.currentPage = appliedFilters[listType].page;
                    dataObj.jumpToPage = appliedFilters[listType].page;
                    dataObj.columnName = appliedFilters[listType].sortColumnName;
                    dataObj.sortType = appliedFilters[listType].sortType;
                    return appliedFilters[listType];
                }
            }
        },
        /**
         * It will fill all the common filter inputs where applied filters need to be display in input box
         * @param {object} headersObj available list header object in page
         * @param {object} filters available filter object in page
         */
        loadFilterInputs: function (headersObj, filters) {
            $.each(headersObj, function (i) {
                for (var key in filters) {
                    if (headersObj[i].column_name == key) {
                        headersObj[i].field = filters[key];
                    }
                }
            });
        },
        /*
         */
        /**
         * Get the applied filter count using following function for the list.
         * We can use this function to get the applied filter count and display it near to label of the filters block
         * if previously usr have applied any filter in the same list and will apply more filter in same list.
         * @param {object} filters Filter object to find out applied filter count
         */
        filterCountFunction: function (filters) {
            var count = 0;
            $.each(filters, function (i) {
                var check = (filters[i] === null || typeof (filters[i]) === "undefined" || filters[i] == '') ? "" : filters[i];
                if (check != "") {
                    count++;
                }
            });
            return count;
        },
        /* Store and apply filters for different lists End */
        downloadFile: function (href) {
            var fileNameIndex = href.lastIndexOf("/") + 1;
            var filename = href.substr(fileNameIndex);
            if ($('#DynDownloadAnchor').length) {
                $('#DynDownloadAnchor').remove();
            }
            var anchor = document.createElement('a');
            anchor.id = "DynDownloadAnchor";
            anchor.style.display = "none";
            anchor.href = href;
            anchor.target = "_blank";
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
        },
        /* Update user object's specific key value */
        updateUserObjectInLocal: function (key, value) {
            var userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                userJson[key] = value;
                localStorage.setItem("user_obj", JSON.stringify(userJson));
            }
        },
        /* Store export header in user's browser local storage of the pages and inside DB by using this function --- Start */
        storeExportHeaders: function (exportType, selectedHeaders) {
            var getExportHeaders = {};
            var exportHeaderObj = localStorage.getItem('export_headers');
            if (exportHeaderObj != null) {
                getExportHeaders = JSON.parse(exportHeaderObj);
            }
            getExportHeaders[exportType] = selectedHeaders;
            window.localStorage.setItem('export_headers', JSON.stringify(getExportHeaders));
            self.storeExportHeadersInDB(JSON.stringify(getExportHeaders));
        },
        storeExportHeadersInDB: function (jsonData) {
            jsonData = (jsonData === null || typeof (jsonData) === "undefined" || jsonData == '' || jsonData == 0) ? '' : jsonData;
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                }
            };
            var data = new FormData();
            data.append('jsonData', jsonData);
            HTTP.post('/exportheader/add/update', data, config)
                .then(response => {
                    var data = response.data.content;
                })
                .catch(function (err) {
                    self.catchHandler(err, function () { });
                });
        },
        /* Store export header in user's browser local storage of the pages and inside DB by using this function --- End*/
        loadExportHeaders: function (exportType, headerObj) {
            var appliedExportHeaders = localStorage.getItem('export_headers');
            if (appliedExportHeaders != null && typeof appliedExportHeaders != "undefined") {
                appliedExportHeaders = JSON.parse(appliedExportHeaders);
                if (appliedExportHeaders[exportType] != null) {
                    headerObj.export_headers = appliedExportHeaders[exportType];
                    return appliedExportHeaders[exportType];
                }
            }
        },
        /* Store list header in user's browser local storage of the pages and inside DB by using this function --- Start */
        storeListHeaders: function (listType, selectedHeaders) {
            var getListHeaders = {};
            var listHeaderObj = localStorage.getItem('list_headers');
            if (listHeaderObj != null) {
                getListHeaders = JSON.parse(listHeaderObj);
            }
            getListHeaders[listType] = selectedHeaders;
            window.localStorage.setItem('list_headers', JSON.stringify(getListHeaders));
            self.storeListHeadersInDB(JSON.stringify(getListHeaders));
        },
        storeListHeadersInDB: function (jsonData) {
            jsonData = (jsonData === null || typeof (jsonData) === "undefined" || jsonData == '' || jsonData == 0) ? '' : jsonData;
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                }
            };
            var data = new FormData();
            data.append('jsonData', jsonData);
            HTTP.post('/listheader/add/update', data, config)
                .then(response => {
                    var data = response.data.content;
                })
                .catch(function (err) {
                    self.catchHandler(err, function () { });
                });
        },
        /* Store list header in user's browser local storage of the pages and inside DB by using this function --- End*/
        loadListHeaders: function (listType, headerObj) {
            var appliedListHeaders = localStorage.getItem('list_headers');
            if (appliedListHeaders != null && typeof appliedListHeaders != "undefined") {
                appliedListHeaders = JSON.parse(appliedListHeaders);
                if (appliedListHeaders[listType] != null) {
                    headerObj.list_headers = appliedListHeaders[listType];
                    return appliedListHeaders[listType];
                }
            }
        },
        convertToNum: function (string, decimal) {
            string = (string != null && string != 'undefined' && string != 0) ? string : 0;
            string = parseFloat(string);
            return string.toFixed(decimal);
        },
        onCopy: function (e) {
            $('#' + e.trigger.id).attr('title', 'Copied').tooltip('show');
        },
        onError: function (e) {
            self.showSAlert("Failed to copy the text to the clipboard", 'info', false);
            //   alert('Failed to copy the text to the clipboard')
            console.log(e);
        },
        addNewLineInColumn: function (value) {
            if (value != null && value != "") {
                let result = value.replaceAll(",", "<br/>");
                return result;
            } else {
                return "";
            }
        },
        addNewLineInEmailColumn: function (invoice) {
            if (invoice != null && invoice != "") {
                let strInvoice = invoice.replaceAll(",", "<br/>");
                return strInvoice;
            } else {
                return "";
            }
        },
        getSignedUrlPath(file) {
            var config = {
                headers: { Authorization: "Bearer " + localStorage.getItem("api_token") },
                params: {
                    file: file,
                }
            };
            HTTP.get("getsignedurlpath", config)
                .then(function (response) {
                    window.open(response.data.file_path);
                })
                .catch(function (err) {
                });
        },
        exportRecords: function () {
            
            //if records are more than configured value than it will export using queue else direct export
            if (this.totalRecords > process.env.VUE_APP_EXPORT_QUEUE) {
                alert(
                    "Total records are more than " +
                        process.env.VUE_APP_EXPORT_QUEUE +
                        ", So your file will be scheduled in queue. You can download it from batch screen once it will be completed."
                );
                this.exportAction = "export_queue";
            } else {
                this.exportAction = "export";
            }
            
        },
        openReorderHeadersListModal: function (modalTitle,action,moduleName,masterHeadersList) {
            let reqData = {
                modalTitle: modalTitle,
                action: action,
                moduleName: moduleName,
                masterHeadersList: masterHeadersList
            };
            this.$refs.reorderHeadersListModalRef.openModal(reqData);
        },
        formatKeyValue(data) {
            // Convert key-value pairs to the desired "Key: Value" format
            return Object.entries(data)
                .map(([key, value]) => `<strong>${key}:</strong> ${value}`)
                .join('<br>'); // Line break after each key-value pair
        },
        getVendorData() {
            let vendor_obj = localStorage.getItem('vendor_obj');
            return vendor_obj ? JSON.parse(vendor_obj) : null;
        },
        setVendorData(vendorData) {
            localStorage.setItem('vendor_obj', JSON.stringify(vendorData));
        }
    }
}
