<template>
    <div id="page-content-wrapper" class="main-internal-section">
        <div class="email-list">
            <div class="head-title">
                <div class="row no-gutters">
                    <div class="col-lg-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><i class="fa fa-envelope-o"></i>Email</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" id="calendar-tab" data-toggle="tab" href="#calendar" role="tab" aria-controls="calendar" aria-selected="false"><i class="fa fa-calendar"></i>calendar</a>
                            </li> -->
                        </ul>
                    </div>
                    <div class="col-lg-9 align-self-center">
                        <div class="row no-gutters">
                            <div class="col-lg-2">
                                <v-select
                                    v-model="filters.searchInFolder"
                                    name="filterFolder"
                                    id="filterFolder"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="folderArr">
                                </v-select>
                            </div>
                            <div class="col-lg-8">
                                <div class="search-box dropdown dropright">
                                    <div class="select-title">
                                        <div v-for="(param, paramInd) in searchParams" :key="param.id" :id="'filterTag'+param.id" class="select-id">
                                            <span><b>{{ param.key }} :</b> {{ param.value }}</span>
                                            <button type="button" class="btn" @click="removeSearchParam(param.id,param.key,paramInd)" aria-pressed="false" autocomplete="off">x</button>
                                        </div>
                                    </div>
                                    <input type="text" @keyup.enter="filterEmailList('global')" v-model="filters.searchGlobal" placeholder="Search" class="form-control">
                                    <!-- Commented as advance search not yet implemented -->
                                    <!-- <i class="fa fa-bars search-icon dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"></i> -->

                                    <div class="search-form dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        
                                        <div class="row mb-2">
                                            <div class="col-lg-2">
                                                <label class="form-label">From :</label>
                                            </div>
                                            <div class="col-lg-10">
                                                <input v-model="filters.searchFromEmail" type="text" placeholder="Search" class="form-control">
                                            </div>
                                        </div>
                                            
                                        <div class="row mb-2">
                                            <div class="col-lg-2">
                                                <label class="form-label">To :</label>
                                            </div>
                                            <div class="col-lg-10">
                                                <input v-model="filters.searchToEmail" type="text" placeholder="Search" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-lg-2">
                                                <label class="form-label">Cc :</label>
                                            </div>
                                            <div class="col-lg-10">
                                                <input v-model="filters.searchCcEmail" type="text" placeholder="Search" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-lg-2">
                                                <label class="form-label">Bcc :</label>
                                            </div>
                                            <div class="col-lg-10">
                                                <input v-model="filters.searchBccEmail" type="text" placeholder="Search" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-lg-2">
                                                <label class="form-label">Subject :</label>
                                            </div>
                                            <div class="col-lg-10">
                                                <input v-model="filters.searchSubject" type="text" placeholder="Search" class="form-control">
                                            </div>
                                            
                                        </div>
                                        <div class="row mb-2">
                                        <div class="col-lg-6">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label class="form-label">Date From: </label>
                                                </div>
                                                <div class="col-lg-8">
                                                    <date-picker
                                                            v-model="filters.searchDateFrom"
                                                            :config="dateOpenOptions"
                                                            class=""
                                                            autocomplete="off"
                                                            placeholder="From Date"
                                                            name="dateFrom"
                                                    >
                                                    </date-picker>
                                                </div>
                                            </div>
                                            </div>
                                                <!-- <input v-model="searchDateFrom" type="text" placeholder="Select Date" class="form-control col-lg-5"> -->
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-2">
                                                    <label class="form-label">To :</label>
                                                </div>
                                                <div class="col-lg-10">
                                                <date-picker
                                                        v-model="filters.searchDateTo"
                                                        :config="dateOpenOptions"
                                                        class=""
                                                        autocomplete="off"
                                                        placeholder="To Date"
                                                        name="dateTo"
                                                        >
                                                        </date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                                <!-- <input v-model="searchDateFrom" type="text" placeholder="Select Date" class="form-control col-lg-5"> -->
                                               
                                                
                                                <!-- <input v-model="searchDateTo" type="text" placeholder="Select Date" class="form-control col-lg-5"> -->
                                        </div>
                                        <div>
                                            <div class="custom-checkbox">
                                                <input v-model="filters.searchAttachment" type="checkbox"> 
                                                <label> Has attachment</label>
                                                <span class="checkmark"></span>
                                            </div>
                                        </div>
                                        <div class="button float-r">
                                            <button @click.stop="clearFilter()" class="btn">clear filter</button>
                                            <button @click="filterEmailList('form')" class="btn btn-primary">search</button>
                                        </div>
                                        
                                    </div>
                                </div>    
                            </div>
                            <div class="col-lg-2">
                                <a
                                    id="refreshList"
                                    title="Refresh"
                                    class="extraFeature btn btn-primary waves-effect pull-right mr-5"
                                    v-on:click.prevent="syncEmails()"
                                >
                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="container-fluid">
                <div class="">
                    <div class="email-middle mt-3">
                        <div class="row no-gutters">
                            <div class="col-lg-2">
                                <!-- <div class="new-email">
                                    <a><i class="fa fa-plus"></i>New mail</a>
                                </div> -->
                                <ul id="folder_list" v-if="folderData && folderData.length > 0" class="email-sidebar">
                                    
                                    <li :key="'folder_'+index" v-for="(folder, index) in folderData" 
                                                @click="listEmails( $event, folder.folder_id,'link'); selFoldName = folder.folder_name" 
                                                :class="['email-details-list', folder.folder_name === 'Inbox' ? 'active' : '']"
                                                :folder-name="folder.folder_name">

                                        <div class="rightsidebar-icon">   
                                            <i :class="getFolderClass(folder.folder_name)"></i> 
                                                {{ folder.folder_name }}
                                        </div>    
                                        <span class="float-r">{{ folder.unreadCount }}</span>
                                        
                                    </li>
                                </ul>
                                <ul v-else class="email-sidebar">
                                    <li class="email-details-list"> No data found. </li>
                                </ul>
                            </div>
                            <div class="col-lg-10">
                                <div class="ml-2">
                                    <div class="row no-gutters">
                                        <div class="col-lg-3">
                                            <div class="inbox-section">
                                                <div>
                                                    <div class="inbox-message">
                                                        <p>{{ selFoldName }}</p>
                                                        <!-- <span>381 Messages . 31unread</span>
                                                        <div class="float-r">
                                                            <i class="fa fa-retweet pr-3"></i>
                                                            <i class="fa fa-align-center"></i>
                                                        </div> -->
                                                    </div>
                                                    <div id="email_details" class="email-details" @scroll="onScroll">
                                                        <common-loader
                                                            refId="loaderEmailList"
                                                            ref="loaderEmailList">
                                                        </common-loader>
                                                        <template v-if="emailData && emailData.length > 0">
                                                            <div :key="'email_thread_'+index" v-for="(thread, index) in emailData" 
                                                                                        @click="showEmailContent( thread, thread.id, $event)" 
                                                                                        :class="'inbox-details inbox'+thread.id">

                                                                    <!-- <img src="/static/images/Frame 1000003397.png"> -->
                                                                    <div class="letter-logo">
                                                                        {{ thread.name_initials }}
                                                                    </div>
                                                                    <div class="inbox-subtitle">
                                                                        <div class="inbox-title">
                                                                            <div class="inbox-email-id">{{ thread.fromuser.email_name }}</div>
                                                                            <div class="inbox-icon">
                                                                                <!-- <i class="icon-Vector"></i> -->
                                                                                <i v-if="thread.is_read" class="icon-regular_envelope"></i>
                                                                                <i v-else class="icon-envelope"></i>
                                                                                <!-- <i class="icon-report-1"></i>
                                                                                <i class="icon-fluent"></i> -->
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <span class="float-r pl-2" :class="{ 'font-color-blue': !thread.is_read }">{{ thread.list_msg_received_at }}</span>
                                                                        <p :class="{ 'font-color-blue': !thread.is_read }">{{ thread.msg_subject }}</p>
                                                                        <p class="light-font">{{ thread.body_preview }}</p>
                                                                    </div>    
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="inbox-details">
                                                                <span>No data found.</span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>    
                                    
                                        <div class="col-lg-9">
                                            <div class="ml-2">
                                                <common-loader
                                                    refId="loaderEmailContent"
                                                    ref="loaderEmailContent">
                                                </common-loader>
                                                <template v-if="emailContent.length > 0">
                                                    <div v-if="emailContent[0].msg_subject" class="email-subject">
                                                    {{ emailContent[0].msg_subject }}
                                                    </div>
                                                    <div class="inbox-email" id="inbox_email">

                                                        <!-- <div class="emil-title">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <i class="fa fa-long-arrow-left"></i>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="float-right">
                                                                        <i class="fa fa-download"></i>
                                                                        <i class="fa fa-file"></i>
                                                                        <i class="fa fa-trash-o"></i>
                                                                        <i class="fa fa-ellipsis-v"></i>
                                                                    </div>     
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <template v-for="(eContent, e_index) in emailContent">
                                                            <div :key="'content_main_'+e_index" class="mb-2">
                                                                <div v-bind:key="'header_'+e_index" class="profile-title collapsed" data-toggle="collapse" :data-target="'#collapsed_one_'+e_index">
                                                                    <div class="row mb-3">
                                                                        <div class="col-lg-9">
                                                                            <div class="d-flex">
                                                                                <template v-if="eContent.msg_subject">
                                                                                    <div class="letter-logo">{{ eContent.name_initials }}</div>
                                                                                    <div class="">
                                                                                        <div class="user-name">{{ eContent.fromuser.email_name }}</div>
                                                                                        <p> 
                                                                                            <template v-if="eContent.tousers && eContent.tousers.length > 0">
                                                                                                <span class="mr-2">To:</span>
                                                                                                <div>
                                                                                                    <label :title="toUser.email_address" :key="'to_thread_'+index" v-for="(toUser, index) in eContent.tousers">
                                                                                                        &nbsp;{{ toUser.email_name }};&nbsp;&nbsp;
                                                                                                    </label>
                                                                                                </div>    
                                                                                            </template>
                                                                                        </p>
                                                                                        <p>
                                                                                            <template v-if="eContent.ccusers && eContent.ccusers.length > 0">
                                                                                                <span class="mr-2">Cc:</span>
                                                                                                <div>
                                                                                                    <span :title="ccUser.email_address" :key="'cc_thread_'+index" v-for="(ccUser, index) in eContent.ccusers"> 
                                                                                                        &nbsp;{{ ccUser.email_name }};&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                </div>
                                                                                            </template>
                                                                                        </p>
                                                                                        <p>
                                                                                            <template v-if="eContent.bccusers && eContent.bccusers.length > 0">
                                                                                                <span class="mr-2">Bcc:</span>
                                                                                                <div>
                                                                                                    <span :title="bccUser.email_address" :key="'bcc_thread_'+index" v-for="(bccUser, index) in eContent.bccusers"> 
                                                                                                        &nbsp;{{ bccUser.email_name }};&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                </div>
                                                                                            </template>
                                                                                        </p>
                                                                                    </div>
                                                                                </template>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <div class="py-3 col-lg-12">
                                                                                <span class="float-r pl-2">{{ eContent.msg_received_at }}</span>
                                                                                <!-- <i class="fa fa-star-o"></i>
                                                                                <i class="fa fa-angle-left left-angle"></i> -->
                                                                                <!-- <i class="fa fa-ellipsis-v"></i> -->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div v-bind:key="'content_'+e_index" class="email-detalis collapse show" :id="'collapsed_one_'+e_index">
                                                                        <div v-html="eContent.msg_body"></div>
                                                                        <!-- <div class="reply-section">
                                                                            <div class="replay-box">
                                                                                <i class="fa fa-angle-left"></i>
                                                                                <span>replay</span>
                                                                            </div>
                                                                            <div class="replay-box">
                                                                                <i class="fa fa-angle-left"></i>
                                                                                <span>replay all</span>
                                                                            </div>
                                                                            <div class="replay-box">
                                                                                <i class="fa fa-angle-left"></i>
                                                                                <span>forward</span>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </template>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="inbox-email" id="inbox_email">
                                                        <div class="no-select">
                                                            <img src="/static/images/email-logo.png">
                                                            <p>Please select an email to read.</p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <app-footer></app-footer>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="tab-pane fade" id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
                        <div class="container-fluid">
                            <div class="spaced-container">
                                <div class="col-12 page_title paddingnone">
                                <div class="page_title_left">
                                    <span>Calendar</span>
                                </div>
                                <a
                                    id="refreshList"
                                    title="Refresh"
                                    class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                    v-on:click.prevent="syncOutlookEvents()"
                                    >
                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="col-12 paddingnone">
                                
                                    <full-calendar
                                        id="calendar"
                                        :header="headerToolbar"
                                        :events="events"
                                        :config="config"
                                        ref="calendar"
                                    ></full-calendar>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import Footer from "@/components/partials/Footer";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import { commonMixins } from "../../mixins/commonMixins";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import _ from 'lodash';

Vue.component("app-footer", Footer);
    
    var self;

    export default {
        name: 'ListMail',
        components: {
            "date-picker" : datePicker,
            "common-loader": CommonLoader
        },
        mixins: [commonMixins],
        data: () => ({
            userId:"",
            perPage:20,
            currentPage:1,
            emailData:[],
            folderData:[],
            folderArr:[
                {
                    text:'All Folders',
                    value:''
                }
            ],
            
            allDataLoaded: false,
            loading: false,
            dateOpenOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
                allowInputToggle: true,
                
                widgetPositioning: {
                    horizontal: 'auto',
                    vertical: 'bottom'
                },
            },
            emailContent : [],
            filters: {
                searchFromEmail:"",
                searchToEmail:"",
                searchCcEmail:"",
                searchBccEmail:"",
                searchSubject:"",
                searchDateFrom:"",
                searchDateTo:"",
                searchAttachment:"",
                searchGlobal:"",
                searchInFolder: {
                    text:'All Folders',
                    value:''
                },
            },
            selFoldId:"",
            selFoldName:"",
            searchParams:[]
            
        }),
        beforeMount: function () {
            self = this;
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push("/");
            } else {
                var userJson = JSON.parse(userObj);
                self.userId = userJson.id;
            }
            
            self.setActiveMenu("email");
		    EventBus.$emit("menu-selection-changed", "email");

            self.syncEmails();

            
            
        },
        methods: {
            
            syncEmails: function() {
                
                // if request already sent then wait for complete
                if ( self.loading ) return;
                
                self.loading = true;
                
                $("#loaderEmailList")
                        .find(".loading-container.lodingTable")
                        .show();

                var config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("api_token")
                                }
                            };

                HTTP.get("/email/sync", config)
                    .then(function (response) {
                        
                    self.loading = false;
                    $("#loaderEmailList")
                            .find(".loading-container.lodingTable")
                            .hide();
                    
                    self.listEmailFolder('onLoad');
                    
                })
                .catch(function (error) {

                    self.loading = false;
                    $("#loaderEmailList")
                        .find(".loading-container.lodingTable")
                        .hide();
                    self.catchHandler(error, function () {});
                });
            },
            // Detect scroll event and check if we're near the bottom
            onScroll: _.debounce(function(event) {
                const container = event.target;
                const bottomPosition = container.scrollHeight - container.scrollTop <= container.clientHeight + 10;
                
                if (bottomPosition) {
                    self.listEmails( event, self.selFoldId, 'scroll');
                }
            }, 200),

            filterEmailList: function(flag) {
                
                self.searchParams = [];
                if ( flag == 'form' ) {
                    if ( self.filters.searchFromEmail != "" ) {
                        const newItem = { 
                                            id: 'from_'+Date.now(), 
                                            key: 'From', 
                                            value: self.filters.searchFromEmail 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchToEmail != "" ) {
                        const newItem = { 
                                            id: 'to_'+Date.now(), 
                                            key: 'To', 
                                            value: self.filters.searchToEmail 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchCcEmail != "" ) {
                        const newItem = { 
                                            id: 'cc_'+Date.now(), 
                                            key: 'Cc', 
                                            value: self.filters.searchCcEmail 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchBccEmail != "" ) {
                        const newItem = { 
                                            id: 'bcc_'+Date.now(), 
                                            key: 'Bcc', 
                                            value: self.filters.searchBccEmail 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchSubject != "" ) {
                        const newItem = { 
                                            id: 'sub_'+Date.now(), 
                                            key: 'Subject', 
                                            value: self.filters.searchSubject 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchDateFrom != "" ) {
                        const newItem = { 
                                            id: 'dtFrom_'+Date.now(), 
                                            key: 'Received>=', 
                                            value: self.filters.searchDateFrom 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchDateTo != "" ) {
                        const newItem = { 
                                            id: 'dtTo_'+Date.now(), 
                                            key: 'Received<=', 
                                            value: self.filters.searchDateTo 
                                        };
                        self.searchParams.push(newItem);
                    }
                    if ( self.filters.searchAttachment == true ) {
                        const newItem = { 
                                            id: 'attach_'+Date.now(), 
                                            key: 'Attachment', 
                                            value: true 
                                        };
                        self.searchParams.push(newItem);
                    }
                }

                self.selFoldName = "Search Result";
                self.listEmails(null ,self.selFoldId, 'filter');
            },

            removeSearchParam:function( id,key,index) {

                $("#filterTag"+id).remove();
                self.searchParams.splice(index, 1);
                
                if ( key == 'From' ) {
                    self.filters.searchFromEmail = "";
                } else if ( key == 'To' ) {
                    self.filters.searchToEmail = "";
                } else if ( key == 'Cc' ) {
                    self.filters.searchCcEmail = "";
                } else if ( key == 'Bcc' ) {
                    self.filters.searchBccEmail = "";
                } else if ( key == 'Subject' ) {
                    self.filters.searchSubject = "";
                } else if ( key == 'Received>=' ) {
                    self.filters.searchDateFrom = "";
                } else if ( key == 'Received<=' ) {
                    self.filters.searchDateTo = "";
                } else if ( key == 'Attachment' ) {
                    self.filters.searchAttachment = "";
                }
                self.listEmails(null ,self.selFoldId, 'filter');
            },
            
            clearFilter: function() {

                self.searchParams = [];

                self.filters = {
                    searchFromEmail:"",
                    searchToEmail:"",
                    searchCcEmail:"",
                    searchBccEmail:"",
                    searchSubject:"",
                    searchDateFrom:"",
                    searchDateTo:"",
                    searchAttachment:"",
                    searchGlobal:"",
                    searchInFolder: {
                        text:'All Folders',
                        value:''
                    },
                }

                self.listEmails(null ,self.selFoldId, 'filter');
            },
            // Fetch email folders
            listEmailFolder: function (flag) {

                self.loading = true;
                self.folderData = [];
                $("#commonLoaderListEmail").find(".loading-container.lodingTable").show();

                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                };

                HTTP.post("/email/folders/list", {}, config)
                    .then(function (response) {
                        
                    
                    self.loading = false;
                    $("#commonLoaderListEmail")
                                    .find(".loading-container.lodingTable")
                                    .hide();
                    
                    if ( response.data.code == 200 ) {
                        
                        self.folderData.push(...response.data.content);

                        // Load folder dropdown for filters
                        if ( response.data.content.length > 0 ) {
                            for ( var i=0; i < response.data.content.length; i++ ) {
                                
                                self.folderArr.push({
                                                    value:response.data.content[i].folder_id,
                                                    text:response.data.content[i].folder_name
                                                });
                            }
                        }
                        
                        //don't load if refresh folder list to update count
                        if ( flag == 'onLoad') {

                            let inboxFolder = response.data.content.find(folder => folder.folder_name === 'Inbox');
                            if ( inboxFolder.folder_id ) {
                                self.selFoldName = "Inbox";
                                self.listEmails( null, inboxFolder.folder_id, 'onload');
                            }
                        }
                        if ( flag == 'refresh') {
                            // Wait until the DOM has finished updating before calling the function
                            self.$nextTick(() => {
                                self.displayFoladerActive(flag, null);
                            });
                        }
                        
                    }
                })
                .catch(function (error) {

                    self.loading = false;
                    $("#commonLoaderListEmail")
                        .find(".loading-container.lodingTable")
                        .hide();
                    self.catchHandler(error, function () {});
                });
            
            },

            displayFoladerActive : function ( flag, event) {
                
                // Get all the <li> elements in the list
                let listItems = document.querySelectorAll('#folder_list li');
                
                // Remove 'active' class from all <li> elements
                if ( flag != 'scroll' ) {
                    listItems.forEach(item => {
                        item.classList.remove('active');
                    });
                }
                
                if ( event && flag == 'link' ) {
                    
                    // Add 'active' class to the clicked <li>
                    event.currentTarget.classList.add('active');
                }
                if ( flag == 'refresh') {
                    
                    listItems.forEach(item => {
                        const folderName = item.getAttribute('folder-name');
    
                        if (folderName === self.selFoldName ) {
                            item.classList.add('active');  // Add the 'active' class to the matching item
                        }
                    });
                }
            },
            // Fetch email data
            listEmails: function (event ,folderId, flag) {

                self.selFoldId = folderId;
                //set current page to as it is incrementing page and load next page data
                if ( flag != 'scroll' ) {

                    self.currentPage = 1;
                    self.emailData = [];
                    self.allDataLoaded = false;
                    
                    self.resetEmailContent();
                    
                }
                //reset active folder in folder list
                self.displayFoladerActive(flag, event);
                
                if ( (self.loading || self.allDataLoaded) && flag == 'scroll' ) return;

                self.loading = true;
                // $("#loaderEmailList").find(".loading-container.lodingTable").show();

                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                };

                var fields = {
                    flag:flag,
                    folderId: folderId,
                    perPage: self.perPage,
                    page: self.currentPage,
                    filters: self.filters
                };
                
                HTTP.post("/email/list", fields, config)
                    .then(function (response) {
                        
                    
                    self.loading = false;
                    $("#loaderEmailList")
                                    .find(".loading-container.lodingTable")
                                    .hide();
                    
                    if ( response.data.code == 200 ) {

                        // Check if all data is loaded
                        if ( response.data.content.length < self.perPage ) {
                            self.allDataLoaded = true;
                        }
                        self.emailData.push(...response.data.content);
                        self.currentPage += 1;
                    }
                })
                .catch(function (error) {

                    self.loading = false;
                    $("#loaderEmailList")
                        .find(".loading-container.lodingTable")
                        .hide();
                    self.catchHandler(error, function () {});
                });
            
            },
            resetEmailContent() {

                self.emailContent = [];

            },
            showEmailContent (thread, msgId, event) {
                
                self.resetEmailContent();
                // Remove blue color class as mail is read now.
                $(".inbox"+msgId).find('.font-color-blue').removeClass('font-color-blue');

                // Display email thread as active
                $('#email_details .inbox-details').removeClass('active');
                $('#email_details').find('.inbox' + msgId).addClass('active');

                $("#loaderEmailContent")
                        .find(".loading-container.lodingTable")
                        .show();

                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: thread.id
                    }
                };

                HTTP.get("/email/content", config)
                    .then(function (response) {
                        
                    self.loading = false;
                    $("#loaderEmailContent")
                            .find(".loading-container.lodingTable")
                            .hide();
                    
                    if ( response.data.code == 200 ) {
                        
                        //this.$set(this.items, itemIndex, { ...this.items[itemIndex], isRead: true });


                        self.emailContent = response.data.content;
                        if ( response.data.reloadFolderList ) {
                            self.listEmailFolder('refresh');
                        }

                        var emailbody = window.outerHeight - $('.app-sidebar-nav').outerHeight() - $('#main-footer').outerHeight() - $('.head-title').outerHeight() + 400;
                        $('#inbox_email').height(emailbody - $('.email-subject').outerHeight());
                        
                    }
                })
                .catch(function (error) {

                    self.loading = false;
                    $("#loaderEmailContent")
                        .find(".loading-container.lodingTable")
                        .hide();
                    self.catchHandler(error, function () {});
                });
            },
            //return folder icon class as per name
            getFolderClass (folderName) {

                var className = 'icon-regular';

                if ( folderName == 'Inbox' ) {
                    className = 'icon-inbox';
                } else if ( folderName == 'Archive' ) {
                    className = 'icon-inbox';
                } else if ( folderName == 'Drafts' ) {
                    className = 'icon-draft-outline';
                } else if ( folderName == 'Outbox' ) {
                    className = 'icon-send';
                } else if ( folderName == 'Sent Items' ) {
                    className = 'icon-send';
                } else if ( folderName == 'Junk Email' ) {
                    className = 'fa fa-connectdevelop';
                } else if ( folderName == 'Deleted Items' ) {
                    className = 'fa fa-trash-o';
                }
                return className;
            }
        }
    }
    
</script>
<style scoped>
.dropdown-toggle::after {
    display: none;
}
span.float-r {
    font-size: 12px;
}

</style>