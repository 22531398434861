<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container"></div>
            <!--Page content-->
            <div class="admin-page-content">
                <div class="middle-page-wrap">
   			        <h1 class="page-title"> {{ allLabelMessages.dashboard_page_title}} </h1>
   			        <div class="dashboard-block">
				        <div class="row">
                            <div class="col-md-6 col-lg-3">
                                <div class="dashboard-internal-box">
                                    <div class="block-content-main">
                                        <div class="block-content-img">
                                            <img src="/static/images/profile-info.png" :alt="allLabelMessages.profile_info_label">
                                        </div>
                                        <div class="right-block-content">
                                            <h5> {{ allLabelMessages.profile_info_label}} </h5>
                                            <h6> {{ vendorName}} </h6>
                                        </div>
                                        <div class="block-common-btn manage-profile">
                                            <a href="javascript:void(0)" @click.prevent="goToManageProfile()" class="mb-2"> 
                                                {{ allLabelMessages.manage_profile_label}}
                                                <span> 
                                                    <img src="/static/images/right-arrow-img.png" :alt="allLabelMessages.right_arrow_label"> 
                                                </span> 
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <div class="dashboard-internal-box">
                                    <div class="block-content-main">
                                        <div class="block-content-img">
                                            <img src="/static/images/invetory-list-img.png" :alt="allLabelMessages.inv_list_label">
                                        </div>
                                        <div class="right-block-content">
                                            <h5> {{ allLabelMessages.my_inv_list_label}} </h5>
                                            <p> {{ allStaticMessages.to_view_entire_inv_list_msg }} <br/> {{ allStaticMessages.please_click_the_btn_below_msg }} </p>
                                            <div class="search-container">
                                                <input type="text" placeholder="Search VIN No..." name="search" v-model="searchVin" @keyup.enter="searchInventory()" autocomplete="off" maxlength="25">
                                                <template v-if="searchVin">
                                                    <button type="submit" class="cancel-btn pr-2">
                                                        <i class="fa fa-times" @click.prevent="resetSearch()"></i>
                                                    </button>
                                                </template>
                                                <button type="submit">
                                                    <i class="fa fa-search" @click.prevent="searchInventory()"></i>
                                                </button>
                                            </div>
                                        </div>                   
                                        <div class="block-common-btn">
                                            <a href="javascript:void(0)" @click.prevent="goToInventoryList('current')" class="mb-2"> 
                                                {{ allLabelMessages.current_inv_list}} 
                                                <span> 
                                                    <img src="/static/images/right-arrow-img.png" :alt="allLabelMessages.right_arrow_label"> 
                                                </span> 
                                            </a>
                                            <a href="javascript:void(0)" @click.prevent="goToInventoryList('all')" class="mb-2"> 
                                                {{ allLabelMessages.all_inv_list}} 
                                                <span> 
                                                    <img src="/static/images/right-arrow-img.png" :alt="allLabelMessages.right_arrow_label"> 
                                                </span> 
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
				        </div>
			        </div>
		        </div>
            </div>

            <!-- Profile Information -->
            <div class="table-listing vendor-profile-info-section">
                <div class="profile-info">
                    <div class="profile-title-section">
                        <h2>Profile Information</h2>
                        <a v-if="!isChangePassword" href="javascript:void(0);" v-on:click.prevent="changePasswordClick()" class="btn btn-primary waves-effect pull-right">
                            {{ allLabelMessages.change_password_lbl }}
                        </a>
                    </div>
                    <!-- Change Password Section -->
                    <div v-if="isChangePassword" class="change-pwd-section mb-5">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="newPassword">{{ allLabelMessages.new_password_lbl }} <span class="requiredstar">*</span></label>
                                    <input type="password" id="toggle-newPassword" name="newPassword" v-model="newPassword" placeholder="Enter New Password" maxlength="20" @input="newPasswordError=''" autocomplete="new-password" ref="newPassword">
                                    <span toggle="#toggle-newPassword" class="fa fa-lg fa-eye field-icon toggle-newPassword"></span>
                                    <span class="help is-danger">{{ newPasswordError }}</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label for="confirmPassword">{{ allLabelMessages.confirm_password_lbl }} <span class="requiredstar">*</span></label>
                                    <input type="password" id="toggle-confirmPassword" name="confirmPassword" v-model="confirmPassword" placeholder="Confirm New Password" maxlength="20" @input="confirmPasswordError=''" autocomplete="new-password" ref="confirmPassword">
                                    <span toggle="#toggle-confirmPassword" class="fa fa-lg fa-eye field-icon toggle-confirmPassword"></span>
                                    <span class="help is-danger">{{ confirmPasswordError }}</span>
                                </div>
                            </div>    
                        </div>    

                        <div class="button-controls mt-2">
                            <input id="changePasswordBtn" :disabled="disableButtons.changePasswordBtn" type="submit" :value="buttonNames.changePasswordBtn" class="btn btn-primary" v-on:click.prevent="updatePassword()">
                            <input id="cancelChangePasswordBtn" :disabled="disableButtons.cancelChangePasswordBtn" type="submit" :value="buttonNames.cancelChangePasswordBtn" class="btn btn-primary ml-2" v-on:click.prevent="cancelChangePassword()">
                        </div>
                    </div>
                    <div class="row g-0">
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="vendorCode">Vendor Code</label>
                                <input type="text" id="vendorCode" name="vendorCode" v-model="vendorData.vendorCode" disabled>
                            </div>
                        </div>    

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="vendorFName">First Name</label>
                                <input type="text" id="vendorFName" name="vendorFName" v-model="vendorData.vendorFName" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="vendorLName">Last Name</label>
                                <input type="text" id="vendorLName" name="vendorLName" :value="vendorData.vendorLName || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="username">Username</label>
                                <input type="text" id="username" name="username" :value="vendorData.username || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="companyName">Company Name</label>
                                <input type="text" id="companyName" name="companyName" :value="vendorData.companyName || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="vendorAddr">Address</label>
                                <input type="text" id="vendorAddr" name="vendorAddr" :value="vendorData.vendorAddr || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="cityName">City</label>
                                <input type="text" id="cityName" name="cityName" :value="vendorData.city ? vendorData.city.name : '-'" disabled>
                            </div>
                        </div>    

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="stateName">State</label>
                                <input type="text" id="stateName" name="stateName" :value="vendorData.state ? vendorData.state.name : '-'" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="countryName">Country</label>
                                <input type="text" id="countryName" name="countryName" :value="vendorData.country ? vendorData.country.name : '-'" disabled>
                            </div>
                        </div>    

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="vendorZipCode">Zip Code</label>
                                <input type="text" id="vendorZipCode" name="vendorZipCode" :value="vendorData.vendorZipCode || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="cntName">Contact Name <span class="requiredstar">*</span> </label>
                                <input type="text" id="cntName" name="cntName" placeholder="Enter Contact Name" v-model="cntName" maxlength="190" @input="cntNameError=''" autocomplete="off">
                                <span class="help is-danger">{{ cntNameError }}</span>
                            </div>
                        </div>    

                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <label for="priPhoneNo">Phone <span class="requiredstar">*</span> </label>
                                <input type="text" id="priPhoneNo" name="priPhoneNo" placeholder="Enter Phone" v-model="priPhoneNo" maxlength="10" @input="priPhoneNoError=''" autocomplete="off">
                                <span class="help is-danger">{{ priPhoneNoError }}</span>
                            </div>       
                        </div>    
                    </div>

                    <div class="button-controls mt-2">
                        <input id="updateVendorProfileBtn" :disabled="disableButtons.updateProfileBtn" type="submit" :value="buttonNames.updateProfileBtn" class="btn btn-primary" v-on:click.prevent="updateVendorProfile()">
                        <input id="cancelProfileBtn" :disabled="disableButtons.cancelProfileBtn" type="submit" :value="buttonNames.cancelProfileBtn" class="btn btn-primary ml-2" v-on:click.prevent="cancelProfileBtn()">
                    </div>
                </div>
            </div>

            <!-- Vehicle Information -->
            <button type="submit" v-on:click.prevent="backToViewInvList()" class="btn btn-primary waves-effect pull-right vendor-vehicle-info-section"  style="margin-top: 2%;">Back</button>
            <div class="table-listing vendor-vehicle-info-section">
                <div class="profile-info">
                    <h2>Vehicle Information</h2>
                    
                    <div class="row g-0">
                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="vin">VIN</label>
                                <input type="text" id="vin" name="vin" v-model="assetData.vin" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="unit">Unit</label>
                                <input type="text" id="unit" name="unit" v-model="assetData.unit" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="stock">Stock</label>
                                <input type="text" id="stock" name="stock" :value="assetData.asset_mapper?.unt_misc40 || '-'"  disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="company">Company</label>
                                <input type="text" id="company" name="company" :value="assetData.company_master?.name || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="storage-location">Location</label>
                                <input type="text" id="storage-location" name="storage-location" v-model="defaultLoc" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="make">Make</label>
                                <input type="text" id="make" name="make" :value="assetData.make?.name || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="model">Model</label>
                                <input type="text" id="model" name="model" :value="assetData.model_type?.name || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="type">Type</label>
                                <input type="text" id="type" name="type" :value="assetData.is_truck || '-'" disabled>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3">
                            <div class="form-group">
                                <label for="year">Year</label>
                                <input type="text" id="year" name="year" :value="assetData.year || '-'" disabled>
                            </div>
                        </div>
                    </div>

                    <!-- Vendor Details Section -->
                    <div class="vendor-details">
                        <h2>Vendor Details</h2>
                        <div class="row">
                            <div class="col-md-6 col-lg-3">
                                <div class="form-group">
                                    <label for="vendor-name">Vendor Contact Name <span class="requiredstar">*</span> </label>
                                    <input type="text" id="vendor-name" name="vendor-name" placeholder="Enter Vendor Contact Name" v-model="vendorCntName" maxlength="190" @input="vendorCntNameError=''" required>
                                    <span class="help is-danger">{{ vendorCntNameError }}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3">
                                <div class="form-group">
                                    <label for="vendor-phone">Vendor Phone <span class="requiredstar">*</span> </label>
                                    <input type="text" id="vendor-phone" name="vendor-phone" placeholder="Enter Vendor Phone" v-model="vendorPhone" maxlength="10" @input="vendorPhoneError=''" required>
                                    <span class="help is-danger">{{ vendorPhoneError }}</span>
                                </div>
                            </div>
                        </div>    
                    </div>

                    <div class="button-controls mt-2">
                        <input id="submitInvWithVendorDataBtn" :disabled="disableButtons.submit" type="submit" :value="buttonNames.submit" class="btn btn-primary" v-on:click.prevent="submitInvWithVendorData(assetData.id)">
                        <input id="backToViewInvListBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary ml-2" v-on:click.prevent="backToViewInvList()">
                    </div>
                </div>
            </div>

            <!-- Vendor Linked Inventory List -->
            <div class="col-12 paddingnone vendor-inv-list-block" style="padding-top: 3%;">
                <h1 class="page-title">
                    <template v-if="inventoryType == 'search' && isSearchUI">
                        {{ allLabelMessages.inv_list_label }} - Search Results
                    </template>
                    <template v-else-if="inventoryType == 'all'">
                        {{ allLabelMessages.all_inv_list }}
                    </template>
                    <template v-else>
                        {{ allLabelMessages.current_inv_list }}
                    </template>
                    <a id="refreshList" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="refreshList()">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </a>
                </h1>

                <common-loader refId="commonLoaderListVendorInv" ref="commonLoaderListVendorInv"></common-loader>
                
                <!-- Top Pagination -->
                <div class="top_pagination vgt-wrap__footer crm">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 PerPage">
                            <v-select
                                title="Select Per Page"
                                v-model="perPage"
                                @input="changePerPage"
                                name="customPerPage"
                                id="customPerPage"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="paginationDropDownArr"
                                placeholder="Select Per Page">
                            </v-select>
                        </div>
                        <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                            <p class="pageRowCount">
                                {{ startEndRecord }} of {{ totalRecords }}
                            </p>
                        </div>
                        <div class="col-sm-12 col-md-6 Pagination">
                            <b-pagination
                                v-if="totalRecords > 0"
                                align="center"
                                :limit="5"
                                prev-text="‹ Previous"
                                next-text="Next ›"
                                :total-rows="totalRecords"
                                v-model="currentPage"
                                @input="changePage()"
                                :per-page="perPageValue">
                            </b-pagination>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <p class="pageJumpLabel">Page :</p>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <input
                                v-model="jumpToPage"
                                type="text"
                                class="form-control form-control-sm"
                                name="jumpToPage"
                                id="jumpToPage"
                                v-on:keyup.enter="keyMonitor()"
                                placeholder="Page"/>
                        </div>
                    </div>
                </div>
                 
                <!-- Inventory List -->
                <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                    <table class="table custom-table jsmartable">
                        <tbody>
                            <template v-if="vendorInvArr && vendorInvArr.length > 0">
                                <template v-for="(vendorInv, index) in vendorInvArr">
                                    <tr :key="index">
                                        <td v-show="isSearchUI">
                                            <div class="actions">
                                                <a href="javascript:void(0)" title="Update Location" class="mar-right-0" v-on:click="updateInvLocation(vendorInv.id)">
                                                    <i class="exceptEdit icon-vtclocation" style="font-size: 20px;" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td :class="rowClass.vendor_code">{{ vendorInv.asset_mapper2?.vendor_master?.code ?? '' }}</td>
                                        <td :class="'cl_name_status'"
                                            :key="'row_td_status_' + vendorInv.id + '_' + index">
                                            <span v-if="vendorInv.status.name == 'Active'" class="badge badge-primary badge-pill bg-color-71aa3f">
                                                {{ vendorInv.status.name }}
                                            </span>
                                            <span v-else-if="vendorInv.status.name == 'Hold'" class="badge badge-primary badge-pill bg-color-0771b4">
                                                {{ vendorInv.status.name }}
                                                <template v-if="vendorInv.asset_mapper.hold_status_id && vendorInv.asset_mapper.hold_status_id">
                                                    ({{ vendorInv.asset_mapper.hold_status.name }})
                                                </template>
                                            </span>
                                            <span v-else-if="vendorInv.status.name == 'Pending'" class="badge badge-primary badge-pill bg-color-cc9636">
                                                {{ vendorInv.status.name }}
                                            </span>
                                            <span v-else-if="vendorInv.status.name != null"class="badge badge-primary badge-pill bg-color-c3202b">
                                                {{ vendorInv.status.name }}
                                            </span>
                                        </td>
                                        <td :class="rowClass.unit">{{ vendorInv.unit }}</td>
                                        <td :class="rowClass.stock">{{ vendorInv.asset_mapper?.unt_misc40 ?? '' }}</td>
                                        <td :class="rowClass.vin">{{ vendorInv.vin }}</td>
                                        <td :class="rowClass.is_truck">{{ vendorInv.is_truck }}</td>
                                        <td :class="rowClass.year">{{ vendorInv.year }}</td>
                                        <td :class="rowClass.company_name">{{ vendorInv.company_master?.name ?? '' }}</td>
                                        <template v-if="inventoryType == 'all'">
                                            <td :class="rowClass.prev_loc_abbrevation">{{ vendorInv.prev_loc_abbrevation }}</td>
                                            <td :class="rowClass.updated_loc_abbrevation">{{ vendorInv.updated_loc_abbrevation }}</td>
                                        </template>
                                        <template v-else>
                                            <td :class="rowClass.location">{{ vendorInv.location?.abbrevation ?? '' }}</td>
                                        </template>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <tr>
                                    <td :colspan="headers.length + 1" class="text-alignment-center">
                                        {{ allStaticMessages.data_not_available }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <thead>
                            <tr>
                                <template v-for="(data, headerIndex) in headers">
                                    <th class=""
                                        :key="headerIndex"
                                        v-if="data.name == 'Action'"
                                        data-breakpoint="xs">
                                        {{ data.name }}
                                    </th>
                                </template>
                                <template v-for="(data, headerIndex) in headers">
                                    <th :class="data.column_full_class + ' column-header'"
                                        v-if="data.name != 'Action'"
                                        :key="headerIndex"
                                        v-on:click.prevent="sortTableData(data.column_name, data.sort, searchVin)">
                                        {{ data.name }}
                                    </th>
                                </template>
                            </tr>
                        </thead>
                    </table>
                </div>

                <!-- Bottom Pagination Start -->
                <div class="bottom_pagination vgt-wrap__footer crm">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 PerPage">
                            <v-select
                                title="Select Per Page"
                                v-model="perPage"
                                @input="changePerPage"
                                name="customPerPage"
                                id="customPerPage"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="paginationDropDownArr"
                                placeholder="Select Per Page">
                            </v-select>
                        </div>
                        <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                            <p class="pageRowCount">
                                {{ startEndRecord }} of {{ totalRecords }}
                            </p>
                        </div>
                        <div class="col-sm-12 col-md-6 Pagination">
                            <b-pagination
                                v-if="totalRecords > 0"
                                align="center"
                                :limit="5"
                                prev-text="‹ Previous"
                                next-text="Next ›"
                                :total-rows="totalRecords"
                                v-model="currentPage"
                                @input="changePage()"
                                :per-page="perPageValue">
                            </b-pagination>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <p class="pageJumpLabel">Page :</p>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <input
                                v-model="jumpToPage"
                                type="text"
                                class="form-control form-control-sm"
                                name="jumpToPage"
                                id="jumpToPage"
                                v-on:keyup.enter="keyMonitor()"
                                placeholder="Page"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vendor-footer></vendor-footer>
    </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import VendorFooter from "@/components/VendorPortal/VendorFooter";
import {commonMixins} from '../../mixins/commonMixins';
import vSelect from 'vue-select';
import CommonLoader from "@/components/partials/CommonLoader.vue";
import { HTTP } from "../../http-common";

export default {
    components: {
        'vendor-footer': VendorFooter,
        'v-select': vSelect,
        "common-loader": CommonLoader,
    },
    name: 'VendorDashboard',
    mixins: [commonMixins],
    data () {
        return {
            vendorName: '',

            perPageValue: 25,
            perPage: 25,
            currentPage: 1,
            totalRecords: 0,
            paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
            startEndRecord: "",
            headers: [],
            jumpToPage: 1,
            selectAll: false,
            vendorInvArr: [],
            searchVin: '',
            isSearchUI: false,
            assetData: [],
            defaultLoc: 'OTHER',
            vendorCntName: '',
            vendorCntNameError: '',
            vendorPhone: '',
            vendorPhoneError: '',
            buttonNames : {
                submit : 'Submit',
                cancel : 'Cancel',
                updateProfileBtn : 'Update Profile',
                cancelProfileBtn : 'Cancel',
                changePasswordBtn : 'Update Password',
                cancelChangePasswordBtn : 'Cancel'
            },
            disableButtons : {
                submit : false,
                cancel : false,
                updateProfileBtn : false,
                cancelProfileBtn : false,
                changePasswordBtn : false,
                cancelChangePasswordBtn : false
            },
            vendorData: [],
            cntName: '',
            cntNameError: '',
            priPhoneNo: '',
            priPhoneNoError: '',
            phoneRegex: /^[0-9]{10}$/,
            newPassword: '',
            newPasswordError: '',
            confirmPassword: '',
            confirmPasswordError: '',
            isChangePassword: false,
            vendorId: '',
            vendorUserName: '',
            vendorCntName: '',
            columnName: filterColumn,
            sortType: filterType,
            inventoryType: 'current'
        }
    },
    beforeMount() {
        self = this;
    },
    mounted: function() {
        $("#wrapper").addClass("login-div-full");

        var vendorObj = self.getVendorData();
        if (vendorObj) {
            self.vendorId = vendorObj.id;
            self.vendorName = vendorObj.first_name;
            self.vendorUserName = vendorObj.username;
        } else {
            self.resetBuildVersion();
            self.$router.push('/vendor/login');
        }
    },
    methods: {
        resetBuildVersion: function() {
            let current_build = localStorage.getItem("build_version");
            localStorage.clear();
            localStorage.setItem("build_version", current_build);
        },
        changePerPage: function () {
            self.currentPage = 1;
            if (self.perPage !== "All") {
                self.perPageValue = self.perPage;
            }
            self.goToInventoryList(self.inventoryType);
        },
        changePage: function () {
            if (!self.listExecutionState) {
                self.goToInventoryList(self.inventoryType);
            }
        },
        keyMonitor: function () {
            var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
            self.currentPage = self.jumpToPage = parseInt(tempNumber);
        },
        sortTableData: function (name, isSortAllowed, searchVin) {
            if (isSortAllowed == "nosort") {
                if (self.columnName == name) {
                    self.sortType = self.sortType == "desc" ? "asc" : "desc";
                } else {
                    self.sortType = "asc";
                }
                self.columnName = name;
                self.goToInventoryList(self.inventoryType, searchVin);
            }
        },
        refreshList: function () {
            self.jumpToPage = 1;
            self.columnName = self.filterColumn;
            self.sortType = self.filterType;
            self.keyMonitor();
            self.goToInventoryList(self.inventoryType, self.searchVin);
        },
        goToInventoryList: function (type, searchVIN = '') {
            $('.vendor-profile-info-section').removeClass('show');
            $('.vendor-vehicle-info-section').removeClass('show');
            self.clearChangePasswordData();
            self.inventoryType = type;

            self.listExecutionState = true;
            if (self.perPage == "All" && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
                self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, "info", false);
                return false;
            }
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("vendor_api_token"),
                },
            };
            var fields = {
                per_page: self.perPage,
                page: self.currentPage,
                sortColumnName: self.columnName,
                sortType: self.sortType,
                searchVIN: searchVIN,
                inventoryType: self.inventoryType,
            };
            HTTP.post("/listInventory", fields, config)
                .then(function (data) {
                    self.listExecutionState = false;
                    $("#commonLoaderListVendorInv").find(".loading-container.lodingTable").hide();
                    if (data.status == 200) {
                        $('.vendor-inv-list-block').addClass('show').get(0).scrollIntoView({ behavior: 'smooth' });
                        self.headers = [];
                        self.headers = data.data.content.vendorHeadersList;
                        self.vendorInvArr = data.data.content.vendorInvDataArr;
                        self.totalRecords = data.data.content.result;
                        self.isSearchUI = data.data.content.isSearchUI;

                        self.addColumnClass(self.headers);
                        let testArray = [
                        {
                            text: 1,
                            value: "2020-02-01",
                        },
                        {
                            text: 2,
                            value: "2020-02-02",
                        },
                        {
                            text: 3,
                            value: "2020-02-03",
                        },
                        ];
                        for (let i = 0; i < self.headers.length; i++) {
                            if (self.headers[i]["type"] == "drop_down") {
                                self.headers[i]["options_array"] = testArray;
                            }
                        }
                        if (data.data.content.per_page !== self.totalRecords) {
                            self.perPage = parseInt(data.data.content.per_page);
                            self.jumpToPage = parseInt(data.data.content.current_page);
                        } else {
                            if (self.perPage == "All") {
                                self.jumpToPage = 1;
                            }
                            self.perPageValue = self.totalRecords;
                            self.currentPage = 1;
                        }
                        self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage,self.perPage,self.totalRecords);
                        self.sortFieldDisplay(self.columnName, self.sortType);
                    } else {
                        $('.vendor-inv-list-block').removeClass('show');

                        if(searchVIN) {
                            self.$swal("","Inventory data not found for the requested VIN!", "info");
                        } else {
                            self.$swal("","Inventory data not found! ", "info");
                        }
                    }
                })
                .catch(function (err) {
                    self.listExecutionState = false;
                    $("#commonLoaderListVendorInv").find(".loading-container.lodingTable").hide();
                    self.catchHandler(err, function () {}, 1);
                });
        },
        searchInventory: function() {
            self.clearChangePasswordData();
            const alphaNumWithSpaces = /^[a-zA-Z0-9 ]+$/;
            if (self.searchVin.trim() === "") {
                self.$swal("", "VIN number cannot be empty.", "info");
            } else if(!alphaNumWithSpaces.test(self.searchVin)) {
                self.$swal("","VIN no must contain only alphanumeric characters.", "info");
            } else if (self.searchVin.length < 4) {
                self.$swal("","VIN no must be more than 3 characters.", "info");
            } else if (self.searchVin.length > 25) {
                self.$swal("", "VIN number cannot be longer than 25 characters.", "info");
            } else {
                self.currentPage = 1;
                self.goToInventoryList('search', self.searchVin.trim());               
            }
        },
        updateInvLocation: function(assetId) {
            $('.vendor-inv-list-block').removeClass('show');
            $('.vendor-profile-info-section').removeClass('show');
            $('.vendor-vehicle-info-section').addClass('show').get(0).scrollIntoView({ behavior: 'smooth' });

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                }
            };

            var data = new FormData();
            data.append('assetId', assetId);

            self.showLoader();
            HTTP.post("/getAssetDetailFromId", data, config)
                .then(function (response) {
                    self.hideLoader();
                    if (response.data.status === "success") {
                        self.assetData = response.data.content.asset;

                        let vendor_obj = self.getVendorData();
                        let presetCntName = (vendor_obj) ? vendor_obj.contact_name : '';
                        let presetPriPhone = (vendor_obj) ? vendor_obj.primary_phone : '';

                        self.vendorCntName = response.data.content.asset.asset_mapper2?.service_ven_contact ?? presetCntName;
                        self.vendorPhone = response.data.content.asset.asset_mapper2?.service_ven_phone ?? presetPriPhone;
                    }
                })
                .catch(function (err) {
                    self.hideLoader();
                    self.catchHandler(err, function () {}, 1);
                });
        },
        checkVendorForm: function() {
            var checked = true;
            self.vendorCntNameError = '';
            self.vendorPhoneError = '';

            if (!self.vendorCntName || self.vendorCntName.trim() == '') {
                self.vendorCntNameError = "Please enter vendor contact name";
                if (checked) {
                    checked = false;
                }
            }

            if (!self.vendorPhone) {
                self.vendorPhoneError = "Please enter vendor phone";
                if (checked) {
                    checked = false;
                }
            } else if (self.vendorPhone && self.vendorPhone.length > 10) {
                self.vendorPhoneError = "Max Length is 10";
                if (checked) {
                    checked = false;
                }
            } else if(self.vendorPhone && !self.phoneRegex.test(self.vendorPhone)) {
                self.vendorPhoneError = "Invalid phone number. Please enter a valid number.";
                if (checked) {
                    checked = false;
                }
            }
            return checked;
        },
        submitInvWithVendorData: function(assetId) {
            if(self.checkVendorForm()) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                    }
                };

                var data = new FormData();
                data.append('assetId', assetId);
                data.append('vendorCntName', self.vendorCntName.trim());
                data.append('vendorPhone', self.vendorPhone);
                data.append('stockNo', self.assetData.asset_mapper?.unt_misc40 ?? '');
                data.append('vinNo', self.assetData.vin);
                data.append('defaultLoc', self.defaultLoc);
                data.append('make', self.assetData.make?.name ?? '');
                data.append('model', self.assetData.model_type?.name ?? '');
                data.append('unit', self.assetData.unit);
                data.append('company_id', self.assetData.company_id);

                self.showLoader();
                HTTP.post("/storeInvWithVendorData", data, config)
                    .then(function (response) {
                        self.hideLoader();
                        if (response.data.status === "success") {
                            var displayAlertType = '';
                            var displayMsg = '';
                            var displayTitle = '';

                            if(response.data.is_updated_loc_email_sent === 'Yes') {
                                displayAlertType = 'success';
                                displayMsg = response.data.message;
                            } else if(response.data.is_unit_not_exits_email_sent === 'Yes') {
                                displayAlertType = 'info';
                                displayMsg = "Please contact the admin for assistance";
                                displayTitle = "Unit does not exist on the Arcedium";
                            }

                            self.$swal({
                                title: displayTitle,
                                text: displayMsg,
                                type: displayAlertType,
                                confirmButtonText: "OK",
                                showCancelButton: false,
                                showLoaderOnConfirm: true,
                            }).then((result) => {
                                if (result.value) {
                                    self.resetVendorData();

                                    // Redirect to the current inventory list page after updating vendor details.
                                    self.inventoryType = 'current';
                                    self.searchVin = '';
                                    self.backToViewInvList();
                                }
                            });
                        }
                    })
                    .catch(function (err) {
                        self.hideLoader();
                        self.catchHandler(err, function () {}, 1);
                    });
            }
        },
        backToViewInvList: function() {
            self.resetVendorData();
            self.goToInventoryList(self.inventoryType, self.searchVin);               
        }, 
        resetVendorData: function() {
            self.assetData = [];
            self.defaultLoc = 'OTHER';
            self.vendorCntName = '';
            self.vendorCntNameError = '';
            self.vendorPhone = '';
            self.vendorPhoneError = '';
        },
        resetSearch: function() {
            self.searchVin = '';
            self.closeOpenModels();
        },
        goToManageProfile: function () {
            $('.vendor-inv-list-block').removeClass('show');
            $('.vendor-vehicle-info-section').removeClass('show');
            $('.vendor-profile-info-section').addClass('show').get(0).scrollIntoView({ behavior: 'smooth' });
            
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                }
            };

            self.showLoader();
            HTTP.get("/getVendorProfileDetails", config)
                .then(function (response) {
                    self.hideLoader();
                    if (response.data.status === "success") {
                        self.vendorData = response.data.content.vendorData;

                        if(response.data.content.vendorData && response.data.content.vendorData.contactName != null) {
                            self.cntName = response.data.content.vendorData.contactName;
                        }

                        if(response.data.content.vendorData && response.data.content.vendorData.primaryPhone != null) {
                            self.priPhoneNo = response.data.content.vendorData.primaryPhone;
                        }
                    }
                })
                .catch(function (err) {
                    self.hideLoader();
                    self.catchHandler(err, function () {}, 1);
                });
        },
        cancelProfileBtn: function() {
            self.resetProfileData();
            $('.vendor-profile-info-section').removeClass('show');
            $('.vendor-vehicle-info-section').removeClass('show');
            $('.vendor-inv-list-block').removeClass('show');
        },
        resetProfileData: function() {
            self.vendorData = [];
            self.cntName = '';
            self.cntNameError = '';
            self.priPhoneNo = '';
            self.priPhoneNoError = '';
        },
        checkProfileForm: function() {
            var checked = true;
            self.cntNameError = '';
            self.priPhoneNoError = '';

            if (!self.cntName || self.cntName.trim() == '') {
                self.cntNameError = "Please enter contact name";
                if (checked) {
                    checked = false;
                }
            }

            if (!self.priPhoneNo) {
                self.priPhoneNoError = "Please enter phone";
                if (checked) {
                    checked = false;
                }
            } else if (self.priPhoneNo && self.priPhoneNo.length > 10) {
                self.priPhoneNoError = "Max Length is 10";
                if (checked) {
                    checked = false;
                }
            } else if(self.priPhoneNo && !self.phoneRegex.test(self.priPhoneNo)) {
                self.priPhoneNoError = "Invalid phone number. Please enter a valid number.";
                if (checked) {
                    checked = false;
                }
            }
            return checked;
        },
        updateVendorProfile: function() {
            if(self.checkProfileForm()) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                    }
                };

                var data = new FormData();
                data.append('cntName', self.cntName.trim());
                data.append('priPhoneNo', self.priPhoneNo);

                self.showLoader();
                HTTP.post("/updateVendorProfileData", data, config)
                    .then(function (response) {
                        self.hideLoader();
                        if (response.data.status === "success") {
                            self.showSAlert(response.data.message,'success',false,3);

                            let vendor_obj = self.getVendorData();
                            if (vendor_obj) {
                                vendor_obj.contact_name = self.cntName;
                                vendor_obj.primary_phone = self.priPhoneNo;
                                self.setVendorData(vendor_obj);
                            }

                            self.resetProfileData();
                            $('.vendor-profile-info-section').removeClass('show');
                        }
                    })
                    .catch(function (err) {
                        self.hideLoader();
                        self.catchHandler(err, function () {}, 1);
                    });
            }
        },
        cancelChangePassword() {
            self.isChangePassword = false;
            self.clearChangePasswordData();
        },
        clearChangePasswordData: function () {
            self.isChangePassword = false;
            self.newPassword = "";
            self.newPasswordError = "";
            self.confirmPassword = "";
            self.confirmPasswordError = "";
        },
        changePasswordClick: function() {
            self.isChangePassword = !self.isChangePassword;
            if(!self.isChangePassword) {
                self.clearChangePasswordData();
            } else {
                setTimeout(function() {
                    self.toggelPasswordFields();
                }, 10);
            }
        },
        toggelPasswordFields: function() {
            $(".toggle-newPassword").click(function() {
                $(this).toggleClass("fa-eye fa-eye-slash");
                var input = $($(this).attr("toggle"));
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            });

            $(".toggle-confirmPassword").click(function() {
                $(this).toggleClass("fa-eye fa-eye-slash");
                var input = $($(this).attr("toggle"));
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            });
        },
        checkPasswordFormData: function() {
            var checked = true;
            self.newPasswordError = '';
            self.confirmPasswordError = '';
            var latestErrorField = "";
            var errors = {};

            if (!self.newPassword) {
                self.newPasswordError = errors.newPassword = "Please enter new Password";
                if (checked) {
                    checked = false;
                    latestErrorField = 'newPassword';
                }
            } else if (self.newPassword && self.newPassword.length < 6) {
                self.newPasswordError = errors.newPassword = "You must provide at least 6 characters for password";
                if (checked) {
                    checked = false;
                    latestErrorField = 'newPassword';
                }
            }
            if (!self.confirmPassword) {
                self.confirmPasswordError = errors.confirmPassword = "Please enter Confirm Password";
                if (checked) {
                    checked = false;
                    latestErrorField = 'confirmPassword';
                }
            } else if (self.confirmPassword && !self.checkPasswordAndConfirmPassword(self.newPassword, self.confirmPassword)) {
                self.confirmPasswordError = errors.confirmPassword = "Password and Confirm Password do not match";
                if (checked) {
                    checked = false;
                    latestErrorField = 'confirmPassword';
                }
            }

            // Redirect to the latest mandatory field with error on form submission
            if (!checked && latestErrorField) {
                self.$nextTick(() => {
                    self.$refs[latestErrorField].focus();
                });

                // If an errors are available, navigate to the first error field based on ID
                if (Object.keys(errors).length > 0) {
                    const firstErrorField = Object.keys(errors)[0];
                    const errorElement = self.$el.querySelector(`#${firstErrorField}`);
                    
                    // Scroll to the error field
                    if (errorElement) {
                        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }
            }

            return checked;
        },
        updatePassword: function() {
            setTimeout(() => {
                if (self.checkPasswordFormData()) {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                        }
                    };
                    var passwordData = new FormData();
                    passwordData.append('username', self.vendorUserName);
                    passwordData.append('password', self.newPassword);

                    self.buttonNames.changePasswordBtn = "Updating Password...";
                    self.disableButtons.changePasswordBtn = true;
                    self.disableButtons.changePasswordClick = true;

                    HTTP.post("/vendor/" + self.vendorId + "/credentials", passwordData, config)
                        .then(function (response) {
                            self.buttonNames.changePasswordBtn = "Update Password";
                            self.disableButtons.changePasswordBtn = false;
                            self.disableButtons.changePasswordClick = false;

                            if (response.data.status == "success") {
                                self.clearChangePasswordData();
                                self.showSAlert(response.data.message, 'success', false, 3);
                            } else {
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                            self.buttonNames.changePasswordBtn = "Update Password";
                            self.disableButtons.changePasswordBtn = false;
                            self.disableButtons.changePasswordClick = false;
                            self.catchHandler(err, function () {});
                        });
                }
            }, 200);
        },
        checkPasswordAndConfirmPassword: function (password, confirmPassword) {
            if (password != confirmPassword) {
                return false;
            } else {
                return true;
            }
        },
        closeOpenModels: function() {
            $('.vendor-inv-list-block, .vendor-vehicle-info-section, .vendor-profile-info-section').removeClass('show');
        }
    }
}
</script>
<style lang="css" scoped>
.page-header-top-container {
    float: left;
    width: 100%;
    padding: 30px;
    position: initial !important;
}
.middle-page-wrap .page-title {
    margin-bottom: 20px;
}
h1.page-title {
    float: left;
    color: #444444;
    font-size: 32px;
    line-height: 32px;
    width: 100%;
    font-family: 'SanFranciscoDisplayThin';
}
h1.page-title a,
h1.page-title a:hover {
    color: #fff;
}
.dashboard-block .row {
    float: left;
    width: 100%;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.dashboard-internal-box {
    float: left;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 50px 25px 40px 25px;
    border: 1px solid #bababa;
    min-height: 325px;
    position: relative;
}
.block-content-main {
    float: left;
}
.block-content-img {
    float: left;
    width: 30%;
    padding-right: 20px;
}
.block-content-img img {
    width: 100%;
}
.right-block-content {
    float: left;
    margin-top: 5px;
    width: 70%;
}
.right-block-content h5 {
    float: left;
    width: 100%;
    color: #098de1;
    font-size: 20px;
    line-height: 24px;
    font-family: 'SanFranciscoDisplayMedium';
    margin-bottom: 12px;
}
.right-block-content h6 {
    float: left;
    width: 100%;
    color: #545454;
    font-size: 20px;
    line-height: 24px;
    font-family: 'SanFranciscoDisplaySemiBold';
    margin-bottom: 0;
}
.right-block-content p {
    float: left;
    width: 100%;
    color: #545454;
    font-size: 16px;
    line-height: 20px;
    font-family: 'SanFranciscoDisplayRegular';
    margin-top: 0px;
}
.block-common-btn {
    float: left;
    width: 100%;
    position: absolute;
    bottom: 40px;
    padding: 0 25px;
    left: 0;
    right: 0;
    margin: auto;
}
.block-common-btn a {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    font-family: 'SanFranciscoDisplayRegular';
    background-color: #098de1;
    padding: 12px 20px;
    border-bottom: 2px solid #076eb0;
    float: left;
    width: 100%;
    border-radius: 4px;
}
.block-common-btn span {
    float: right;
    padding-top: 3px;
}
.block-common-btn a:hover {
    background-color: #1063ad;
    transition: all 0.3s ease;
    color: #fff;
}
.vendor-inv-list-block,
.vendor-vehicle-info-section,
.vendor-profile-info-section {
    display: none;
}
.vendor-inv-list-block.show,
.vendor-vehicle-info-section.show,
.vendor-profile-info-section.show {
    display: block;
}
/* Search container style --- START(BS) */
.search-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
}
.search-container input {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
    padding-right: 70px;
}
.search-container input:focus {
    border-color: #098de1;
}
.search-container button {
    position: absolute;
    right: 10px;
    top: 82%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.search-container i {
    color: #098de1;
    font-size: 18px;
}
/* Search container style --- END(BS) */

/* Inventory container --- START(BS) */
.card {
    background-color: white;
    padding: 20px;
    margin-bottom: 30px;
    border: none;
}

.card h2 {
    margin-bottom: 20px;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input[type="number"] {
    -moz-appearance: textfield;
}

.vendor-details {
    background-color: #ffffff;
    border-radius: 8px;
    padding-top: 20px;
}

.vendor-details h2 {
    margin-bottom: 20px;
}

.vendor-details .form-group {
    margin-bottom: 10px;
}
/* Inventory container --- END(BS) */
.btn {
    font-size: 14px !important;
}
.requiredstar {
    position: static;
}
.profile-title-section {
    display: flex;
    justify-content: space-between;
}
.profile-title-section a{
    line-height: 5px;
    align-self: center;
    padding: 15px 10px !important;
}
.pr-2 {
    padding-right: 2rem !important;
}
.cancel-btn i {
    border-right: 1px solid #ccc;
    padding-right: 8px;
    line-height: 26px;
}
</style>